//   // https://stackoverflow.com/questions/44480644/string-union-to-string-array
// export type AreasOfSpecialization = "Anesthesiologist" | "Cardiologist" | "Ent"

export enum EditResult {
  Cancelled,
  Added,
  Updated,
}

export type EditProps = {
  open: boolean;
  id: string;
  data: any;
  callback: (result: EditResult, data: any) => void;
  handleClose: (event: any) => void;
};

export const EditPropsClosed: EditProps = {
  open: false,
  id: "",
  data: undefined,
  callback: (r, d) => {},
  handleClose: (e) => {},
};
