import { useState, useContext, useEffect } from 'react'
import { Box, Button, Link, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import AddIcon from '@mui/icons-material/Add'
import { PageHeader } from '../common/pageHeader'
import { UserContext } from '../../common/userContext'
import { Visa, VisaApplication } from '../../graphql/API'
import NewVisaApplicationModal from '../modals/NewVisaApplicationModal'
import { callApi } from '../../common/apiUtils'
import { getVisaApplicationsByUser } from '../../graphql/queries'
import { useGridApiRef, DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { Link as RouterLink } from 'react-router-dom'
import { deleteVisaApplication } from '../../graphql/mutations'
import { Loading } from '../common/loading'

export default function Applications() {
    const contextData = useContext(UserContext)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [visaApplications, setVisaApplications] = useState<Array<VisaApplication>>()
    const [visaTypes, setVisaTypes] = useState<Array<Visa>>()
    const [newVisaModalOpen, setNewVisaModalOpen] = useState<boolean>(false)

    const apiRef = useGridApiRef()

    const deleteRow = (id: string) => async (event: any) => {
        const va = await callApi<Array<VisaApplication>>(contextData.user, 'deleteVisaApplication', {
            query: deleteVisaApplication,
            variables: { pk: id },
        })
        if (va.Result) {
            const filtered = visaApplications?.filter((vv) => vv.id !== id)!
            setVisaApplications(filtered!)
            contextData.setVisaApplications(filtered!)
        }
    }

    const openModal = () => {
        setNewVisaModalOpen(true)
    }
    const handleNewVisaModalClose = async () => {
        setNewVisaModalOpen(false)

        const visaApplications = await callApi<Array<VisaApplication>>(contextData.user, 'getVisaApplicationsByUser', {
            query: getVisaApplicationsByUser,
            variables: { pk: contextData.ledgeUser?.id },
        })

        if (visaApplications.Result) {
            setVisaApplications(visaApplications.Result)
            contextData.setVisaApplications(visaApplications.Result)
        }
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'visaType',
            headerName: 'Visa type',
            flex: 1,
        },
        {
            field: 'dueDate',
            headerName: 'Due date',
            flex: 1,
        },
        {
            field: 'edit',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ cursor: 'pointer' }}>
                        <Link component={RouterLink} to={`/applications/${params.id}`} sx={{ textDecoration: 'none' }}>
                            <EditIcon />
                        </Link>
                        ,
                    </Box>
                    {/* <Box sx={{ cursor: 'pointer' }}>
                        <Download />
                    </Box> */}
                    <Box sx={{ cursor: 'pointer' }}>
                        <DeleteIcon onClick={deleteRow(params.row.id)} />
                    </Box>
                </Box>
            ),
        },
    ]

    useEffect(() => {
        const initialise = async () => {
            try {
                // initially make page unloaded, get the visas then set loaded
                setLoaded(false)
                const visaTypesList = contextData.visaTypes
                const visaApplications = contextData.visaApplications

                setVisaApplications(visaApplications)
                setVisaTypes(visaTypesList)
            } finally {
                setLoaded(true)
            }
        }
        initialise()
    }, [])
    if (loaded && visaApplications)
        return (
            <Box marginBottom={8}>
                <NewVisaApplicationModal
                    open={newVisaModalOpen}
                    visaTypes={visaTypes!}
                    onClose={handleNewVisaModalClose}
                />
                <PageHeader title="My applications" />
                <Typography variant="body1" color={'secondary'}>
                    Create a new Visa application checklist and export your responses and documents for easy handover to
                    your migration agent!
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'left',
                        width: '100%',
                        marginBottom: 2,
                        marginTop: 2,
                    }}
                >
                    {/* <TextField
                        id="standard-search"
                        label="Search field"
                        type="search"
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: 2 }}
                    /> */}
                    <Button variant="contained" startIcon={<AddIcon />} onClick={openModal}>
                        New Visa application checklist
                    </Button>
                </Box>
                <Box>
                    <DataGridPro
                        autoPageSize
                        pagination
                        rows={visaApplications}
                        columns={columns}
                        apiRef={apiRef}
                        editMode="row"
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'asc' }],
                            },
                        }}
                        sx={{ minHeight: 400 }}
                    />
                </Box>
            </Box>
        )
    return <Loading />
}
