import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, TextField, Typography, Stack } from '@mui/material'
import { DataGridPro, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import { theme } from '../../assets/theme'

const RedDivider = () => {
    
    return (
        <hr color="error" />
    )
}

export default RedDivider
