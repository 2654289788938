import { useContext, useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { useGridApiRef, DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro'

import { UserContext } from '../../common/userContext'
import { callApi, retrieveVisaTypes } from '../../common/apiUtils'
import { ConfirmDialog, ConfirmDialogProps, ConfirmDialogPropsClosed } from '../common/confirmDialog'
import NewVisaTypeModal from '../modals/NewVisaTypeModal'
import NewQuestionModal from '../modals/NewQuestionModal'
import { getQuestionBlocks, getQuestions, getQuestionTopics, getUsers } from '../../graphql/queries'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { Loading } from '../common/loading'
import {
    deleteQuestion,
    deleteQuestionBlock,
    deleteQuestionTopic,
    modifyQuestionBlock,
    modifyQuestionTopic,
} from '../../graphql/mutations'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { QuestionBlock, QuestionTopic, Question, Visa, User } from '../../graphql/API'
import NewQuestionTopicModal from '../modals/NewQuestionTopicModal'
import NewQuestionBlockModal from '../modals/NewQuestionBlockModal'
import DeleteVisaTypeModal from '../modals/DeleteVisaTypeModal'

export default function QuestionDashboard() {
    const contextData = useContext(UserContext)
    const apiRef = useGridApiRef()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [addVisaModalOpen, setAddVisaModalOpen] = useState<boolean>(false)
    const [addQuestionModalOpen, setQuestionModalOpen] = useState<boolean>(false)
    const [addQuestionTopicModalOpen, setAddQuestionTopicModalOpen] = useState<boolean>(false)
    const [addQuestionBlockModalOpen, setAddQuestionBlockModalOpen] = useState<boolean>(false)
    const [deleteVisaTypeModalOpen, setDeleteVisaTypeModalOpen] = useState<boolean>(false)
    const [deleteVisaTarget, setDeleteVisaTarget] = useState<string>('')
    const [deleteVisaTargetName, setDeleteVisaTargetName] = useState<string>('')
    const [usersSignedUp, setUsersSignedUp] = useState<number>()
    const [visaTypeRows, setVisaTypeRows] = useState<Array<Visa>>([])
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps>(ConfirmDialogPropsClosed)
    const [processing, setProcessing] = useState<boolean>(false)

    // ===========================================================
    const handleAddVisaTypeModalClose = async () => {
        setAddVisaModalOpen(false)
        const visas = await retrieveVisaTypes(contextData.user)
        if (visas.Result) setVisaTypeRows(visas.Result)
    }

    const handleDeleteVisaTypeModalClose = async (item) => {
        setDeleteVisaTypeModalOpen(false)
        if (item.completed === true) setVisaTypeRows(item.result)
    }

    const handleAddQuestionModalClose = async () => {
        setQuestionModalOpen(false)
        const questions = await callApi<Array<Question>>(contextData.user, 'getQuestions', {
            query: getQuestions,
        })
        contextData.setQuestions(questions.Result!)
    }

    const handleAddQuestionTopicModalClose = async () => {
        setAddQuestionTopicModalOpen(false)
        const qt = await callApi<Array<QuestionTopic>>(contextData.user, 'getQuestionTopics', {
            query: getQuestionTopics,
        })
        contextData.setQuestionTopics(qt.Result!)
    }

    const handleAddQuestionBlockModalClose = async () => {
        setAddQuestionBlockModalOpen(false)

        const qt = await callApi<Array<QuestionBlock>>(contextData.user, 'getQuestionBlocks', {
            query: getQuestionBlocks,
        })
        contextData.setQuestionBlocks(qt.Result!)
    }
    // ===========================================================

    const deleteTheQuestion = async (params: GridRenderCellParams) => {
        setProcessing(true)
        for (const block of contextData.questionBlocks) {
            if (block.questions?.find((f) => f === params.row.id)) {
                block.questions = block.questions?.filter((v) => v !== params.row.id)
                const item = { ...block, questions: block.questions }
                const updatedBlock = await callApi<Array<QuestionBlock>>(contextData.user, 'modifyQuestionBlock', {
                    query: modifyQuestionBlock,
                    variables: { item: item },
                })

                console.log(updatedBlock.Result)
            }
        }

        const dq = await callApi<Array<Question>>(contextData.user, 'deleteQuestion', {
            query: deleteQuestion,
            variables: { pk: params.row.id },
        })

        console.log(dq.Result)

        const questions = await callApi<Array<Question>>(contextData.user, 'getQuestions', {
            query: getQuestions,
        })

        contextData.setQuestions(questions.Result!)
        setProcessing(false)
    }

    const deleteTheTopic = async (params: GridRenderCellParams) => {
        setProcessing(true)

        await callApi<Array<QuestionTopic>>(contextData.user, 'deleteQuestionTopic', {
            query: deleteQuestionTopic,
            variables: { pk: params.row.id },
        })

        const qts = await callApi<Array<QuestionTopic>>(contextData.user, 'getQuestionTopics', {
            query: getQuestionTopics,
        })

        contextData.setQuestionTopics(qts.Result!)
        setProcessing(false)
    }

    const deleteTheBlock = async (params: GridRenderCellParams) => {
        setProcessing(true)

        for (const topic of contextData.questionTopics) {
            if (topic.questionBlocks?.find((bb) => bb === params.row.id)) {
                topic.questionBlocks = topic.questionBlocks?.filter((delBlock) => delBlock !== params.row.id)
                const item = { ...topic, questionBlocks: topic.questionBlocks }
                const updatedTopic = await callApi<Array<QuestionTopic>>(contextData.user, 'modifyQuestionTopic', {
                    query: modifyQuestionTopic,
                    variables: { item: item },
                })

                console.log(updatedTopic.Result)
            }
        }

        await callApi<Array<QuestionBlock>>(contextData.user, 'deleteQuestionBlock', {
            query: deleteQuestionBlock,
            variables: { pk: params.row.id },
        })

        const qbs = await callApi<Array<QuestionBlock>>(contextData.user, 'getQuestionBlocks', {
            query: getQuestionBlocks,
        })

        contextData.setQuestionBlocks(qbs.Result!)
        setProcessing(false)
    }

    const startDeletingTheVisaType = async (params: GridRenderCellParams) => {
        setDeleteVisaTarget(params.row.id)
        setDeleteVisaTargetName(params.row.name)
        setDeleteVisaTypeModalOpen(true)
    }

    const questionTopicColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
        },
        {
            field: 'order',
            headerName: 'Order #',
            flex: 1,
        },
        {
            field: 'tags',
            headerName: 'Tags?',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box display="flex">
                    {params.row.tags.map((tt, i) => (
                        <Box key={i} sx={{ background: '#402E8D', padding: 0.5, borderRadius: 2, marginRight: 1 }}>
                            <Typography variant="body2" margin={0} color={'white'}>{`${tt}`}</Typography>
                        </Box>
                    ))}
                </Box>
            ),
        },
        {
            field: 'edit',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Link
                        component={RouterLink}
                        to={`/questiondashboard/questiontopic/${params.id}`}
                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                    >
                        <EditIcon />
                    </Link>
                    <Box sx={{ marginLeft: 2, cursor: 'pointer' }} onClick={() => deleteTheTopic(params)}>
                        <DeleteIcon />
                    </Box>
                </Box>
            ),
        },
    ]

    const questionBlockColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 3,
        },
        {
            field: 'order',
            headerName: 'Order #',
            flex: 1,
        },
        {
            field: 'subtext',
            headerName: 'Has subtext?',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <Box>{params.row.subtext ? 'Yes' : 'No'}</Box>,
        },
        {
            field: 'edit',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Link
                        component={RouterLink}
                        to={`/questiondashboard/questionblock/${params.id}`}
                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                    >
                        <EditIcon />
                    </Link>
                    <Box sx={{ marginLeft: 2, cursor: 'pointer' }} onClick={() => deleteTheBlock(params)}>
                        <DeleteIcon />
                    </Box>
                </Box>
            ),
        },
    ]

    const questionColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'questionText',
            headerName: 'Question',
            flex: 3,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 1,
        },
        {
            field: 'questionType',
            headerName: 'Question Type',
            flex: 1,
        },
        {
            field: 'order',
            headerName: 'Order #',
            flex: 1,
        },
        {
            field: 'edit',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Link
                        component={RouterLink}
                        to={`/questiondashboard/question/${params.id}`}
                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                    >
                        <EditIcon />
                    </Link>
                    <Box sx={{ marginLeft: 2, cursor: 'pointer' }} onClick={() => deleteTheQuestion(params)}>
                        <DeleteIcon />
                    </Box>
                </Box>
            ),
        },
    ]

    const visaTypeColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
            flex: 2,
        },
        {
            field: 'name',
            headerName: 'Visa Name',
            flex: 2,
        },
        {
            field: 'published',
            headerName: 'Published',
            flex: 2,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Link
                        component={RouterLink}
                        to={`/questiondashboard/visa/${params.id}`}
                        sx={{ textDecoration: 'none' }}
                    >
                        <EditIcon />
                    </Link>
                    <Box sx={{ marginLeft: 2, cursor: 'pointer' }} onClick={() => startDeletingTheVisaType(params)}>
                        <DeleteIcon />
                    </Box>
                </Box>
            ),
        },
    ]

    useEffect(() => {
        const initialise = async () => {
            try {
                // initially make page unloaded, get the visas then set loaded
                if (!contextData.user.isAdmin()) return
                setLoaded(false)
                setVisaTypeRows(contextData.visaTypes)
                const users = await callApi<Array<User>>(contextData.user, 'getUsers', {
                    query: getUsers,
                })

                console.log()
                if (users.Result) {
                    setUsersSignedUp(users.Result.length)
                } else {
                    setUsersSignedUp(1)
                }
            } finally {
                setLoaded(true)
            }
        }

        initialise()
    }, [])

    if (!contextData.user.isAdmin()) return <div />

    if (loaded && contextData.questions && visaTypeRows && usersSignedUp)
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    minHeight: '300px',
                    minWidth: '300px',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="h1" marginBottom={2}>
                        Question Dashboard
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 1 }}>
                    <DeleteVisaTypeModal
                        open={deleteVisaTypeModalOpen}
                        target={deleteVisaTarget}
                        targetName={deleteVisaTargetName}
                        onClose={handleDeleteVisaTypeModalClose}
                    />
                    <NewQuestionTopicModal
                        open={addQuestionTopicModalOpen}
                        onClose={handleAddQuestionTopicModalClose}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginBottom: 2,
                        }}
                    >
                        <Typography variant="h2">Question Topics </Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => setAddQuestionTopicModalOpen(true)}
                        >
                            Add Question Topic
                        </Button>
                    </Box>
                </Box>

                <DataGridPro
                    pagination
                    autoHeight
                    rows={contextData.questionTopics}
                    columns={questionTopicColumns}
                    apiRef={apiRef}
                    editMode="row"
                    rowsPerPageOptions={[5, 10, 15]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'questionCategory', sort: 'asc' }],
                        },
                        pagination: {
                            pageSize: 10,
                            page: 0,
                        },
                    }}
                />

                <Box sx={{ marginBottom: 1 }}>
                    <NewQuestionBlockModal
                        open={addQuestionBlockModalOpen}
                        onClose={handleAddQuestionBlockModalClose}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginBottom: 2,
                        }}
                    >
                        <Typography variant="h2">Question Blocks </Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => setAddQuestionBlockModalOpen(true)}
                        >
                            Add Question Block
                        </Button>
                    </Box>
                </Box>

                <DataGridPro
                    pagination
                    autoHeight
                    rows={contextData.questionBlocks}
                    columns={questionBlockColumns}
                    apiRef={apiRef}
                    editMode="row"
                    rowsPerPageOptions={[5, 10, 15]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'questionCategory', sort: 'asc' }],
                        },
                        pagination: {
                            pageSize: 10,
                            page: 0,
                        },
                    }}
                />

                <Box sx={{ marginBottom: 1 }}>
                    <NewQuestionModal
                        open={addQuestionModalOpen}
                        questions={contextData.questions}
                        contextData={contextData}
                        onClose={handleAddQuestionModalClose}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginBottom: 2,
                        }}
                    >
                        <Typography variant="h2">Question library</Typography>
                        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setQuestionModalOpen(true)}>
                            Add Question
                        </Button>
                    </Box>
                </Box>
                <DataGridPro
                    pagination
                    autoHeight
                    rows={contextData.questions}
                    columns={questionColumns}
                    apiRef={apiRef}
                    editMode="row"
                    rowsPerPageOptions={[5, 10, 15]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'questionCategory', sort: 'asc' }],
                        },
                        pagination: {
                            pageSize: 10,
                            page: 0,
                        },
                    }}
                />

                <Box marginTop={4}>
                    <ConfirmDialog {...confirmDialogProps} />
                    <NewVisaTypeModal
                        open={addVisaModalOpen}
                        user={contextData.user}
                        contextData={contextData}
                        onClose={handleAddVisaTypeModalClose}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginBottom: 2,
                        }}
                    >
                        <Typography variant="h2">Visa Types</Typography>
                        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setAddVisaModalOpen(true)}>
                            Add Visa
                        </Button>
                    </Box>
                </Box>
                <DataGridPro
                    autoPageSize
                    pagination
                    rows={visaTypeRows}
                    columns={visaTypeColumns}
                    apiRef={apiRef}
                    // getRowId={(row) => `${row.pathName}`}
                    editMode="row"
                    rowsPerPageOptions={[5, 10, 15]}
                    sx={{ minHeight: 400, marginBottom: 8 }}
                />
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    return <Loading />
}
