import { useState, useContext } from 'react'
import { Box, Button, Typography, TextField, Select, MenuItem, FormControl } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RedDivider from '../common/redDivider'
import { UserContext } from '../../common/userContext'
import { Visa, VisaApplication } from '../../graphql/API'
import { callApi } from '../../common/apiUtils'
import { createVisaApplication } from '../../graphql/mutations'
import { CommonStyles } from '../../assets/theme'
import { CreateVisaApplicationInput } from '../../API'
const { v4: uuid } = require('uuid')
type NewVisaTypeModalProps = {
    open: boolean
    visaTypes: Array<Visa>
    onClose: (e: any) => void
}

const NewVisaApplicationModal = (props: NewVisaTypeModalProps) => {
    const { onClose, open } = props
    const [visaType, setVisaType] = useState<string>('')
    const [visaTypeName, setVisaTypeName] = useState<string>('')
    const [dueDate, setDueDate] = useState<string>()
    const [needsConfirmation, setNeedsConfirmation] = useState<string>('')

    const [processing, setProcessing] = useState<boolean>(false)
    const [errorState, setErrorState] = useState<string>('')
    const [errorData, setErrorData] = useState<any>({
        visaType: '',
        visaTypeName: '',
    })

    const contextData = useContext(UserContext)

    const handleClose = (e) => {
        onClose({ completed: false })
        setNeedsConfirmation('')
    }

    const clearFields = () => {
        setVisaType('')
        setVisaTypeName('')
        setDueDate('')
        setNeedsConfirmation('')
    }

    const handleNext = () => {
        setErrorState('')
        if (!visaType || visaType === '' || visaType === undefined) setErrorState('Please select a visa type!')

        if (errorState === '') setNeedsConfirmation('next')

        const current = contextData.visaTypes.find((v) => v.id === visaType)
        setVisaTypeName(current!.name!)
    }

    const handleSubmit = async () => {
        try {
            setProcessing(true)
            const item: CreateVisaApplicationInput = {
                userId: contextData.user.sub,
                id: uuid(),
                name: contextData.visaTypes.find((vt) => vt.id === visaType)?.name ?? "",
                visaType: visaType,
                dueDate: dueDate,
            }
            const addVisaApplication = await callApi<Array<VisaApplication>>(
                contextData.user,
                'createVisaApplication',
                {
                    query: createVisaApplication,
                    variables: { item: item },
                }
            )
            if (!addVisaApplication.Result) {
                return addVisaApplication.Error
            }
            if (addVisaApplication.Error) {
                console.log('Error!', addVisaApplication.Error)
                return
            }
        } catch {
        } finally {
            onClose({ completed: true })
            clearFields()
            setErrorData({
                visaType: '',
                visaTypeName: '',
            })
            setProcessing(false)
        }
    }

    return (
        <Dialog onClose={handleClose} open={open} sx={{ borderRadius: 1 }}>
            {needsConfirmation === '' && (
                <Box sx={{ width: '475px', padding: 1 }}>
                    <DialogTitle variant="h2" sx={{ marginBottom: 0 }}>
                        New application checklist
                        <RedDivider />
                    </DialogTitle>
                    <DialogContent>
                        {contextData.visaTypes && (
                            <FormControl sx={{ marginBottom: 2 }} fullWidth>
                                <Typography variant="body1" color="secondary" marginBottom={1}>
                                    Visa type:
                                </Typography>
                                <Select
                                    autoFocus
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    name="visa type"
                                    value={visaType}
                                    onChange={(e) => setVisaType(e.target.value)}
                                    sx={{ width: '100%' }}
                                    size="small"
                                >
                                    <MenuItem key={''} value={''}>
                                        - please select a visa type -
                                    </MenuItem>
                                    {contextData.visaTypes
                                        .filter((vt) => vt.published)
                                        .map((v) => (
                                            <MenuItem key={v.id} value={v.id}>
                                                {v.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}

                        <FormControl sx={{ marginBottom: 4 }} fullWidth>
                            <Typography variant="body1" color="secondary" marginBottom={1}>
                                Enter due date (optional):
                                <TextField
                                    id="dueDate"
                                    value={dueDate}
                                    onChange={(e) => setDueDate(e.target.value)}
                                    type="date"
                                    size="small"
                                    sx={{ width: '100%' }}
                                />
                            </Typography>
                            {errorState && (
                                <Box sx={{ fontSize: '10px', marginBottom: '10px' }}>
                                    <Typography variant="body2" color="error">
                                        {errorState}
                                    </Typography>
                                </Box>
                            )}
                        </FormControl>
                        <Box>
                            <Box sx={CommonStyles.buttonFlex}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        alignSelf: 'end',
                                        marginRight: '10px',
                                    }}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        alignSelf: 'end',
                                    }}
                                    onClick={handleNext}
                                >
                                    Create
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Box>
            )}
            {needsConfirmation === 'next' && (
                <Box sx={{ width: '475px', padding: 1 }}>
                    <DialogTitle variant="h2" sx={{ marginBottom: 0 }}>
                        Confirmation
                        <RedDivider />
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" color="secondary">
                            You are creating a new application checklist of the following type:{' '}
                        </Typography>
                        <Box sx={{ display: 'flex', marginBottom: 2 }}>
                            <Typography variant="body1" color="secondary" paddingRight={2}>{`•    `}</Typography>
                            <Typography variant="body1" color="secondary">
                                {visaTypeName}
                            </Typography>
                        </Box>
                        <Typography variant="body1" color="secondary">
                            Are you sure you want to create this visa application type? This will create a duplicate if
                            you have an application of the same type.
                        </Typography>
                        <Box>
                            <Box sx={CommonStyles.buttonFlex}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        alignSelf: 'end',
                                        marginRight: '10px',
                                    }}
                                    onClick={() => setNeedsConfirmation('')}
                                >
                                    Back
                                </Button>

                                <LoadingButton
                                    variant="contained"
                                    loading={processing}
                                    loadingIndicator="CREATING"
                                    sx={{
                                        alignSelf: 'end',
                                    }}
                                    onClick={handleSubmit}
                                >
                                    Confirm
                                </LoadingButton>
                            </Box>
                        </Box>
                    </DialogContent>
                </Box>
            )}
        </Dialog>
    )
}

export default NewVisaApplicationModal
