import { useState, useContext, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Backdrop, Box, Button, CircularProgress, Typography, Link } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { ConfirmDialog, ConfirmDialogProps, ConfirmDialogPropsClosed } from '../common/confirmDialog'
import { getErrorMessage } from '../../common/errorUtils'
import { callApi } from '../../common/apiUtils'
import { UserAuth0, UserAuth0Input, User, UserLedgeRecInput } from '../../graphql/API'
import { getUserAuth0ById } from '../../graphql/queries'
import { updateUserAuth0, updateUserLedgeRec } from '../../graphql/mutations'
import { PageHeader } from '../common/pageHeader'
import { UserContext } from '../../common/userContext'
import { CommonStyles, theme } from '../../assets/theme'

const privacyPolicy = 'https://drive.google.com/file/d/1uLaXJjuDAdyEhXT-7xNiw353VLSowJJC/view?usp=sharing'

export function Privacy() {
    const context = useContext(UserContext)

    const [info, setInfo] = useState<[string, boolean]>(['', false])
    const [backdropOpen, setBackdropOpen] = useState<boolean>(true)
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps>(ConfirmDialogPropsClosed)
    const [usesMfa, setUsesMfa] = useState(false)
    const [initialised, setInitialised] = useState(false)

    useEffect(() => {
        const initialise = async () => {
            try {
                // await clearCandidateContext(context)
                const result = await callApi<UserAuth0>(context.user, 'getUserAuth0ById', {
                    query: getUserAuth0ById,
                    variables: { pk: context.user.sub },
                })
                if (result.Error) {
                    setBackdropOpen(false)
                    setInfo([result.Error.message, true])
                    return
                }
                setUsesMfa(result.Result?.useMfa ?? false)
                // setUsesMfa(false)
                setInitialised(true)
            } catch (error) {
                console.log(error)
                setInfo(['There was en error initialising: ' + error, true])
            } finally {
                setBackdropOpen(false)
            }
        }

        initialise()
    }, [])

    const doUpdateMfa = async (enable: boolean, signOutOnComplete: boolean) => {
        try {
            setBackdropOpen(true)
            const update: UserAuth0Input = {
                id: context.user.sub,
                useMfa: false,
                resetMfa: true,
            }
            const result = await callApi<UserAuth0>(context.user, 'updateUserAuth0', {
                query: updateUserAuth0,
                variables: { item: update },
            })
            if (result.Error) {
                setBackdropOpen(false)
                setInfo([result.Error.message, true])
                return
            }
            if (signOutOnComplete) {
                context.user.signOut()
                return
            }
            setUsesMfa(enable)
            setInfo(['', false])
        } catch (error) {
            setInfo([getErrorMessage('Update privacy', error), true])
        } finally {
            setBackdropOpen(false)
        }
    }

    const updateMfa = async (enable: boolean) => {
        const handleConfirmDialogResult = async (action: string, data: any, result: boolean) => {
            setConfirmDialogProps(ConfirmDialogPropsClosed)
            if (enable) {
                await doUpdateMfa(enable, result)
            } else {
                if (result) await doUpdateMfa(enable, false)
            }
        }

        const props: ConfirmDialogProps = enable
            ? {
                  open: true,
                  title: 'Enable MFA',
                  description:
                      'Enabling MFA will require an initial configuration that will log you out of your account. Do you want to configure MFA for your account now or on your next login?',
                  action: 'Yes, I would like to configure MFA now',
                  cancel: "I'll configure MFA later",
                  callback: handleConfirmDialogResult,
                  data: enable,
              }
            : {
                  open: true,
                  title: 'Disable MFA',
                  description: 'Are you sure that you would like to disable MFA for your account?',
                  action: 'Yes',
                  callback: handleConfirmDialogResult,
                  data: enable,
              }
        setConfirmDialogProps(props)
    }

    return (
        <Box
            sx={{
                mt: 1,
                ml: 0,
                maxWidth: 1000,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />

            <PageHeader title="Privacy" />

            <Box sx={{ mb: 4 }}>
                <Typography variant="h2">Multi Factor Authentication</Typography>

                <Typography variant="body1" color={theme.palette.secondary.main}>
                    Before you begin using the Globoz Portal, we strongly suggest that you enable multi-factor
                    authentication (MFA) to ensure maximum protection over any personal details or documents you may
                    choose to upload. MFA can be enabled or disabled at any time via this page.
                </Typography>

                {initialised && usesMfa && (
                    <Box sx={{ mb: 3 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                mt: 2,
                            }}
                        >
                            <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                            <Typography variant="body1" color={theme.palette.success.main}>
                                Multi Factor Authentication is currently enabled
                            </Typography>
                        </Box>
                    </Box>
                )}

                {initialised && usesMfa === false && (
                    <Box sx={{ mb: 3 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                mt: 4,
                                mb: 4,
                            }}
                        >
                            <ErrorIcon color="error" sx={{ mr: 1 }} />
                            <Typography sx={{ fontSize: 19, color: theme.palette.warning.main }}>
                                Multi Factor Authentication is currently disabled
                            </Typography>
                        </Box>

                        <Typography variant="body1" color={theme.palette.secondary.main}>
                            Enabling MFA will require an initial configuration. Disabling MFA will clear all
                            configuration settings and require you to reconfigure your MFA settings if MFA is
                            re-enabled.
                        </Typography>
                    </Box>
                )}

                {/* {info[0] && (
    <Typography variant="h6" component="div" sx={{ mt: 2 }} color={info[1] === false ? 'green' : 'error'}>
        {info[0]}
    </Typography>
)} */}
                {initialised && (
                    <Box>
                        <Button
                            variant="contained"
                            onClick={() => updateMfa(true)}
                            disabled={!initialised || usesMfa}
                            sx={{ mr: 2 }}
                        >
                            Enable MFA
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => updateMfa(false)}
                            disabled={!initialised || !usesMfa}
                        >
                            Disable MFA
                        </Button>
                    </Box>
                )}
            </Box>

            <Box>
                {/* <Typography variant="h2" marginBottom={2}>
                    Enable Globoz document access
                </Typography>
                <Box>
                    <Box marginBottom={4}>
                        <Typography variant="body1" marginBottom={1}>
                            Globoz provides recruitment services to facilitate doctors who are looking for locum
                            positions.
                        </Typography>

                        <Typography variant="body1">
                            Enabling document access allows our recruiters to access your uploaded documents to
                            conveniently match you with roles that may be suitable for you. Document access must be
                            enabled if you wish to engage Globoz's agency service.
                        </Typography>
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            onClick={() => updateUsesLedgeRec(true)}
                            disabled={!initialised || usesLedgeRec || !context.user.emailVerified}
                            sx={{ mr: 2 }}
                        >
                            Allow Globoz to access my documents
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => updateUsesLedgeRec(false)}
                            disabled={!initialised || !usesLedgeRec}
                            sx={{ mr: 2 }}
                        >
                            Revoke access to my documents
                        </Button>
                    </Box>
                </Box> */}

                {context.user.emailVerified === false && (
                    <Box mt={0} sx={[CommonStyles.dashedBorder, {padding: 2}]}>
                        <Typography variant="h3" color="error" marginBottom={1}>
                            Email Verification Required
                        </Typography>
                        <Typography variant="body1" color={theme.palette.secondary.main}>
                            Your email address has not yet been verified. You need to verify your email address before
                            enabling Globoz document access. Go to the{' '}
                            <Link component={RouterLink} to="/profile" color={theme.palette.error.main} sx={{}}>
                                profile page
                            </Link>{' '}
                            for instructions on how to verify your email.
                        </Typography>
                    </Box>
                )}
            </Box>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}
