import { useState, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import * as common from './profileCommon'
import TextField from '@mui/material/TextField'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid/Grid'

export function ProfileInitial({
    userInput,
    gridItemWidth,
    mobileMode,
    setValue,
    updateProfile,
}: common.InitialProfileSectionProps) {
    const [stage, setStage] = useState<string>('start')

    const handleSelectJobs = (event: SelectChangeEvent<typeof userInput.jobInterestCategory>) => {
        const {
            target: { value },
        } = event
        setValue(
            event.target.name,
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const handleSelectAreaOfStudy = (event: SelectChangeEvent<typeof userInput.jobAreaOfStudy>) => {
        const {
            target: { value },
        } = event
        setValue(
            event.target.name,
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const handleHowDidYouFindOutAboutUs = (event: SelectChangeEvent<typeof userInput.jobHowDidYouFindOutAboutUs>) => {
        const {
            target: { value },
        } = event
        setValue(
            event.target.name,
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const initialCheck = (alt = false) => {
        if (alt) {
            if (
                userInput.firstName &&
                userInput.lastName &&
                userInput.phone &&
                userInput.sex &&
                userInput.dateOfBirth &&
                userInput.jobInterestCategory
            )
                return false
            return true
        }

        if (userInput.firstName && userInput.lastName && userInput.phone && userInput.sex && userInput.dateOfBirth)
            return false
        return true
    }

    const jobCheck = () => {
        if (
            userInput.jobPreviousExperience &&
            userInput.jobEnglishFluency &&
            userInput.jobAreaOfStudy &&
            userInput.jobDrive &&
            userInput.jobVisaStatus &&
            userInput.jobDays &&
            userInput.jobTime
        )
            return false
        return true
    }

    const getGridFieldWidths = (large: boolean = false) => {
        if (mobileMode) {
            return gridItemWidth * 2
        } else {
            if (large) return gridItemWidth * 2

            return gridItemWidth
        }
    }

    useEffect(() => {
        const initialise = async () => {}
        initialise()
    }, [])

    if (stage === 'start')
        if (!mobileMode) {
            return (
                <Box sx={{ paddingY: 4, paddingX: 20 }}>
                    <Box width={'auto'} margin={'auto'} textAlign={'center'} mb={4}>
                        <Typography variant="h1">Welcome to the Globoz portal!</Typography>
                        <Typography variant="body2" color={'secondary.light'}>
                            Welcome to our Visa checking service! We understand that applying for a visa can be a
                            stressful process, and our goal is to make it as easy and streamlined as possible. With our
                            service, you can quickly and easily check your eligibility for a visa, ensuring that you
                            have the necessary qualifications and documentation before you submit your application.
                        </Typography>
                    </Box>

                    <Box width={'auto'} justifyContent="center" display="flex">
                        <Button
                            sx={{ textTransform: 'capitalize', marginRight: 2 }}
                            variant="outlined"
                            onClick={() => setStage('visa')}
                        >
                            Just to manage my Visa
                        </Button>
                        <Button
                            sx={{ textTransform: 'capitalize' }}
                            variant="contained"
                            onClick={() => setStage('visaJob')}
                        >
                            I'm looking for work too!
                        </Button>
                    </Box>
                </Box>
            )
        } else {
            return (
                <Box sx={{ paddingY: 4, transform: 'translateY(50%)' }}>
                    <Box width={'auto'} margin={'auto'} textAlign={'center'} mb={4}>
                        <Typography variant="h1">Welcome to the Globoz portal!</Typography>
                        <Typography variant="body2" color={'secondary.light'}>
                            Welcome to our Visa checking service! We understand that applying for a visa can be a
                            stressful process, and our goal is to make it as easy and streamlined as possible. With our
                            service, you can quickly and easily check your eligibility for a visa, ensuring that you
                            have the necessary qualifications and documentation before you submit your application.
                        </Typography>
                    </Box>

                    <Button
                        sx={{ textTransform: 'capitalize', mb: 2 }}
                        fullWidth
                        variant="outlined"
                        onClick={() => setStage('visa')}
                    >
                        Just to manage my Visa
                    </Button>
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        fullWidth
                        variant="contained"
                        onClick={() => setStage('visaJob')}
                    >
                        I'm looking for work too!
                    </Button>
                </Box>
            )
        }

    if (stage === 'visa' || stage === 'visaJob') {
        return (
            <Box sx={{ padding: 4 }}>
                <Box mb={2}>
                    <Typography variant="h2" mb={1.5} color={'secondary.light'}>
                        Complete your profile:
                    </Typography>
                    <Typography variant="body1" color={'secondary.light'}>
                        Please complete the following details to get started
                    </Typography>
                </Box>

                <Grid container spacing={2} mb={4} paddingRight={2}>
                    <Grid item xs={getGridFieldWidths(true)} marginBottom={-2}>
                        <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                            General Details:
                        </Typography>
                    </Grid>

                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            First name:
                        </Typography>
                        <TextField
                            autoFocus
                            fullWidth
                            size="small"
                            name="firstName"
                            value={userInput.firstName}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            error={userInput.firstName ? false : true}
                            helperText={userInput.firstName ? '' : 'Please enter your first name!'}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            Last name:
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            name="lastName"
                            value={userInput.lastName}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            error={userInput.lastName ? false : true}
                            helperText={userInput.lastName ? '' : 'Please enter your last name!'}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            Preferred name:
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            name="preferredName"
                            value={userInput.preferredName}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            Gender:
                        </Typography>
                        <Select
                            id="sex"
                            name="sex"
                            value={userInput.sex}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                            error={userInput.sex ? false : true}
                        >
                            <MenuItem key={''} value="">
                                -- please select a value --
                            </MenuItem>
                            {common.profileGenderList.map((pgl) => (
                                <MenuItem key={pgl.key} value={pgl.value}>
                                    {pgl.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            Contact number:
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            name="phone"
                            value={userInput.phone}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            helperText={userInput.phone ? '' : 'Please enter a valid phone number!'}
                            error={userInput.phone ? false : true}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>

                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            Where are you currently situated?:
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            name="jobCurrentLocation"
                            value={userInput.jobCurrentLocation}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            Date of birth:
                        </Typography>
                        <TextField
                            id="dateOfBirth"
                            name="dateOfBirth"
                            type="date"
                            value={userInput.dateOfBirth}
                            helperText={userInput.dateOfBirth ? '' : 'Please enter a your date of birth!'}
                            error={userInput.dateOfBirth ? false : true}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={getGridFieldWidths(true)}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            How did you find out about us?
                        </Typography>
                        <Select
                            id="jobHowDidYouFindOutAboutUs"
                            name="jobHowDidYouFindOutAboutUs"
                            value={userInput.jobHowDidYouFindOutAboutUs}
                            multiple
                            onChange={handleHowDidYouFindOutAboutUs}
                            sx={{ backgroundColor: 'white' }}
                            size={'small'}
                            fullWidth
                        >
                            {common.jobHowDidYouFindOutAboutUsList.map((jhdyfoaul) => (
                                <MenuItem key={jhdyfoaul.key} value={jhdyfoaul.value}>
                                    {jhdyfoaul.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                <Box>
                    {stage === 'visaJob' && (
                        <Grid container spacing={2} mb={2} paddingRight={2}>
                            <Grid item xs={getGridFieldWidths(true)}>
                                <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                    What roles/job titles are you looking for?
                                </Typography>
                                <Select
                                    id="jobInterestCategory"
                                    name="jobInterestCategory"
                                    value={userInput.jobInterestCategory}
                                    multiple
                                    onChange={handleSelectJobs}
                                    sx={{ backgroundColor: 'white' }}
                                    size={'small'}
                                    fullWidth
                                >
                                    {common.jobInterestCategories.map((jic) => (
                                        <MenuItem key={jic.key} value={jic.value}>
                                            {jic.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {userInput.jobInterestCategory?.includes('other') && (
                                    <TextField
                                        id="jobInterestCategoryOther"
                                        name="jobInterestCategoryOther"
                                        value={userInput.jobInterestCategoryOther}
                                        onChange={(e) => setValue(e.target.name, e.target.value)}
                                        placeholder="Please enter role here"
                                        autoFocus
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white', marginTop: 2 }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {!mobileMode && (
                        <Box width={'auto'} justifyContent="center" display="flex">
                            <Button
                                sx={{ textTransform: 'capitalize', marginRight: 2 }}
                                variant="outlined"
                                onClick={() => setStage('start')}
                            >
                                Back
                            </Button>
                            {stage === 'visa' && (
                                <Button
                                    sx={{ textTransform: 'capitalize' }}
                                    variant="contained"
                                    onClick={updateProfile}
                                    disabled={initialCheck()}
                                >
                                    Complete
                                </Button>
                            )}

                            {stage === 'visaJob' && (
                                <Button
                                    sx={{ textTransform: 'capitalize' }}
                                    variant="contained"
                                    onClick={() => setStage('visaJobPreferences')}
                                    disabled={initialCheck(true)}
                                >
                                    Next
                                </Button>
                            )}
                        </Box>
                    )}
                    {mobileMode && (
                        <Box>
                            {stage === 'visa' && (
                                <Button
                                    sx={{ textTransform: 'capitalize', marginBottom: 2 }}
                                    variant="contained"
                                    onClick={updateProfile}
                                    disabled={initialCheck()}
                                    fullWidth
                                >
                                    Complete
                                </Button>
                            )}

                            {stage === 'visaJob' && (
                                <Button
                                    sx={{ textTransform: 'capitalize', marginBottom: 2 }}
                                    variant="contained"
                                    onClick={() => setStage('visaJobPreferences')}
                                    disabled={initialCheck(true)}
                                    fullWidth
                                >
                                    Next
                                </Button>
                            )}
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                variant="outlined"
                                onClick={() => setStage('start')}
                                fullWidth
                            >
                                Back
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }

    if (stage === 'visaJobPreferences') {
        return (
            <Box sx={{ padding: 4 }}>
                <Box mb={2}>
                    <Typography variant="h2" mb={1.5} color={'secondary.light'}>
                        Complete your job preferences:
                    </Typography>
                    <Typography variant="body1">
                        We will use the following details to suggest any available, compatible job opportunities.{' '}
                    </Typography>
                </Box>

                <Grid container spacing={2} mb={4} paddingRight={2}>
                    <Grid item xs={getGridFieldWidths(true)} marginBottom={-2}>
                        <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                            Employment:
                        </Typography>
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            What was your previous role title?:
                        </Typography>
                        <TextField
                            autoFocus
                            fullWidth
                            size="small"
                            name="jobPreviousExperience"
                            value={userInput.jobPreviousExperience}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            placeholder="Enter your previous role titles here"
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            English fluency:
                        </Typography>

                        <Select
                            id="jobEnglishFluency"
                            name="jobEnglishFluency"
                            value={userInput.jobEnglishFluency}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            size={'small'}
                            fullWidth
                            sx={{ backgroundColor: 'white' }}
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            {common.jobEnglishFluencyLevels.map((jefl) => (
                                <MenuItem key={jefl.key} value={jefl.value}>
                                    {jefl.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            What is/was your area of study?
                        </Typography>
                        <Select
                            id="jobAreaOfStudy"
                            name="jobAreaOfStudy"
                            value={userInput.jobAreaOfStudy}
                            multiple
                            onChange={handleSelectAreaOfStudy}
                            sx={{ backgroundColor: 'white' }}
                            size={'small'}
                            fullWidth
                        >
                            {common.jobAreasOfStudy.map((jaos) => (
                                <MenuItem key={jaos.key} value={jaos.value}>
                                    {jaos.text}
                                </MenuItem>
                            ))}
                        </Select>
                        {userInput.jobAreaOfStudy?.includes('other') && (
                            <TextField
                                id="jobAreaOfStudyOther"
                                name="jobAreaOfStudyOther"
                                value={userInput.jobAreaOfStudyOther}
                                onChange={(e) => setValue(e.target.name, e.target.value)}
                                sx={{ backgroundColor: 'white', marginTop: 2 }}
                                fullWidth
                                size="small"
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} mb={4} paddingRight={2}>
                    <Grid item xs={getGridFieldWidths(true)} marginBottom={-2}>
                        <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                            Transport:
                        </Typography>
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            Can you drive to work?
                        </Typography>
                        <Select
                            fullWidth
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            id="jobDrive"
                            name="jobDrive"
                            value={userInput.jobDrive}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            <MenuItem key={'Yes'} value={'yes'}>
                                Yes
                            </MenuItem>
                            <MenuItem key={'No'} value={'no'}>
                                No
                            </MenuItem>
                        </Select>
                    </Grid>
                    {userInput.jobDrive === 'yes' && (
                        <Grid item xs={getGridFieldWidths()}>
                            <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                How far are you able to drive for work?
                            </Typography>
                            <Select
                                id="jobFar"
                                name="jobFar"
                                value={userInput.jobFar}
                                onChange={(e) => setValue(e.target.name, e.target.value)}
                                fullWidth
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                            >
                                <MenuItem key={''} value={''}>
                                    -- please select a value --
                                </MenuItem>
                                {common.jobDrivingDistances.map((jdd) => (
                                    <MenuItem key={jdd.key} value={jdd.value}>
                                        {jdd.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={2} mb={4} paddingRight={2}>
                    <Grid item xs={getGridFieldWidths(true)} marginBottom={-2}>
                        <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                            Availability:
                        </Typography>
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            What is your current visa type?
                        </Typography>
                        <Select
                            fullWidth
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            id="jobVisaStatus"
                            name="jobVisaStatus"
                            value={userInput.jobVisaStatus}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            {common.jobVisaStatusList.map((jvs) => (
                                <MenuItem key={jvs.key} value={jvs.value}>
                                    {jvs.text}
                                </MenuItem>
                            ))}
                        </Select>
                        {userInput.jobVisaStatus === 'other' && (
                            <TextField
                                id="jobVisaStatusOther"
                                name="jobVisaStatusOther"
                                value={userInput.jobVisaStatusOther}
                                onChange={(e) => setValue(e.target.name, e.target.value)}
                                sx={{ backgroundColor: 'white', marginTop: 2 }}
                                fullWidth
                                size="small"
                            />
                        )}
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            How many days are you willing to work per week?
                        </Typography>
                        <Select
                            fullWidth
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            id="jobDays"
                            name="jobDays"
                            value={userInput.jobDays}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            {common.jobWorkDaysList.map((jwdl) => (
                                <MenuItem key={jwdl.key} value={jwdl.value}>
                                    {jwdl.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getGridFieldWidths()}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            What hours are you willing to work?
                        </Typography>
                        <Select
                            id="jobTime"
                            name="jobTime"
                            value={userInput.jobTime}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            {common.jobWorkTimesList.map((jwtl) => (
                                <MenuItem key={jwtl.key} value={jwtl.value}>
                                    {jwtl.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                {!mobileMode && (
                    <Box width={'auto'} justifyContent="center" display="flex">
                        <Button
                            sx={{ textTransform: 'capitalize', marginRight: 2 }}
                            variant="outlined"
                            onClick={() => setStage('visaJob')}
                        >
                            Back
                        </Button>

                        <Button
                            sx={{ textTransform: 'capitalize' }}
                            variant="contained"
                            onClick={updateProfile}
                            disabled={jobCheck()}
                        >
                            Complete
                        </Button>
                    </Box>
                )}
                {mobileMode && (
                    <Box>
                        <Button
                            sx={{ textTransform: 'capitalize', marginBottom: 2 }}
                            variant="contained"
                            onClick={updateProfile}
                            disabled={jobCheck()}
                            fullWidth
                        >
                            Complete
                        </Button>
                        <Button
                            sx={{ textTransform: 'capitalize' }}
                            variant="outlined"
                            onClick={() => setStage('visaJob')}
                            fullWidth
                        >
                            Back
                        </Button>
                    </Box>
                )}
            </Box>
        )
    }
    return <></>
}
