// https://github.com/Bilal-Bangash/detecting-route-change-react-route-dom-v6/blob/master/src/hooks/useBlocker.ts
// https://stackoverflow.com/questions/70288198/detecting-user-leaving-page-with-react-router-dom-v6-0-2
import * as React from 'react'
import { UNSAFE_NavigationContext } from 'react-router-dom'
import type { History, Blocker, Transition } from 'history'

export function useBlocker(blocker: Blocker, when: boolean): void {
    const navigator = React.useContext(UNSAFE_NavigationContext).navigator as History
    React.useEffect(() => {
        if (!when) return

        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock()
                    tx.retry()
                },
            }

            blocker(autoUnblockingTx)
        })

        return unblock
    }, [navigator, blocker, when])
}
