import { useState, useContext, useEffect } from 'react'
import { Box, Typography, TextField, Select, MenuItem, FormControl } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { UserContext } from '../../common/userContext'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import { callApi } from '../../common/apiUtils'
import { modifyQuestion, modifyQuestionBlock } from '../../graphql/mutations'
import { Link, useParams } from 'react-router-dom'
import { SelectChangeEvent } from '@mui/material/Select'
import { LoadingButton } from '@mui/lab'
import { Loading } from '../common/loading'
import { Question, QuestionBlock } from '../../graphql/API'
import Button from '@mui/material/Button'
import { CommonStyles } from '../../assets/theme'

export default function QuestionBlockPage() {
    const contextData = useContext(UserContext)

    const [loaded, setLoaded] = useState<boolean>(false)
    const [current, setCurrent] = useState<QuestionBlock>()
    const [title, setTitle] = useState<String>('')
    const [subtext, setSubtext] = useState<String>('')
    const [questions, setQuestions] = useState<Array<String | null>>([])
    const [questionList, setQuestionList] = useState<Array<Question>>([])
    const [order, setOrder] = useState<number>(999)
    const [conditionalShowQuestionId, setConditionalShowQuestionId] = useState<string>('')
    const [conditionalShowAnswer, setConditionalShowAnswer] = useState<string>('')
    const [newName, setNewName] = useState<string>('')
    const [editingNewName, setEditingNewName] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)
    const { id } = useParams()

    const doChange = async (val: number, qq: Question) => {
        let questionData = { ...qq, order: val }
        const modQuestion = await callApi<Question>(contextData.user, 'modifyQuestion', {
            query: modifyQuestion,
            variables: { item: questionData },
        })
        let newList = [...questionList]
        newList[newList.findIndex((quest) => quest.id === questionData.id)!] = modQuestion.Result!

        setQuestionList(newList)
    }

    const handleSubmit = async () => {
        setProcessing(true)

        const item = {
            id: current?.id,
            title: title,
            subtext: subtext,
            questions: questions,
            order: order,
            conditionalShowQuestionId: conditionalShowQuestionId ? conditionalShowQuestionId : '',
            conditionalShowAnswer: conditionalShowAnswer ? conditionalShowAnswer : '',
        }

        console.log(item!)

        const modQuestionBlock = await callApi<QuestionBlock>(contextData.user, 'modifyQuestionBlock', {
            query: modifyQuestionBlock,
            variables: { item: item },
        })
        console.log(modQuestionBlock.Result!)

        if (modQuestionBlock.Result) {
            const r = modQuestionBlock.Result
            setCurrent(r)
            setTitle(r.title!)
            setSubtext(r.subtext!)
            setQuestions(r.questions!)
            setOrder(r.order ? r.order : 999)
            setConditionalShowQuestionId(r.conditionalShowQuestionId ? r.conditionalShowQuestionId : '')
            setConditionalShowAnswer(r.conditionalShowAnswer ? r.conditionalShowAnswer : '')

            // reupdate the question list
            const qs = Array<Question>()
            r.questions?.forEach((qid) => {
                const toFind = contextData.questions.find((qq) => qq.id === qid)
                if (toFind) qs.push(toFind)
            })
            setQuestionList(qs)

            const f = contextData.questionBlocks.filter((c) => c.id !== r.id)
            contextData.setQuestionBlocks([...f, r])
        }

        setProcessing(false)
    }

    const saveName = async () => {
        setProcessing(true)
        const q = await callApi<QuestionBlock>(contextData.user, 'modifyQuestionBlock', {
            query: modifyQuestionBlock,
            variables: { item: { ...current, title: newName } },
        })
        if (q.Result) {
            setCurrent(q.Result)
            setTitle(newName)
        }
        setEditingNewName(false)
        setProcessing(false)
    }

    const handleSelectQuestions = (event: SelectChangeEvent<typeof questions>) => {
        const {
            target: { value },
        } = event
        setQuestions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                if (id) {
                    // initially make page unloaded, get the visas then set loaded
                    setLoaded(false)

                    const current = contextData.questionBlocks.filter((qt) => qt.id === id)[0]!

                    if (current) {
                        setCurrent(current)
                        setTitle(current.title!)
                        setSubtext(current.subtext!)
                        setQuestions(current.questions!)
                        setOrder(current.order ? current.order : 999)
                        setConditionalShowQuestionId(
                            current.conditionalShowQuestionId ? current.conditionalShowQuestionId : ''
                        )
                        setConditionalShowAnswer(current.conditionalShowAnswer ? current.conditionalShowAnswer : '')

                        var ql = Array<Question>()
                        current.questions?.forEach((qq) => {
                            const question = contextData.questions.find((n) => n.id === qq)
                            if(!question) 
                                throw new Error(`Missing question ${qq}!`);
                            ql.push(question)
                        })
                        setQuestionList(ql)
                    }
                }
            } finally {
                setLoaded(true)
            }
        }

        initialise()
    }, [])
    if (loaded && current && questionList)
        return (
            <Box maxWidth={'70vw'}>
                <Box marginBottom={2}>
                    {editingNewName && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                onChange={(e) => setNewName(e.target.value)}
                                size="small"
                                variant="standard"
                                sx={{ width: '400px' }}
                            />

                            <Box onClick={() => setEditingNewName(false)} sx={{ cursor: 'pointer', marginLeft: 2 }}>
                                <CloseIcon />
                            </Box>

                            <Box onClick={saveName} sx={{ cursor: 'pointer', marginLeft: 1 }}>
                                <DoneIcon />
                            </Box>
                        </Box>
                    )}
                    {!editingNewName && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h1" marginBottom={0}>
                                Editing {current.title}
                            </Typography>
                            <Box
                                sx={{ cursor: 'pointer', marginLeft: 2 }}
                                onClick={() => setEditingNewName(!editingNewName)}
                            >
                                <EditIcon />
                            </Box>
                        </Box>
                    )}
                </Box>
                <FormControl sx={{ marginBottom: 2, marginTop: 2 }} fullWidth>
                    <Typography variant="h4">Block Subtext:</Typography>
                    <TextField
                        id="blockSubText"
                        value={subtext}
                        onChange={(e) => setSubtext(e.target.value)}
                        type="number"
                        size="small"
                        multiline
                        minRows={4}
                    />
                </FormControl>

                <FormControl sx={{ marginBottom: 2 }}>
                    <Typography variant="h4">Block Order Number</Typography>
                    <TextField
                        id="order"
                        name="order"
                        type="number"
                        value={order}
                        onChange={(e) => setOrder(parseInt(e.target.value))}
                        size="small"
                        fullWidth
                        sx={{ marginBottom: 1 }}
                    />
                </FormControl>
                <FormControl sx={{ width: '100%', marginBottom: 4 }}>
                    <Typography variant="h4">Questions assigned:</Typography>
                    <Select
                        id="questions"
                        name="questions"
                        multiple
                        value={questions}
                        onChange={handleSelectQuestions}
                        size="small"
                        sx={{ background: '#fff' }}
                    >
                        {contextData.questions.map((d) => (
                            <MenuItem key={d.id} value={d.id} sx={{ maxWidth: '100%' }}>
                                {d.questionText}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {questionList.length > 0 && (
                    <FormControl sx={{ marginBottom: 3 }} fullWidth>
                        <Typography variant="h4">Question Order</Typography>
                        {questionList.map((qq) => (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                <Typography sx={{ maxWidth: '70%' }}>{qq.questionText}</Typography>
                                <TextField
                                    id={qq.id}
                                    name={qq.id}
                                    type="number"
                                    onChange={(e) => doChange(parseInt(e.target.value), qq)}
                                    defaultValue={qq.order}
                                    size="small"
                                    sx={{ height: '100%' }}
                                />
                            </Box>
                        ))}
                    </FormControl>
                )}

                <FormControl sx={{ marginBottom: 3 }} fullWidth>
                    <Typography variant="h4">Conditional Display Settings</Typography>
                    <Typography variant="body2" color={'secondary.light'}>
                        Select a Yes/No question and answer to make this question only appear when the selected question
                        is answered
                    </Typography>
                    <Box maxWidth={'100%'} marginBottom={2}>
                        <Select
                            id="conditionalShowQuestionId"
                            name="conditionalShowQuestionId"
                            value={conditionalShowQuestionId}
                            onChange={(e) => setConditionalShowQuestionId(e.target.value)}
                            size="small"
                            sx={{ background: '#fff' }}
                            fullWidth
                        >
                            {contextData.questions
                                .filter((qq) => qq.questionType === 'boolean')
                                .map((d) => (
                                    <MenuItem key={d.id} value={d.id} sx={{ maxWidth: '100%' }}>
                                        {d.questionText}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Box>
                    <Box maxWidth={'100%'}>
                        <Typography variant="body2" color={'secondary.light'}>
                            The block will become visible if the answer below is selected for that question
                        </Typography>
                        <Select
                            id="conditionalShowAnswer"
                            name="conditionalShowAnswer"
                            value={conditionalShowAnswer}
                            onChange={(e) => setConditionalShowAnswer(e.target.value)}
                            size="small"
                            sx={{ background: '#fff' }}
                            fullWidth
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            <MenuItem key={'yes'} value={'yes'}>
                                Yes
                            </MenuItem>
                            <MenuItem key={'no'} value={'no'}>
                                No
                            </MenuItem>
                        </Select>
                    </Box>
                </FormControl>

                <Box>
                    <Box sx={CommonStyles.buttonFlex}>
                        <Button
                            variant="outlined"
                            sx={{
                                alignSelf: 'end',
                                marginRight: '10px',
                            }}
                            component={Link}
                            to="/questiondashboard"
                        >
                            Close
                        </Button>
                        <LoadingButton
                            variant="contained"
                            loading={processing}
                            loadingIndicator="SAVING!"
                            sx={{
                                alignSelf: 'end',
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Box>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    return <Loading />
}
