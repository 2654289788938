import { useState, useContext } from 'react'
import { Box, Button, Typography, TextField, Select, SelectChangeEvent, MenuItem, FormControl } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { UserContext } from '../../common/userContext'
import DialogTitle from '@mui/material/DialogTitle'
import RedDivider from '../common/redDivider'
import { callApi } from '../../common/apiUtils'
import { createQuestionBlock, createQuestionTopic } from '../../graphql/mutations'
import { questionCategories } from '../questions/questionCommon'
import { CommonStyles, theme } from '../../assets/theme'
import { QuestionBlock, QuestionTopic } from '../../graphql/API'

const { v4: uuid } = require('uuid')
type NewQuestionModalProps = {
    open: boolean
    onClose: (e: any) => void
}

const NewQuestionTopicModal = (props: NewQuestionModalProps) => {
    const { onClose, open } = props

    const [topicCategory, setTopicCategory] = useState<string>('')
    const [topicTitle, setTopicTitle] = useState<string>('')
    const [topicSubtext, setTopicSubText] = useState<string>('')
    const [topicOrder, setTopicOrder] = useState<number>(99)
    const [topicTags, setTopicTags] = useState<Array<string>>([])

    const [blockTitle, setBlockTitle] = useState<string>('')
    const [blockSubtext, setBlockSubtext] = useState<string>('')
    const [blockQuestions, setQuestionBlockQuestions] = useState<Array<string>>([])

    const [processing, setProcessing] = useState<boolean>()

    const contextData = useContext(UserContext)

    const handleSelectTags = (event: SelectChangeEvent<typeof topicTags>) => {
        const {
            target: { value },
        } = event
        setTopicTags(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const clearFields = () => {
        setTopicCategory('')
        setTopicTitle('')
        setTopicSubText('')
        setTopicOrder(99)
        setTopicTags([])
        setBlockTitle('')
        setBlockSubtext('')
        setQuestionBlockQuestions([])
    }

    const handleClose = () => {
        clearFields()
        onClose({ completed: false })
    }

    const handleSubmit = async () => {
        setProcessing(true)
        const blockId = uuid()

        if (topicCategory && topicTitle) {
            const topicItem = {
                id: uuid(),
                category: topicCategory,
                title: topicTitle,
                subtext: topicSubtext,
                order: topicOrder ? topicOrder : 99,
                tags: topicTags ? topicTags : [],
                questionBlocks: blockTitle && blockTitle !== '' ? [blockId] : [],
            }

            const qt = await callApi<QuestionTopic>(contextData.user, 'createQuestionTopic', {
                query: createQuestionTopic,
                variables: { item: topicItem },
            })

            contextData.setQuestionTopics([...contextData.questionTopics, qt.Result!])
        }

        if (blockTitle && blockTitle !== '') {
            const blockItem = {
                id: blockId,
                title: blockTitle,
                subtext: blockSubtext,
                questions: blockQuestions,
                order: 99,
            }

            const qb = await callApi<QuestionBlock>(contextData.user, 'createQuestionBlock', {
                query: createQuestionBlock,
                variables: { item: blockItem },
            })

            contextData.setQuestionBlocks([...contextData.questionBlocks, qb.Result!])
        }

        clearFields()
        setProcessing(false)
        onClose({ completed: true })
    }

    const handleSelectQuestions = (event: SelectChangeEvent<typeof blockQuestions>) => {
        const {
            target: { value },
        } = event
        setQuestionBlockQuestions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    return (
        <Dialog onClose={handleClose} open={open} sx={{ borderRadius: 1 }}>
            <Box sx={{ width: '475px', padding: 1 }}>
                <DialogTitle variant="h2" sx={{ marginBottom: 0 }}>
                    Create a new question topic
                    <RedDivider />
                </DialogTitle>
                <DialogContent>
                    <FormControl sx={{ marginBottom: 2 }} fullWidth>
                        <Typography variant="body2" color="secondary">
                            Select topic category
                        </Typography>
                        <Select value={topicCategory} onChange={(e) => setTopicCategory(e.target.value)} size="small">
                            <MenuItem key={''} value={''}>
                                - please select a type -
                            </MenuItem>
                            {questionCategories.map((qc) => (
                                <MenuItem sx={{ textTransform: 'capitalize' }} key={qc} value={qc}>
                                    {`${qc}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <Typography variant="h4" color={theme.palette.primary.light}>
                            Question Topic:
                        </Typography>
                        <Typography variant="body2" color="secondary">
                            Enter topic:
                        </Typography>
                        <TextField
                            id="topicTitle"
                            value={topicTitle}
                            onChange={(e) => setTopicTitle(e.target.value)}
                            size="small"
                            fullWidth
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <Typography variant="body2" color="secondary">
                            Enter topic sub-text:
                        </Typography>
                        <TextField
                            id="topicSubtext"
                            value={topicSubtext}
                            onChange={(e) => setTopicSubText(e.target.value)}
                            size="small"
                            multiline
                            minRows={4}
                            fullWidth
                        />
                    </FormControl>

                    <FormControl>
                        <Typography variant="body2" color={'secondary'}>
                            Set topic appearance order
                        </Typography>
                        <TextField
                            id="order"
                            name="order"
                            type="number"
                            value={topicOrder}
                            onChange={(e) => setTopicOrder(parseInt(e.target.value))}
                            size="small"
                            fullWidth
                            sx={{ marginBottom: 1 }}
                        />
                    </FormControl>

                    <FormControl sx={{ marginBottom: 2 }} fullWidth>
                        <Typography variant="body2" color={'secondary'}>
                            Tags assigned:
                        </Typography>
                        <Select
                            id="tags"
                            name="tags"
                            multiple
                            value={topicTags}
                            onChange={handleSelectTags}
                            size="small"
                            sx={{ background: '#fff' }}
                        >
                            {contextData.visaTypes.map((vt) => (
                                <MenuItem key={vt.name} value={vt.name}>
                                    {vt.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Box sx={{ marginBottom: 2, marginTop: 2 }}>
                        <Typography variant="body1" color={theme.palette.primary.light}>
                            Question block:
                        </Typography>
                        <Typography variant="body2" marginBottom={2} color="secondary">
                            You can add additional blocks in the editor menu in the admin dashboard!
                        </Typography>
                    </Box>
                    <Box sx={[CommonStyles.dashedBorder, { padding: 1, margin: -1, marginBottom: 3 }]}>
                        <FormControl sx={{ marginBottom: 2 }} fullWidth>
                            <Box>
                                <Typography variant="body2" color="secondary">
                                    Enter block name:
                                </Typography>
                                <TextField
                                    id="blockTitle"
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    size="small"
                                    fullWidth
                                />
                            </Box>
                        </FormControl>

                        <FormControl sx={{ marginBottom: 2 }} fullWidth>
                            <Typography variant="body2" color="secondary">
                                Enter block sub-text:
                            </Typography>
                            <TextField
                                id="blockSubtext"
                                value={blockSubtext}
                                onChange={(e) => setBlockSubtext(e.target.value)}
                                size="small"
                                fullWidth
                                multiline
                                minRows={4}
                            />
                        </FormControl>

                        <FormControl sx={{ marginBottom: 2 }} fullWidth>
                            <Typography variant="body2" color="secondary">
                                Add questions by ID:
                            </Typography>
                            <Select
                                id="blockQuestions"
                                multiple
                                value={blockQuestions}
                                onChange={handleSelectQuestions}
                                size={'small'}
                                fullWidth
                            >
                                {contextData.questions.map((q) => (
                                    <MenuItem key={q.id} value={q.id} sx={{ maxWidth: 400 }}>
                                        {q.questionText}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box>
                        <Box sx={CommonStyles.buttonFlex}>
                            <Button
                                variant="outlined"
                                sx={{
                                    alignSelf: 'end',
                                    marginRight: '10px',
                                }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={processing}
                                loadingIndicator="CREATING"
                                sx={{
                                    alignSelf: 'end',
                                }}
                                onClick={handleSubmit}
                            >
                                Create
                            </LoadingButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default NewQuestionTopicModal
