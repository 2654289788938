import { useContext } from 'react'
import { Route, Routes, Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, Drawer, List, ListItemButton } from '@mui/material'
import { UserContext } from '../common/userContext'

import QuestionDashboard from './admin/questionDashboard'

import { Profile, ProfileDialog } from './profile/profile'
import { Privacy } from './profile/privacy'
import About from './about'
import Logo from '../assets/logo.svg'
import BoxRow from '../assets/headerBoxRow.png'
import { Responses } from './responses/responses'
import Applications from './applications/applications'
import QuestionPage from './questions/questionPage'
import VisaPage from './visas/visaPage'
import ApplicationsPage from './applications/applicationsPage'
import QuestionBlockPage from './questions/questionBlockPage'
import QuestionTopicPage from './questions/questionTopicPage'
import { CommonStyles } from '../assets/theme'
import { ProfileJobsDetails } from './profile/profileJobsDetails'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MobileOverlayBlock } from './mobileOverlayBlock'
import UserList from './admin/userList'
import AdminUserPage from './admin/adminUserPage'

const drawerWidth = 200

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    padding: '90px 24px 24px 224px',
}))

export function MainWindow() {
    const contextData = useContext(UserContext)
    const mobileMode = useMediaQuery('(max-width:700px)')
    if (!contextData.ledgeUser) return <ProfileDialog />
    else {
        if (mobileMode) return <MobileOverlayBlock />
        return (
            <Box sx={{ borderRadius: 0, height: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        zIndex: 2,
                        height: 65,
                        padding: '12px 32px',
                        width: '100%',
                        background: '#fff',
                        boxShadow: 2,
                        position: 'fixed',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            position: 'relative',
                            height: '100%',
                        }}
                        src={Logo}
                        alt="logo"
                    />

                    <Box
                        component="img"
                        sx={{
                            position: 'relative',
                            height: 19,
                            width: 120,
                        }}
                        src={BoxRow}
                        alt="colourful row of boxes haha"
                    />
                </Box>
                <Box sx={{ display: 'flex', height: '100%', minHeight: '100vh' }}>
                    <Drawer
                        sx={{
                            minWidth: drawerWidth,
                            flexShrink: 0,
                            zIndex: 1,
                            position: 'fixed',
                            height: '100vh',
                            paddingTop: '65px',
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                                backgroundColor: '#402E8D',
                                position: 'relative',
                                borderRadius: 0,
                                border: 'none',
                            },
                        }}
                        variant="permanent"
                        anchor="left"
                    >
                        <List style={{ padding: 0 }}>
                            <ListItemButton
                                key="Applications"
                                component={Link}
                                to="/applications"
                                sx={CommonStyles.sideBarBoxStyle}
                            >
                                Applications
                            </ListItemButton>

                            <ListItemButton
                                key="Responses"
                                component={Link}
                                to="/responses"
                                sx={CommonStyles.sideBarBoxStyle}
                            >
                                My Responses
                            </ListItemButton>
                            {/* <ListItemButton key="Documents" component={Link} to="/documents" sx={CommonStyles.sideBarBoxStyle}>
                                My Documents
                            </ListItemButton> */}
                            {/* <ListItemButton
                                key="KnowledgeCenter"
                                onClick={() =>
                                    window.open('https://help.ledgevisa.com.au/', 'KnowledgeCenter', 'noopener,noreferrer')
                                }
                                sx={CommonStyles.sideBarBoxStyle}
                            >
                                Portal Guide
                            </ListItemButton> */}
                            <ListItemButton key="Jobs" component={Link} to="/jobs" sx={CommonStyles.sideBarBoxStyle}>
                                Jobs
                            </ListItemButton>

                            {/* <ListItemButton
                                key="Help"
                                component={Link}
                                to="/help"
                                sx={CommonStyles.sideBarBoxStyle}
                            >
                                Help
                            </ListItemButton> */}
                        </List>

                        <List style={{ marginTop: `auto`, paddingBottom: 0 }}>
                            {contextData.user.isAdmin() && (
                                <>
                                    <ListItemButton
                                        key="Questions Dashboard"
                                        component={Link}
                                        to="/questiondashboard"
                                        sx={[CommonStyles.sideBarBoxStyle, { borderTop: '1px solid #644DC8' }]}
                                    >
                                        Question Dashboard
                                    </ListItemButton>

                                    <ListItemButton
                                        key="User List"
                                        component={Link}
                                        to="/userList"
                                        sx={[CommonStyles.sideBarBoxStyle, { borderTop: '1px solid #644DC8' }]}
                                    >
                                        User List
                                    </ListItemButton>
                                </>
                            )}

                            <ListItemButton
                                key="Profile"
                                component={Link}
                                to="/profile"
                                sx={CommonStyles.sideBarBoxStyle}
                            >
                                Profile
                            </ListItemButton>
                            {/* <ListItemButton key="Privacy" component={Link} to="/privacy" sx={CommonStyles.sideBarBoxStyle}>
                                Privacy
                            </ListItemButton> */}
                            {/* <ListItemButton
                                key="ServiceDesk"
                                sx={CommonStyles.sideBarBoxStyle}
                                onClick={() =>
                                    window.open(
                                        'https://elco.atlassian.net/servicedesk/customer/portal/1',
                                        'ServiceDesk',
                                        'noopener,noreferrer'
                                    )
                                }
                            >
                                Service Desk
                            </ListItemButton> */}
                            {/* <ListItemButton key="About" component={Link} to="/about" sx={CommonStyles.sideBarBoxStyle}>
                                About
                            </ListItemButton> */}
                            <ListItemButton
                                sx={CommonStyles.sideBarBoxStyle}
                                key="SignOut"
                                onClick={(e) => contextData.user.signOut()}
                            >
                                Sign Out
                            </ListItemButton>
                        </List>
                    </Drawer>
                    <Main sx={{ width: '100%' }}>
                        <Routes>
                            <Route path="/" element={<Profile dialog={false} mobileMode={mobileMode} />} />
                            <Route path="/profile" element={<Profile dialog={false} mobileMode={mobileMode} />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/responses" element={<Responses />} />
                            <Route path="/knowledgecentre" element={<Privacy />} />
                            <Route path="/applications" element={<Applications />} />
                            <Route path="/applications/:id" element={<ApplicationsPage />} />
                            <Route path="/questiondashboard" element={<QuestionDashboard />} />
                            <Route path="/userList" element={<UserList />} />
                            {/* <Route path="/help" element={<QuestionDashboard />} /> */}
                            <Route path="/userList/user" element={<AdminUserPage />} />
                            <Route path="/userList/user/:id" element={<AdminUserPage />} />
                            <Route path="/questiondashboard/question" element={<QuestionPage />} />
                            <Route path="/questiondashboard/question/:id" element={<QuestionPage />} />
                            <Route path="/questiondashboard/questionblock" element={<QuestionBlockPage />} />
                            <Route path="/questiondashboard/questionblock/:id" element={<QuestionBlockPage />} />
                            <Route path="/questiondashboard/questiontopic" element={<QuestionTopicPage />} />
                            <Route path="/questiondashboard/questiontopic/:id" element={<QuestionTopicPage />} />
                            <Route path="/questiondashboard/visa" element={<VisaPage />} />
                            <Route path="/questiondashboard/visa/:id" element={<VisaPage />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/jobs" element={<ProfileJobsDetails />} />
                        </Routes>
                    </Main>
                </Box>
            </Box>
        )
    }
}
