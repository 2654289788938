/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($item: UserInput!) {
    createUser(item: $item) {
      id
      firstName
      lastName
      preferredName
      title
      dateOfBirth
      address
      email
      sex
      maritalStatus
      phone
      created
      updated
      visaApplications
      jobInterestCategory
      jobInterestCategoryOther
      jobPreviousExperience
      jobEnglishFluency
      jobAreaOfStudy
      jobAreaOfStudyOther
      jobDrive
      jobFar
      jobVisaStatus
      jobVisaStatusOther
      jobDays
      jobTime
      jobHowDidYouFindOutAboutUs
      jobCurrentLocation
      sourceJobChangeDate
      sourceJobForMe
      status
      agency
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($item: UserInput!) {
    updateUser(item: $item) {
      id
      firstName
      lastName
      preferredName
      title
      dateOfBirth
      address
      email
      sex
      maritalStatus
      phone
      created
      updated
      visaApplications
      jobInterestCategory
      jobInterestCategoryOther
      jobPreviousExperience
      jobEnglishFluency
      jobAreaOfStudy
      jobAreaOfStudyOther
      jobDrive
      jobFar
      jobVisaStatus
      jobVisaStatusOther
      jobDays
      jobTime
      jobHowDidYouFindOutAboutUs
      jobCurrentLocation
      sourceJobChangeDate
      sourceJobForMe
      status
      agency
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($pk: ID!) {
    deleteUser(pk: $pk) {
      id
      firstName
      lastName
      preferredName
      title
      dateOfBirth
      address
      email
      sex
      maritalStatus
      phone
      created
      updated
      visaApplications
      jobInterestCategory
      jobInterestCategoryOther
      jobPreviousExperience
      jobEnglishFluency
      jobAreaOfStudy
      jobAreaOfStudyOther
      jobDrive
      jobFar
      jobVisaStatus
      jobVisaStatusOther
      jobDays
      jobTime
      jobHowDidYouFindOutAboutUs
      jobCurrentLocation
      sourceJobChangeDate
      sourceJobForMe
      status
      agency
      __typename
    }
  }
`;
export const updateUserLedgeRec = /* GraphQL */ `
  mutation UpdateUserLedgeRec($item: UserLedgeRecInput!) {
    updateUserLedgeRec(item: $item) {
      id
      firstName
      lastName
      preferredName
      title
      dateOfBirth
      address
      email
      sex
      maritalStatus
      phone
      created
      updated
      visaApplications
      jobInterestCategory
      jobInterestCategoryOther
      jobPreviousExperience
      jobEnglishFluency
      jobAreaOfStudy
      jobAreaOfStudyOther
      jobDrive
      jobFar
      jobVisaStatus
      jobVisaStatusOther
      jobDays
      jobTime
      jobHowDidYouFindOutAboutUs
      jobCurrentLocation
      sourceJobChangeDate
      sourceJobForMe
      status
      agency
      __typename
    }
  }
`;
export const updateUserAuth0 = /* GraphQL */ `
  mutation UpdateUserAuth0($item: UserAuth0Input!) {
    updateUserAuth0(item: $item) {
      id
      useMfa
      __typename
    }
  }
`;
export const resendUserAuth0EmailVerification = /* GraphQL */ `
  mutation ResendUserAuth0EmailVerification($pk: ID!) {
    resendUserAuth0EmailVerification(pk: $pk)
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument($item: DocumentInput!) {
    createDocument(item: $item) {
      id
      userId
      documentName
      documentCategory
      documentType
      created
      updated
      expires
      uploaded
      originalFileName
      contentType
      __typename
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument($item: DocumentInput!) {
    updateDocument(item: $item) {
      id
      userId
      documentName
      documentCategory
      documentType
      created
      updated
      expires
      uploaded
      originalFileName
      contentType
      __typename
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($pk: ID!) {
    deleteDocument(pk: $pk) {
      id
      userId
      documentName
      documentCategory
      documentType
      created
      updated
      expires
      uploaded
      originalFileName
      contentType
      __typename
    }
  }
`;
export const updateUserDocumentStatus = /* GraphQL */ `
  mutation UpdateUserDocumentStatus($pk: ID!) {
    updateUserDocumentStatus(pk: $pk) {
      id
      firstName
      lastName
      preferredName
      title
      dateOfBirth
      address
      email
      sex
      maritalStatus
      phone
      created
      updated
      visaApplications
      jobInterestCategory
      jobInterestCategoryOther
      jobPreviousExperience
      jobEnglishFluency
      jobAreaOfStudy
      jobAreaOfStudyOther
      jobDrive
      jobFar
      jobVisaStatus
      jobVisaStatusOther
      jobDays
      jobTime
      jobHowDidYouFindOutAboutUs
      jobCurrentLocation
      sourceJobChangeDate
      sourceJobForMe
      status
      agency
      __typename
    }
  }
`;
export const updateUserDocumentStatuses = /* GraphQL */ `
  mutation UpdateUserDocumentStatuses {
    updateUserDocumentStatuses
  }
`;
export const createVisa = /* GraphQL */ `
  mutation CreateVisa($item: VisaInput!) {
    createVisa(item: $item) {
      id
      name
      topics
      documentTypes
      published
      __typename
    }
  }
`;
export const modifyVisa = /* GraphQL */ `
  mutation ModifyVisa($item: VisaInput!) {
    modifyVisa(item: $item) {
      id
      name
      topics
      documentTypes
      published
      __typename
    }
  }
`;
export const deleteVisa = /* GraphQL */ `
  mutation DeleteVisa($pk: ID!) {
    deleteVisa(pk: $pk) {
      id
      name
      topics
      documentTypes
      published
      __typename
    }
  }
`;
export const addVisaDocumentType = /* GraphQL */ `
  mutation AddVisaDocumentType($item: VisaInput!) {
    addVisaDocumentType(item: $item) {
      id
      name
      topics
      documentTypes
      published
      __typename
    }
  }
`;
export const createVisaApplication = /* GraphQL */ `
  mutation CreateVisaApplication($item: CreateVisaApplicationInput!) {
    createVisaApplication(item: $item) {
      id
      userId
      name
      visaType
      created
      updated
      documents
      dueDate
      __typename
    }
  }
`;
export const modifyVisaApplication = /* GraphQL */ `
  mutation ModifyVisaApplication($item: VisaApplicationInput!) {
    modifyVisaApplication(item: $item) {
      id
      userId
      name
      visaType
      created
      updated
      documents
      dueDate
      __typename
    }
  }
`;
export const deleteVisaApplication = /* GraphQL */ `
  mutation DeleteVisaApplication($pk: ID!) {
    deleteVisaApplication(pk: $pk) {
      id
      userId
      name
      visaType
      created
      updated
      documents
      dueDate
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion($item: QuestionInput!) {
    createQuestion(item: $item) {
      id
      questionType
      questionText
      notes
      order
      choices
      questionHelperTexts
      __typename
    }
  }
`;
export const modifyQuestion = /* GraphQL */ `
  mutation ModifyQuestion($item: ModifyQuestionInput!) {
    modifyQuestion(item: $item) {
      id
      questionType
      questionText
      notes
      order
      choices
      questionHelperTexts
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion($pk: ID!) {
    deleteQuestion(pk: $pk) {
      id
      questionType
      questionText
      notes
      order
      choices
      questionHelperTexts
      __typename
    }
  }
`;
export const createQuestionTopic = /* GraphQL */ `
  mutation CreateQuestionTopic($item: QuestionTopicInput!) {
    createQuestionTopic(item: $item) {
      id
      category
      title
      subtext
      questionBlocks
      order
      tags
      __typename
    }
  }
`;
export const modifyQuestionTopic = /* GraphQL */ `
  mutation ModifyQuestionTopic($item: QuestionTopicInput!) {
    modifyQuestionTopic(item: $item) {
      id
      category
      title
      subtext
      questionBlocks
      order
      tags
      __typename
    }
  }
`;
export const deleteQuestionTopic = /* GraphQL */ `
  mutation DeleteQuestionTopic($pk: ID!) {
    deleteQuestionTopic(pk: $pk) {
      id
      category
      title
      subtext
      questionBlocks
      order
      tags
      __typename
    }
  }
`;
export const createQuestionBlock = /* GraphQL */ `
  mutation CreateQuestionBlock($item: QuestionBlockInput!) {
    createQuestionBlock(item: $item) {
      id
      title
      subtext
      questions
      order
      conditionalShowQuestionId
      conditionalShowAnswer
      __typename
    }
  }
`;
export const modifyQuestionBlock = /* GraphQL */ `
  mutation ModifyQuestionBlock($item: QuestionBlockInput!) {
    modifyQuestionBlock(item: $item) {
      id
      title
      subtext
      questions
      order
      conditionalShowQuestionId
      conditionalShowAnswer
      __typename
    }
  }
`;
export const deleteQuestionBlock = /* GraphQL */ `
  mutation DeleteQuestionBlock($pk: ID!) {
    deleteQuestionBlock(pk: $pk) {
      id
      title
      subtext
      questions
      order
      conditionalShowQuestionId
      conditionalShowAnswer
      __typename
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer($item: AnswerInput!) {
    createAnswer(item: $item) {
      id
      userId
      questionId
      answer
      __typename
    }
  }
`;
export const modifyAnswer = /* GraphQL */ `
  mutation ModifyAnswer($item: AnswerInput!) {
    modifyAnswer(item: $item) {
      id
      userId
      questionId
      answer
      __typename
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer($pk: ID!) {
    deleteAnswer(pk: $pk) {
      id
      userId
      questionId
      answer
      __typename
    }
  }
`;
