import { useState } from 'react'
import { Box, Button, Typography, TextField, Select, MenuItem, FormControl } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RedDivider from '../common/redDivider'
import { Question } from '../../graphql/API'
import { callApi } from '../../common/apiUtils'
import { createQuestion } from '../../graphql/mutations'
import { ContextDataInterface } from '../../common/userContext'
import { CommonStyles } from '../../assets/theme'
import { questionTypes } from '../questions/questionCommon'

const { v4: uuid } = require('uuid')
type NewQuestionModalProps = {
    open: boolean
    questions: Array<Question>
    contextData: ContextDataInterface
    onClose: (e: any) => void
}

const NewQuestionModal = (props: NewQuestionModalProps) => {
    const { onClose, open } = props
    const [questionText, setQuestionText] = useState<string>('')
    const [questionType, setQuestionType] = useState<string>('')
    const [questionNotes, setQuestionNotes] = useState<string>('')
    const [questionOrder, setQuestionOrder] = useState<number>(99)

    const [choiceValues, setChoiceValues] = useState<Array<string>>([])
    const [choiceBoxes, setChoiceBoxes] = useState<Array<JSX.Element>>([
        <TextField
            id={'choice0'}
            value={choiceValues[0]}
            onChange={(e) => (choiceValues[0] = e.target.value)}
            size="small"
            fullWidth
            sx={{ marginBottom: 1 }}
        />,
    ])
    const [choicesNum, setChoicesNum] = useState<number>(0)

    const clearAll = () => {
        setQuestionText('')
        setQuestionType('')
        setChoiceValues([])
        setQuestionOrder(99)
        setQuestionNotes('')
    }

    const addChoiceBox = () => {
        var n = choicesNum

        setChoicesNum(choicesNum + 1)
        n++

        var f = choiceBoxes
        var r = (
            <TextField
                id={'choice' + n}
                value={choiceValues[n]}
                onChange={(e) => (choiceValues[n] = e.target.value)}
                size="small"
                fullWidth
                sx={{ marginBottom: 1 }}
            />
        )
        f.push(r)
        setChoiceBoxes(f)
    }

    const removeChoiceBox = () => {
        if (choicesNum > 0) {
            var cb = choiceBoxes
            cb.pop()
            setChoiceBoxes(cb)
            setChoicesNum(choicesNum - 1)
        }
    }

    const handleClose = () => {
        onClose({ completed: false })
    }

    const handleSubmit = async () => {
        const questionId = uuid()

        let helperTexts = Array<string>()
        if (questionType === 'boolean') helperTexts = ['', '']
        else if (questionType === 'boolean') helperTexts = Array<string>(choiceValues.length)

        const item = {
            id: questionId,
            questionText: questionText,
            questionType: questionType,
            order: questionOrder ? questionOrder : 99,
            notes: questionNotes ? questionNotes : '',
            choices: choiceValues,
            questionHelperTexts: helperTexts,
        }

        const addQuestion = await callApi<Array<Question>>(props.contextData.user, 'createQuestion', {
            query: createQuestion,
            variables: { item: item },
        })

        if (addQuestion.Result) {
            onClose({ completed: true })
            clearAll()
            return
        }

        onClose({ completed: false })
    }

    return (
        <Dialog onClose={handleClose} open={open} sx={{ borderRadius: 1 }}>
            <Box sx={{ width: '475px', padding: 1 }}>
                <DialogTitle variant="h2" sx={{ marginBottom: 0 }}>
                    Create a new Visa Question
                    <RedDivider />
                </DialogTitle>
                <DialogContent>
                    <FormControl sx={{ marginBottom: 2 }} fullWidth>
                        <Typography variant="h4">Question Text:</Typography>
                        <TextField
                            id="text"
                            name="text"
                            value={questionText}
                            onChange={(e) => setQuestionText(e.target.value)}
                            size="small"
                            rows={3}
                            multiline={true}
                        />
                    </FormControl>

                    <FormControl sx={{ marginBottom: 2 }} fullWidth>
                        <Typography variant="h4">Question Type:</Typography>

                        <Select
                            id="type"
                            name="type"
                            value={questionType}
                            onChange={(e) => setQuestionType(e.target.value)}
                            size="small"
                        >
                            <MenuItem key={''} value={''}>
                                - please select a type -
                            </MenuItem>
                            {questionTypes.map((qt) => (
                                <MenuItem key={qt.key} value={qt.value}>
                                    {qt.text}
                                </MenuItem>
                            ))}
                            {/* <MenuItem key={'Multiselect'} value="multiselect">
                                Multiselect
                            </MenuItem> */}
                        </Select>

                        {choicesNum + 1 > 0 && questionType === 'choice' && (
                            <Box marginTop={2}>
                                <Typography variant="h4">Question Choices</Typography>

                                <Box>
                                    {choiceBoxes.map((b) => {
                                        return b
                                    })}

                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Button
                                            sx={{ textAlign: 'right' }}
                                            variant="outlined"
                                            onClick={removeChoiceBox}
                                        >
                                            -
                                        </Button>
                                        <Typography
                                            variant="body1"
                                            sx={{ alignSelf: 'center', marginLeft: 2, marginRight: 2 }}
                                        >
                                            {choicesNum + 1}
                                        </Typography>
                                        <Button sx={{ textAlign: 'right' }} variant="outlined" onClick={addChoiceBox}>
                                            +
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </FormControl>
                    <FormControl fullWidth>
                        <Typography variant="h4">Notes</Typography>
                        <TextField
                            id="notes"
                            name="notes"
                            value={questionNotes}
                            onChange={(e) => setQuestionNotes(e.target.value)}
                            size="small"
                            fullWidth
                            rows={2}
                            sx={{ marginBottom: 1 }}
                        />
                    </FormControl>
                    <FormControl>
                        <Typography variant="h4">Question Order Number</Typography>
                        <TextField
                            id="order"
                            name="order"
                            type="number"
                            value={questionOrder}
                            onChange={(e) => setQuestionOrder(parseInt(e.target.value))}
                            size="small"
                            fullWidth
                            sx={{ marginBottom: 1 }}
                        />
                    </FormControl>

                    <Box>
                        <Box sx={CommonStyles.buttonFlex}>
                            <Button
                                variant="outlined"
                                sx={{
                                    alignSelf: 'end',
                                    marginRight: '10px',
                                }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    alignSelf: 'end',
                                }}
                                onClick={handleSubmit}
                            >
                                Create
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default NewQuestionModal
