import { useState, useContext } from 'react'
import { UserContext } from '../../common/userContext'
import { Box, Button, Typography, FormControl } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import DialogTitle from '@mui/material/DialogTitle'

import { callApi } from '../../common/apiUtils'
import { QuestionTopic, Visa, VisaApplication } from '../../graphql/API'
import { deleteVisa, deleteVisaApplication, modifyQuestionTopic } from '../../graphql/mutations'
import { getVisaApplications, getVisaApplicationsByUser, getVisas } from '../../graphql/queries'

type DeleteVisaModalProps = {
    open: boolean
    target: string
    targetName: string
    onClose: (e: any) => void
}

const DeleteVisaTypeModal = (props: DeleteVisaModalProps) => {
    const contextData = useContext(UserContext)

    const { onClose, open } = props
    const [confirm, setConfirm] = useState<boolean>(false)

    const clearAll = () => {
        setConfirm(false)
    }

    const handleChange = () => {
        setConfirm(!confirm)
    }

    const handleClose = () => {
        onClose({ completed: false })
        clearAll()
    }

    const handleDelete = async () => {
        // Remove any applications that are of the visa type to be deleted
        let filteredApplications = Array<VisaApplication>()
        const visaApplications = await callApi<Array<VisaApplication>>(contextData.user, 'getVisaApplications', {
            query: getVisaApplications,
        })
        if (visaApplications.Result) {
            filteredApplications = visaApplications.Result.filter((va) => va.visaType === props.target)
        }
        if (filteredApplications.length > 0) {
            for (const fa of filteredApplications) {
                await callApi<VisaApplication>(contextData.user, 'deleteVisaApplication', {
                    query: deleteVisaApplication,
                    variables: { pk: fa.id },
                })
            }
        }
        // ===========================================================================================================

        // Check question topics and remove tags belonging to the deleted visa type
        let modifiedTopics = [...contextData.questionTopics]
        if (modifiedTopics.length > 0) {
            for (const qt of modifiedTopics) {
                if (qt.tags?.includes(props.targetName)) {
                    qt.tags = qt.tags.filter((tag) => tag !== props.targetName)

                    await callApi<Array<QuestionTopic>>(contextData.user, 'modifyQuestionTopic', {
                        query: modifyQuestionTopic,
                        variables: { item: qt },
                    })
                }
            }
        }

        contextData.setQuestionTopics(modifiedTopics)
        // ===========================================================================================================
        const deleteVisaResult = await callApi<Array<Visa>>(contextData.user, 'deleteVisa', {
            query: deleteVisa,
            variables: { pk: props.target },
        })

        if (deleteVisaResult.Result) {
            const updatedApps = await callApi<Array<VisaApplication>>(contextData.user, 'getVisaApplicationsByUser', {
                query: getVisaApplicationsByUser,
                variables: { pk: contextData.ledgeUser!.id },
            })
            contextData.setVisaApplications(updatedApps.Result!)

            const updatedVisaTypes = await callApi<Array<Visa>>(contextData.user, 'getVisas', {
                query: getVisas,
            })
            contextData.setVisaTypes(updatedVisaTypes.Result!)

            onClose({ completed: true, result: updatedVisaTypes.Result })
            clearAll()
            return
        }

        onClose({ completed: false })
        clearAll()
    }

    return (
        <Dialog onClose={handleClose} open={open} sx={{ borderRadius: 1 }}>
            <Box sx={{ padding: 1 }}>
                <DialogTitle variant="h2" color={'primary.light'} sx={{ marginBottom: 0 }}>
                    STOP! Are you sure you want to delete this Visa type?
                </DialogTitle>

                <DialogContent>
                    <Typography variant="body1" color={'secondary.light'}>
                        Deleting this Visa type will <b>impact every user</b> who has selected this Visa type to create
                        an application.
                    </Typography>
                    <Typography variant="body1" color={'secondary.light'}>
                        Do not proceed unless you are <b>absolutely sure you want to delete this Visa type.</b>
                    </Typography>

                    <FormControl
                        sx={{ marginBottom: 4, marginTop: 2, display: 'flex', flexDirection: 'row' }}
                        fullWidth
                    >
                        <Checkbox id="confirm" name="confirm" checked={confirm} onChange={handleChange} />
                        <Typography variant="body2" margin={0} alignSelf={'center'} color={'secondary.light'}>
                            I understand that by deleting this Visa type, all users who have an application with this
                            visa type will have their applications impacted
                        </Typography>
                    </FormControl>

                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="outlined"
                                sx={{
                                    alignSelf: 'end',
                                    marginRight: '10px',
                                }}
                                onClick={handleClose}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    alignSelf: 'end',
                                }}
                                disabled={!confirm}
                                onClick={handleDelete}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default DeleteVisaTypeModal
