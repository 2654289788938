import { useState, useContext } from 'react'
import { UserContext } from '../../common/userContext'
import { Box, Button, Typography, FormControl } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import DialogTitle from '@mui/material/DialogTitle'

import { callApi } from '../../common/apiUtils'
import { Answer, Document, User, VisaApplication } from '../../graphql/API'
import { deleteAnswer, deleteDocument, deleteUser, deleteVisaApplication } from '../../graphql/mutations'
import { getDocumentsForUser, getUserAnswers, getVisaApplicationsByUser } from '../../graphql/queries'

type DeleteUserModalProps = {
    open: boolean
    user: User | undefined
    onClose: (e: any) => void
}

const DeleteUserModal = ({ open, user, onClose }: DeleteUserModalProps) => {
    const contextData = useContext(UserContext)

    const [confirm, setConfirm] = useState<boolean>(false)

    const clearAll = () => {
        setConfirm(false)
    }

    const handleChange = () => {
        setConfirm(!confirm)
    }

    const handleClose = () => {
        onClose({ completed: false })
        clearAll()
    }

    const handleDelete = async () => {
        // Remove any applications that are of the visa type to be deleted
        const userDocuments = await callApi<Array<Document>>(contextData.user, 'getDocumentsForUser', {
            query: getDocumentsForUser,
            variables: { pk: user?.id },
        })

        //Delete the documents belonging to the user
        if (userDocuments.Result) {
            let docsToDelete = userDocuments.Result
            for (const dd of docsToDelete) {
                await callApi<Document>(contextData.user, 'getDocumentsForUser', {
                    query: deleteDocument,
                    variables: { pk: dd.id },
                })
            }
        }

        //Delete answers belonging to user
        const userAnswers = await callApi<Array<Answer>>(contextData.user, 'getUserAnswers', {
            query: getUserAnswers,
            variables: { pk: user?.id },
        })

        if (userAnswers.Result) {
            let answersToDelete = userAnswers.Result
            for (const aa of answersToDelete) {
                await callApi<Answer>(contextData.user, 'deleteAnswer', {
                    query: deleteAnswer,
                    variables: { pk: aa.id },
                })
            }
        }

        // Delete user visa applications
        const userVisas = await callApi<Array<VisaApplication>>(contextData.user, 'getVisaApplicationsByUser', {
            query: getVisaApplicationsByUser,
            variables: { pk: user?.id },
        })

        if (userVisas.Result) {
            let visasToDelete = userVisas.Result
            for (const vv of visasToDelete) {
                await callApi<Answer>(contextData.user, 'deleteVisaApplication', {
                    query: deleteVisaApplication,
                    variables: { pk: vv.id },
                })
            }
        }

        // // Delete the user
        await callApi<User>(contextData.user, 'deleteUser', {
            query: deleteUser,
            variables: { pk: user?.id },
        })

        onClose({ completed: true })
        clearAll()
    }
    if (user)
        return (
            <Dialog onClose={handleClose} open={open} sx={{ borderRadius: 1 }}>
                <Box sx={{ padding: 1 }}>
                    <DialogTitle variant="h2" color={'primary.light'} sx={{ marginBottom: 0 }}>
                        STOP! Are you sure you want to delete this user?
                    </DialogTitle>

                    <DialogContent>
                        <Typography variant="body1" color={'secondary.light'}>
                            Deleting this Visa type will <b>permanently</b> delete the user's account, data and
                            documents from Globoz <b>FOREVER.</b>
                        </Typography>
                        <Typography variant="body1" color={'secondary.light'}>
                            Do not proceed unless you are{' '}
                            <b>
                                absolutely sure you want to delete {user.firstName} {user.lastName}'s account!{' '}
                            </b>
                        </Typography>

                        <FormControl
                            sx={{ marginBottom: 4, marginTop: 2, display: 'flex', flexDirection: 'row' }}
                            fullWidth
                        >
                            <Checkbox id="confirm" name="confirm" checked={confirm} onChange={handleChange} />
                            <Typography variant="body2" margin={0} alignSelf={'center'} color={'secondary.light'}>
                                I understand that by deleting this account, all of {user.firstName}'s data will be
                                deleted permanently.
                            </Typography>
                        </FormControl>

                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        alignSelf: 'end',
                                        marginRight: '10px',
                                    }}
                                    onClick={handleClose}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        alignSelf: 'end',
                                    }}
                                    disabled={!confirm}
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        )
    return <></>
}

export default DeleteUserModal
