import { Storage } from 'aws-amplify'
import { Promise } from 'bluebird'
import { Document } from '../graphql/API'
import { deleteDocument } from '../graphql/mutations'
import { ContextDataInterface } from './userContext'
import { ErrorSessionLapsed, getErrorMessage } from './errorUtils'
import { callApi } from './apiUtils'
import JsZip from 'jszip'
import FileSaver from 'file-saver'

type StorageAccessLevel = 'public' | 'protected' | 'private'

type StorageDetails = {
    level: StorageAccessLevel
    folder: string
}

function getUserFolder(userId: string): string {
    // sub with special characters mapped, see auth0 rule, in particular '|' is an invalid principal tag value in aws
    return userId.replace('|', '-')
}

function getStorageDetails(userId: string): StorageDetails {
    if (!userId) throw new Error('Missing user for storage lookup!') // TODO REVISIT
    const sharedFolder = getUserFolder(userId)
    return {
        level: 'public',
        folder: `${sharedFolder}/`,
    }
}

export function removeExtension(filename: string): string {
    return filename.substring(0, filename.lastIndexOf('.')) || filename
}

export function getExtension(filename: string): string {
    return filename.slice(filename.lastIndexOf('.')) || ''
}

export function getFileName(document: Document) {
    const origExtension = getExtension(document.originalFileName!)
    const currentExtension = getExtension(document.documentName)
    if (currentExtension === origExtension) return document.documentName
    return `${document.documentName}${origExtension}`
}

export async function uploadDocument(
    context: ContextDataInterface,
    key: string,
    file: File
): Promise<Error | undefined> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed || !context.ledgeUser) return ErrorSessionLapsed
        const storageDetails = getStorageDetails(context.ledgeUser.id)
        const result = await Storage.put(`${storageDetails.folder}${key}`, file, {
            level: storageDetails.level,
            contentType: file.type, // contentType is optional
        })
        console.log('Successfully uploaded document: %o', result)
        return undefined
    } catch (error) {
        const errorMessage = getErrorMessage('uploading the document', error)
        console.log(errorMessage)
        return new Error(errorMessage)
    }
}

export type DownLoad = {
    fileName: string
    contentType: string
    blob: any
}

async function retrieveDocument(document: Document, fileNameGetter: (Document) => string): Promise<DownLoad> {
    try {
        const fileName = fileNameGetter(document)
        const downloadFileName = fileName.replace('|', '-')
        const storageDetails = getStorageDetails(document.userId)
        const result = await Storage.get(`${storageDetails.folder}${document.id}`, {
            level: storageDetails.level,
            download: true,
        })
        return {
            fileName: downloadFileName,
            contentType: document.contentType!,
            blob: result?.Body,
        }
    } catch (error) {
        console.log(error)
        console.log(document.documentName)
        return {
            fileName: document.documentName,
            contentType: document.contentType!,
            blob: undefined,
        }
    }
}

async function downloadDocumentsForZip(documents: Document[]) {
    const downloads = Promise.map<Document, DownLoad>(
        documents,
        async (document: Document) => retrieveDocument(document, getFileName),
        { concurrency: 20 }
    )
    return downloads
}

function exportZip(downloads: any, filename: string) {
    const zip = JsZip()
    downloads.forEach((download: any, i: any) => {
        if (!download.blob) return
        const path = download.fileName.split('/')
        var downloadZip = zip
        for (var j = 0; j < path.length; ++j) {
            const nextPath = path[j]
            if (!nextPath) continue
            if (j < path.length - 1) downloadZip = downloadZip.folder(nextPath) ?? zip
            else downloadZip = downloadZip.file(nextPath, download.blob)
        }
    })
    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        const zipFileName = `${filename}.zip`
        return FileSaver.saveAs(zipFile, zipFileName)
    })
}

export async function downloadAndZip(
    context: ContextDataInterface,
    documents: Document[],
    fileName: string
): Promise<Error | undefined> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return ErrorSessionLapsed
        await downloadDocumentsForZip(documents).then((downloads) => exportZip(downloads, fileName))

        return undefined
    } catch (error) {
        const errorMessage = getErrorMessage('calling the api', error)
        console.log(errorMessage)
        return new Error(errorMessage)
    }
}

export async function exportDocsAndQuestions(
    context: ContextDataInterface,
    documents: Document[]
): Promise<Error | undefined> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return ErrorSessionLapsed
        await downloadDocumentsForZip(documents).then((downloads) => exportZip(downloads, "GlobozDocuments"))

        return undefined
    } catch (error) {
        const errorMessage = getErrorMessage('calling the api', error)
        console.log(errorMessage)
        return new Error(errorMessage)
    }
}

async function retrieveSingle(
    context: ContextDataInterface,
    document: Document
): Promise<[Error | undefined, DownLoad | undefined]> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return [ErrorSessionLapsed, undefined]

        const download = await retrieveDocument(document, getFileName)
        if (!download.blob) return [new Error('There was an error downloading the file!'), undefined]
        //FileSaver.saveAs(fileData.blob, fileData.fileName);
        return [undefined, download]
    } catch (error) {
        const errorMessage = getErrorMessage('calling the api', error)
        console.log(errorMessage)
        return [new Error(errorMessage), undefined]
    }
}

export async function deleteSingle(context: ContextDataInterface, document: Document): Promise<Error | undefined> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return ErrorSessionLapsed
        const storageDetails = getStorageDetails(document.userId)
        const removeResult = await Storage.remove(`${storageDetails.folder}${document.id}`, {
            level: storageDetails.level,
        })
        if (!removeResult) {
            const errorMsg = 'Error deleting document: ' + document.id
            console.log(errorMsg)
            return new Error(errorMsg)
        }
        const deletedDocument = await callApi<Document>(context.user, 'deleteDocument', {
            query: deleteDocument,
            variables: { pk: document.id },
        })
        if (!deletedDocument.Result) {
            const errorMsg = getErrorMessage('Error deleting document record: ', deletedDocument.Error)
            console.log(errorMsg)
            return new Error(errorMsg)
        }
        return undefined
    } catch (error) {
        const errorMsg = getErrorMessage('Error deleting document record: ', error)
        console.log(document.id)
        return new Error(errorMsg)
    }
}

export async function getPresignedUrl(
    context: ContextDataInterface,
    document: Document
): Promise<[Error | undefined, string, string | undefined]> {
    try {
        const fileName = getFileName(document)
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return [ErrorSessionLapsed, fileName, undefined]
        const storageDetails = getStorageDetails(document.userId)
        const signedURL = await Storage.get(`${storageDetails.folder}${document.id}`, {
            level: storageDetails.level,
            contentType: `${document.contentType}`,
            expires: 180,
            //contentDisposition: "inline"
        })
        return [undefined, fileName, signedURL]
    } catch (error) {
        const errorMessage = `Unable to view document! ${error}`
        return [new Error(errorMessage), '', undefined]
    }
}

export async function userDocumentExists(
    context: ContextDataInterface,
    document: Document
): Promise<[Error | undefined, boolean]> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return [ErrorSessionLapsed, false]
        const storageDetails = getStorageDetails(document.userId)
        const existing = await Storage.list(`${storageDetails.folder}${document.id}`, {
            level: storageDetails.level,
        })
        const exists = existing.length === 1
        return [undefined, exists]
    } catch (error) {
        const errorMessage = `Unable to view document! ${error}`
        return [new Error(errorMessage), false]
    }
}

export async function viewDocument(context: ContextDataInterface, document: Document): Promise<Error | undefined> {
    try {
        const [error, filename, signedUrl] = await getPresignedUrl(context, document)
        if (error) return error
        window.open(signedUrl, filename)
        return undefined
    } catch (error) {
        return new Error(getErrorMessage('openDocumentInNewTab', error))
    }
}

export async function downloadDocument(context: ContextDataInterface, document: Document): Promise<Error | undefined> {
    try {
        const [error, download] = await retrieveSingle(context, document)
        if (error) return error
        const fileName = getFileName(document)
        console.log(document)
        console.log(download)
        console.log(fileName)
        FileSaver.saveAs(download?.blob, fileName)
        return undefined
    } catch (error) {
        return new Error(getErrorMessage('openDocumentInNewTab', error))
    }
}
