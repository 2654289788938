// import animation from "../assets/animation.mp4";
import { PageHeader } from './common/pageHeader'
import { Box, Link, Typography } from '@mui/material'

export default function About() {
    return (
        <Box>
            {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                <video
                    controls
                    autoPlay
                    src={animation}
                    style={{0
                        width: "60%",
                    }}
                />
            </Box> */}
            <br />
            <PageHeader title="The Globoz Portal." />
            <Typography variant="h3">Designed to make your life easier.</Typography>
            <Typography variant="body1">
                The Globoz Portal and Portal Guide are designed to simplify the process of adjusting to life in
                Australia for foreigners. They provide a centralized platform for collecting and storing documentation
                and submitting credentials for visa and job applications.
            </Typography>
            <Typography variant="body1">
                The Portal Guide offers additional resources such as visa documentation requirements, relocation tips,
                employment resources, and visa-specific information to assist with the process.
            </Typography>
            <Typography variant="body1">
                The goal is to provide a comprehensive and user-friendly solution that simplifies the process and offers
                support every step of the way for those looking to work and live in Australia.
            </Typography>

            <Typography
                sx={{
                    color: "text.secondary",
                    fontSize: "smaller",
                }}
            >
                Please see our{" "}
                <Link
                    href="#"
                    onClick={() => window.open("https://help.ledgevisa.com.au/lmf/LedgeVisa-Portal-Guide.46073537.html", "KnowledgeCenter", 'noopener,noreferrer')}
                >
                    Globoz Portal Guide
                </Link>{" "}
                section of the Portal Guide for a comprehensive guide on how
                to use this Portal.
            </Typography>
            <br />
        </Box>
    )
}
