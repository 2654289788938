import { useState, useContext, useEffect } from 'react'
import { Box, Typography, Backdrop, CircularProgress } from '@mui/material'

import { UserContext } from '../../../common/userContext'

import { Question, QuestionBlock, QuestionTopic, Document, Answer, AnswerInput } from '../../../graphql/API'
import { QuestionTopicSection } from '../../questions/questionTopic'
import { CommonStyles } from '../../../assets/theme'
import { DataGridPro, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { deleteSingle, downloadDocument, viewDocument } from '../../../common/documentUtils'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DownloadIcon from '@mui/icons-material/Download'
import { EditProps, EditPropsClosed, EditResult } from '../../../common/types'
import { LoadingButton } from '@mui/lab'
import { createAnswer, modifyAnswer } from '../../../graphql/mutations'
import { callApi } from '../../../common/apiUtils'
import { getUserAnswers } from '../../../graphql/queries'
import { ExistingDocumentModal } from '../../modals/ExistingDocumentModal'

type ResponseProps = {
    category: string
}

export function ResponseTab(props: ResponseProps) {
    const contextData = useContext(UserContext)

    const [questionTopics, setQuestionTopics] = useState<Array<QuestionTopic>>()
    const [answerInput, setAnswerInput] = useState<Array<AnswerInput>>()
    const apiRef = useGridApiRef()
    const [documents, setDocuments] = useState<Array<Document>>()
    const [processing, setProcessing] = useState<boolean>(false)
    const [editDialogProps, setEditDialogProps] = useState<EditProps>(EditPropsClosed)

    const { v4: uuid } = require('uuid')

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'documentName',
            headerName: 'Document Name',
            flex: 1,
        },
        {
            field: 'documentType',
            headerName: 'Document Type',
            flex: 1,
        },
        {
            field: 'expires',
            headerName: 'Expiration',
            width: 150,
            type: 'date',
            renderCell: (params: GridRenderCellParams<string>) => <>{params.value ? params.value : 'n/a'}</>,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 180,
            resizable: false,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box display={'flex'} width={'100%'} justifyContent={'flex-start'}>
                    <Box sx={{ cursor: 'pointer', marginRight: 2 }} onClick={() => editRow(params.row)}>
                        <EditIcon />
                    </Box>
                    <Box
                        sx={{ cursor: 'pointer', marginRight: 2 }}
                        onClick={() => doDocumentAction(params.row.id, viewDocument)}
                    >
                        <VisibilityIcon />
                    </Box>
                    <Box
                        sx={{ cursor: 'pointer', marginRight: 2 }}
                        onClick={() => doDocumentAction(params.row.id, downloadDocument)}
                    >
                        <DownloadIcon />
                    </Box>
                    <Box sx={{ cursor: 'pointer' }} onClick={() => doDocumentAction(params.row.id, deleteSingle)}>
                        <DeleteIcon />
                    </Box>
                </Box>
            ),
        },
    ]

    const doDocumentAction = async (
        id: string,
        documentAction: (ContextDataInterface, Document) => Promise<Error | undefined>
    ) => {
        const row = apiRef.current.getRow(id) as Document
        if (!row) {
            console.log(`Edit row for ${id} was null!`)
            return
        }

        try {
            setProcessing(true)
            const error = await documentAction(contextData, row)
            if (error) {
                console.log(error)
                return
            }
        } catch (error) {
            alert(`Unable to view document! ${error}`)
        } finally {
            if (documentAction === deleteSingle) {
                contextData.setDocuments(contextData.documents.filter((docs) => docs.id !== row.id))
                setDocuments(documents!.filter((docs) => docs.id !== row.id))
            }
            setProcessing(false)
        }
    }

    const updateAnswer = (id: string, questionId: string, answer: string) => {
        var vv = answerInput?.filter((aa) => aa.id !== id)
        vv?.push({ id: id, questionId: questionId, answer: answer })
        setAnswerInput(vv)

        return
    }

    const handleSave = async () => {
        setProcessing(true)

        const oldAnswers = await callApi<Array<Answer>>(contextData.user, 'getUserAnswers', {
            query: getUserAnswers,
            variables: { pk: contextData.ledgeUser?.id },
        })

        for (const aa of answerInput!) {
            await processAnswer(aa, oldAnswers.Result!)
        }
        const newAnswers = await callApi<Array<Answer>>(contextData.user, 'getUserAnswers', {
            query: getUserAnswers,
            variables: { pk: contextData.ledgeUser?.id },
        })

        if (newAnswers.Result) {
            contextData.setAnswers(newAnswers.Result)
        }
        setProcessing(false)
    }

    const processAnswer = async (answerObject: AnswerInput, userAnswers: Array<Answer>) => {
        let result
        const ans = userAnswers.find((a) => a.questionId === answerObject.questionId)
        if (ans) {
            if (ans.answer !== answerObject.answer) {
                // console.log(`answer found and input different - modifying ${answerObject.id}`)
                const current = await callApi<Answer>(contextData.user, 'modifyAnswer', {
                    query: modifyAnswer,
                    variables: {
                        item: {
                            id: answerObject.id,
                            userId: contextData.user.sub,
                            questionId: ans.questionId,
                            answer: answerObject.answer,
                        },
                    },
                })
                return current.Result
            } else {
                // console.log(`answer found same - ignoring ${answerObject.id}`)
            }
        } else {
            if (!answerObject.answer || answerObject.answer === '') {
                // console.log(`Question ${answerObject.id} empty - ignoring`)
            } else {
                // console.log(`creating new answer for ${answerObject.id}`)
                const current = await callApi<Answer>(contextData.user, 'createAnswer', {
                    query: createAnswer,
                    variables: {
                        item: {
                            id: uuid(),
                            userId: contextData.user.sub,
                            questionId: answerObject.id,
                            answer: answerObject.answer,
                        },
                    },
                })
                return current.Result!
            }
        }
        return result
    }

    const editRow = (doc: Document) => {
        let editProps: EditProps = {
            open: true,
            id: doc.id,
            data: doc,
            callback: handleEditResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        }
        setEditDialogProps(editProps)
    }

    const handleEditResult = (editResult: EditResult, document: any) => {
        setEditDialogProps(EditPropsClosed)
        switch (editResult) {
            case EditResult.Updated:
                if (document) {
                    contextData.setDocuments([...contextData.documents.filter((dd) => dd.id !== document.id), document])
                    setDocuments([...contextData.documents.filter((dd) => dd.id !== document.id), document])
                }
                break
            default:
                break
        }
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                setDocuments(contextData.documents.filter((d) => d.documentCategory === props.category))
                setQuestionTopics(contextData.questionTopics.filter((t) => t.category === props.category.toLowerCase()))
                // Get all topics
                const topics = contextData.questionTopics.filter((t) => t.category === props.category.toLowerCase())

                // get the blocks
                var blocks: Array<QuestionBlock> = []
                var questionsToAdd: Array<Question> = []
                topics.forEach((t) => {
                    t.questionBlocks?.forEach((tb) => {
                        const tbFound = contextData.questionBlocks.find((bb) => bb.id === tb)
                        if (tbFound) {
                            blocks.push(tbFound)
                            tbFound.questions?.forEach((qq) => {
                                const quest = contextData.questions.find((qf) => qf.id === qq)
                                if (quest) {
                                    questionsToAdd.push(quest)
                                }
                            })
                        }
                    })
                })

                const answers: Array<{ id; questionId; answer }> = []
                const userAnswers = await callApi<Array<Answer>>(contextData.user, 'getUserAnswers', {
                    query: getUserAnswers,
                    variables: { pk: contextData.user.sub },
                })
                questionsToAdd.forEach((qta) => {
                    var answerToAdd = userAnswers.Result!.find((aa) => aa.questionId === qta.id)
                    if (answerToAdd && answerToAdd.questionId !== answerToAdd.id) {
                        answers.push({
                            id: answerToAdd.id,
                            questionId: answerToAdd.questionId,
                            answer: answerToAdd.answer,
                        })
                    } else {
                        answers.push({
                            id: qta.id,
                            questionId: qta.id,
                            answer: '',
                        })
                    }
                })
                setQuestionTopics(contextData.questionTopics.filter((t) => t.category === props.category.toLowerCase()))
                setAnswerInput(answers)
            } catch (error) {
                console.log(error)
            }
        }
        initialise()
    }, [props])

    return (
        <Box>
            <Box sx={[CommonStyles.spacingStyle]}>
                <Typography variant="h2">{props.category} documents</Typography>
                {editDialogProps.open && (<ExistingDocumentModal {...editDialogProps} />)}

                {documents && (
                    <DataGridPro
                        sx={{ height: 214 }}
                        pagination={false}
                        hideFooter={true}
                        disableColumnReorder={true}
                        rows={documents}
                        columns={columns}
                        apiRef={apiRef}
                        loading={processing}
                        disableSelectionOnClick
                        editMode="row"
                        components={{
                            NoRowsOverlay: () => (
                                <Box
                                    sx={{
                                        color: 'text.disabled',
                                        mt: 1,
                                        ml: 1,
                                        mr: 1,
                                    }}
                                >
                                    You currently do not have any documents of this type uploaded.
                                </Box>
                            ),
                        }}
                    />
                )}
            </Box>
            {questionTopics && questionTopics.length > 0 && (
                <Box sx={[CommonStyles.spacingStyle, { marginTop: 2 }]}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography mb={0} variant="h2">
                            Questions
                        </Typography>
                        <LoadingButton
                            variant="contained"
                            loading={processing}
                            loadingIndicator="SAVING"
                            sx={{
                                alignSelf: 'end',
                            }}
                            onClick={handleSave}
                        >
                            Save Responses
                        </LoadingButton>
                    </Box>

                    {questionTopics
                        .sort((a, b) => (a.order! ? a.order : 1) - (b.order! ? b.order! : 2))
                        .map((qt, i) => (
                            <Box key={i}>
                                <QuestionTopicSection
                                    answerInput={answerInput}
                                    questionTopic={qt}
                                    updateAnswers={updateAnswer}
                                />
                            </Box>
                        ))}
                </Box>
            )}

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={processing}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}
