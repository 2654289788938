import { Box } from '@mui/material'

const FileIcon = () => {
    return (
        <Box className="fileIcon" textAlign={'center'}>
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 0.297969V14.702C0 14.8666 0.134188 15 0.30002 15H17.7C17.8658 15 18 14.8666 18 14.702V2.16595C18 2.0014 17.8658 1.86798 17.7 1.86798H7.94759L6.29484 0.0954432C6.26622 0.0647624 6.23249 0.0417746 6.19625 0.0258431C6.19183 0.0239002 6.18706 0.0231486 6.18228 0.0223946C6.17838 0.0217794 6.17447 0.0211627 6.17073 0.0198961C6.14112 0.00989351 6.11116 0.00169134 6.08014 0.00110938C6.07923 0.00109149 6.07837 0.000822793 6.07752 0.000554353C6.07663 0.000277042 6.07575 0 6.07481 0H0.30002C0.134188 0 0 0.133417 0 0.297969ZM0.600039 14.4041V4.75805H17.4V14.4041H0.600039ZM5.94377 0.595938L7.59659 2.36847C7.65313 2.42929 7.73312 2.46392 7.81662 2.46392H17.4V4.16211H0.600039V0.595938H5.94377Z"
                    fill="black"
                />
                <path
                    d="M0 0.297969V14.702C0 14.8666 0.134188 15 0.30002 15H17.7C17.8658 15 18 14.8666 18 14.702V2.16595C18 2.0014 17.8658 1.86798 17.7 1.86798H7.94759L6.29484 0.0954432C6.26622 0.0647624 6.23249 0.0417746 6.19625 0.0258431C6.19183 0.0239002 6.18706 0.0231486 6.18228 0.0223946C6.17838 0.0217794 6.17447 0.0211627 6.17073 0.0198961C6.14112 0.00989351 6.11116 0.00169134 6.08014 0.00110938C6.07923 0.00109149 6.07837 0.000822793 6.07752 0.000554353C6.07663 0.000277042 6.07575 0 6.07481 0H0.30002C0.134188 0 0 0.133417 0 0.297969ZM0.600039 14.4041V4.75805H17.4V14.4041H0.600039ZM5.94377 0.595938L7.59659 2.36847C7.65313 2.42929 7.73312 2.46392 7.81662 2.46392H17.4V4.16211H0.600039V0.595938H5.94377Z"
                    stroke="black"
                />
            </svg>
        </Box>
    )
}
export default FileIcon
