import { Box, Typography } from '@mui/material'

import HourglassIcon from '../assets/hourglass.png'
import ExclaimationIcon from '../assets/exclaimBox.png'

export function MobileOverlayBlock() {
    return (
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', padding: 6 }}>
            <Box sx={{ margin: 'auto', width: '100%', textAlign: 'center', transform: 'translateY(50%)' }}>
                <Typography marginBottom={5} variant="h1">
                    Thank you for signing up!
                </Typography>
                <Box width={{ width: '100%', margin: 'auto' }}>
                    <Box
                        marginBottom={5}
                        maxWidth={30}
                        component="img"
                        sx={{
                            position: 'relative',
                        }}
                        src={HourglassIcon}
                        alt="logo"
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center,',
                        backgroundColor: '#FEBD1194',
                        borderRadius: 1,
                        padding: 1.5,
                        textAlign: 'center',
                        width:'fit-content',
                        margin: 'auto'
                    }}
                >
                    <Box
                        width={25}
                        height={25}
                        marginRight={1.2}
                        component="img"
                        sx={{
                            position: 'relative',
                        }}
                        src={ExclaimationIcon}
                        alt="logo"
                    />
                    <Typography variant="body2" color={'primary.light'}>
                        The Globoz portal is currently desktop/PC use only. Mobile compatibility coming soon! 👀
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
