import { useState, useContext, useEffect } from 'react'
import { Box, Button, Typography, TextField, Select, MenuItem, FormControl, Grid } from '@mui/material'

import { PageHeader } from '../common/pageHeader'
import { UserContext } from '../../common/userContext'

import { Question } from '../../graphql/API'

import { callApi } from '../../common/apiUtils'
import { getQuestionById } from '../../graphql/queries'
import { modifyQuestion } from '../../graphql/mutations'
import { Link, useParams } from 'react-router-dom'
import { CommonStyles, theme } from '../../assets/theme'
import { LoadingButton } from '@mui/lab'
import { Loading } from '../common/loading'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { questionTypes } from './questionCommon'

type MultichoiceType = {
    for: string
    value: string
}

export default function QuestionPage() {
    const contextData = useContext(UserContext)

    const [loaded, setLoaded] = useState<boolean>(false)
    const [currentQuestion, setCurrentQuestion] = useState<Question>()
    const [questionOrder, setQuestionOrder] = useState<number>(99)
    const [questionNotes, setQuestionNotes] = useState<string>('')
    const [questionText, setQuestionText] = useState<string>('')
    const [questionType, setQuestionType] = useState<string>('')

    const [questionChoices, setQuestionChoices] = useState<Array<string>>([])
    const [questionHelperTexts, setQuestionHelperTexts] = useState<Array<MultichoiceType>>([])

    const { id } = useParams()

    const [processing, setProcessing] = useState<boolean>(false)

    const addChoice = () => {
        setQuestionChoices([...questionChoices, ''])
        setQuestionHelperTexts([...questionHelperTexts, { for: questionChoices.length.toString(), value: '' }])
    }

    const removeChoice = (index) => {
        if (questionChoices.length > 1) {
            const newQuestionChoice = [...questionChoices]
            newQuestionChoice.splice(index, 1)
            setQuestionChoices(newQuestionChoice)

            const updatedQuestionHelperTexts = [...questionHelperTexts]

            updatedQuestionHelperTexts.splice(updatedQuestionHelperTexts.length - 1, 1)

            setQuestionHelperTexts([...updatedQuestionHelperTexts])
        }
    }
    const handleSubmit = async () => {
        setProcessing(true)
        let helperTexts = Array<string>()

        if (questionHelperTexts && questionHelperTexts.length > 0) {
            for (let n = 0; n < questionHelperTexts.length; n++)
                helperTexts.push(questionHelperTexts.find((qht) => qht.for === n.toString())!.value!)
        }
        const item = {
            id: currentQuestion?.id,
            questionText: questionText,
            questionType: questionType,
            order: questionOrder ? questionOrder : 999,
            notes: questionNotes ? questionNotes : '',
            choices: questionChoices ? questionChoices : [],
            questionHelperTexts: helperTexts ? helperTexts : [],
        }

        const modQuestion = await callApi<Question>(contextData.user, 'modifyQuestion', {
            query: modifyQuestion,
            variables: { item: item },
        })

        if (modQuestion.Result) {
            const r = modQuestion.Result
            setCurrentQuestion(r)
            setQuestionText(r.questionText!)
            setQuestionType(r.questionType!)
            setQuestionOrder(r.order!)
            setQuestionNotes(r.notes!)

            if (r.questionHelperTexts && r.questionHelperTexts.length > 0) {
                var helpers = Array<MultichoiceType>()
                let num = 0
                r.questionHelperTexts.forEach((hh) => {
                    helpers.push({ for: num.toString(), value: hh ? hh : '' })
                    num++
                })
                setQuestionHelperTexts(helpers)
            }
            let newQuestions = [...contextData.questions]

            newQuestions.splice(newQuestions.findIndex((qq) => qq.id === r.id)!, 1)
            newQuestions.push(r)
            contextData.setQuestions(newQuestions)
            setProcessing(false)
        }
    }

    const setBooleanHelperTexts = (val: string, index: number) => {
        const f = [...questionHelperTexts.filter((qht) => qht.for !== index.toString())]
        if (val === '' || !val) val = ''
        f.push({ for: index.toString(), value: val })
        setQuestionHelperTexts(f)
        return f
    }

    const setChoiceTexts = (val: string, index: number) => {
        const current = [...questionChoices]
        current[index] = val
        setQuestionChoices([...current])
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                if (id) {
                    // initially make page unloaded, get the visas then set loaded
                    setLoaded(false)

                    const current = await callApi<Question>(contextData.user, 'getQuestionById', {
                        query: getQuestionById,
                        variables: { pk: id },
                    })

                    if (current.Result) {
                        const r = current.Result
                        setCurrentQuestion(current.Result)
                        setQuestionText(r.questionText ? r.questionText : '')
                        setQuestionType(r.questionType ? r.questionType : '')
                        setQuestionOrder(r.order ? r.order! : 999)
                        setQuestionNotes(r.notes ? r.notes : '')

                        if (r.choices && r.choices.length > 0) {
                            var ch = Array<string>()
                            r.choices.forEach((cc) => {
                                ch.push(cc!)
                            })
                            setQuestionChoices(ch)
                        }

                        if (r.questionHelperTexts && r.questionHelperTexts.length > 0) {
                            var helpers = Array<MultichoiceType>()
                            let num = 0
                            r.questionHelperTexts.forEach((hh) => {
                                helpers.push({ for: num.toString(), value: hh ? hh : '' })
                                num++
                            })
                            setQuestionHelperTexts(helpers)
                        } else {
                            setQuestionHelperTexts([])
                        }
                    }
                }
            } finally {
                setLoaded(true)
            }
        }

        initialise()
    }, [])
    if (loaded && currentQuestion)
        return (
            <Box maxWidth={'70vw'}>
                <PageHeader title={`Editing Question - id ${currentQuestion.id}`} />
                <Box marginBottom={4}>
                    <Typography variant="h4">What does this question look like?</Typography>
                    <Box sx={[CommonStyles.dashedBorder, { padding: 2, background: '#fff' }]}>
                        <Box>
                            <Typography variant="body2" color={'secondary'} marginBottom={0}>
                                Question Title:
                            </Typography>
                            <Typography variant="body1" color={'secondary'}>
                                {currentQuestion.questionText}
                            </Typography>
                        </Box>
                        {questionChoices && questionType !== 'boolean' && (
                            <Box marginTop={1}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color={'secondary'} marginBottom={0}>
                                            Question Choices:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color={'secondary'} marginBottom={0}>
                                            Choice Helper Text:
                                        </Typography>
                                    </Grid>

                                    {questionChoices.map((q, index) => (
                                        <Grid item container xs={12} sx={{}}>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" color={'secondary'}>
                                                    {q}
                                                </Typography>
                                            </Grid>
                                            {questionHelperTexts[index] && (
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" color={'secondary'}>
                                                        {questionHelperTexts[index].value}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}

                        {questionType === 'boolean' && questionHelperTexts.length > 0 && (
                            <Box marginTop={1}>
                                {questionHelperTexts && questionHelperTexts.find((qht) => qht.for === '0') && (
                                    <Box marginBottom={1}>
                                        <Typography variant="body2" color={'secondary'} marginBottom={0}>
                                            Helper text for yes
                                        </Typography>
                                        <Typography variant="body1" color={'secondary'} marginBottom={0}>
                                            {questionHelperTexts.find((qht) => qht.for === '0')!.value}
                                        </Typography>
                                    </Box>
                                )}
                                {questionHelperTexts && questionHelperTexts.find((qht) => qht.for === '1') && (
                                    <Box marginBottom={1}>
                                        <Typography variant="body2" color={'secondary'} marginBottom={0}>
                                            Helper text for no
                                        </Typography>
                                        <Typography variant="body1" color={'secondary'} marginBottom={0}>
                                            {questionHelperTexts.find((qht) => qht.for === '1')!.value}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>

                <Typography variant="h3" color={theme.palette.secondary.light}>
                    Change the question data below
                </Typography>

                <FormControl sx={{ marginBottom: 2 }} fullWidth>
                    <Typography variant="h4">Question Text:</Typography>
                    <TextField
                        id="questionText"
                        name="questionText"
                        value={questionText}
                        onChange={(e) => setQuestionText(e.target.value)}
                        size="small"
                    />
                </FormControl>

                <FormControl sx={{ marginBottom: 2 }} fullWidth>
                    <Typography variant="h4">Question Type:</Typography>

                    <Select
                        id="questionType"
                        name="questionType"
                        value={questionType}
                        onChange={(e) => setQuestionType(e.target.value)}
                        sx={{ background: '#fff' }}
                        size="small"
                    >
                        <MenuItem key={''} value={''}>
                            - please select a type -
                        </MenuItem>
                        {questionTypes.map((qt) => (
                            <MenuItem key={qt.key} value={qt.value}>
                                {qt.text}
                            </MenuItem>
                        ))}
                        {/* <MenuItem key={'Multiselect'} value="multiselect">
                            Multiselect
                        </MenuItem> */}
                    </Select>

                    {questionChoices.length > -1 && (questionType === 'choice' || questionType === 'multiselect') && (
                        <Box marginTop={2}>
                            <Typography variant="h4">Question Choices</Typography>

                            <Box>
                                {questionChoices.map((field, index) => (
                                    <Grid container key={index}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">Choice {index + 1} text</Typography>
                                            <TextField
                                                id={'choice' + index}
                                                name={'choice' + index}
                                                value={questionChoices[index]}
                                                onChange={(e) => setChoiceTexts(e.target.value, index)}
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                sx={{ marginBottom: 1, paddingRight: 2 }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">Helper text</Typography>
                                            <TextField
                                                id={'choiceHelperText' + index}
                                                name={'choiceHelperText' + index}
                                                value={
                                                    questionHelperTexts.find((qht) => qht.for === index.toString())!
                                                        ? questionHelperTexts.find(
                                                              (qht) => qht.for === index.toString()
                                                          )!.value
                                                        : ''
                                                }
                                                onChange={(e) => setBooleanHelperTexts(e.target.value, index)}
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                sx={{ marginBottom: 1 }}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}

                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        sx={{ textAlign: 'right' }}
                                        variant="outlined"
                                        onClick={removeChoice}
                                        disabled={questionChoices.length <= 1}
                                    >
                                        -
                                    </Button>
                                    <Typography
                                        variant="body1"
                                        sx={{ alignSelf: 'center', marginLeft: 2, marginRight: 2 }}
                                    >
                                        {questionChoices.length}
                                    </Typography>
                                    <Button sx={{ textAlign: 'right' }} variant="outlined" onClick={addChoice}>
                                        +
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </FormControl>

                {questionType === 'boolean' && questionHelperTexts && (
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Typography variant="h4">Helper text for yes</Typography>
                                <TextField
                                    id="questionHelperText0"
                                    name="questionHelperText0"
                                    value={
                                        questionHelperTexts.find((qht) => qht.for === '0')
                                            ? questionHelperTexts.find((qht) => qht.for === '0')!.value
                                            : ''
                                    }
                                    onChange={(e) => setBooleanHelperTexts(e.target.value, 0)}
                                    multiline
                                    size="small"
                                    rows={2}
                                    fullWidth
                                    sx={{ marginBottom: 1, paddingRight: 2 }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Typography variant="h4">Helper text for no</Typography>
                                <TextField
                                    id="questionHelperText1"
                                    name="questionHelperText1"
                                    value={
                                        questionHelperTexts.find((qht) => qht.for === '1')
                                            ? questionHelperTexts.find((qht) => qht.for === '1')!.value
                                            : ''
                                    }
                                    onChange={(e) => setBooleanHelperTexts(e.target.value, 1)}
                                    multiline
                                    size="small"
                                    rows={2}
                                    fullWidth
                                    sx={{ marginBottom: 1 }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )}

                <FormControl>
                    <Typography variant="h4">Question Order Number</Typography>
                    <TextField
                        id="order"
                        name="order"
                        type="number"
                        value={questionOrder}
                        onChange={(e) => setQuestionOrder(parseInt(e.target.value))}
                        size="small"
                        fullWidth
                        sx={{ marginBottom: 1 }}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <Typography variant="h4">Notes</Typography>
                    <TextField
                        id="notes"
                        name="notes"
                        value={questionNotes}
                        onChange={(e) => setQuestionNotes(e.target.value)}
                        size="small"
                        fullWidth
                        rows={2}
                    />
                </FormControl>

                <Box marginTop={3}>
                    <Box sx={CommonStyles.buttonFlex}>
                        <Button
                            variant="outlined"
                            sx={{
                                alignSelf: 'end',
                                marginRight: '10px',
                            }}
                            component={Link}
                            to="/questiondashboard"
                        >
                            Close
                        </Button>
                        <LoadingButton
                            variant="contained"
                            loading={processing}
                            loadingIndicator="SAVING!"
                            sx={{
                                alignSelf: 'end',
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Box>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    return <Loading />
}
