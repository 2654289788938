import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { callApi } from '../../common/apiUtils'
import { PageInfo } from '../common/pageInfo'
import { useCallbackPrompt } from '../../common/useCallbackPrompt'
import { getErrorMessage } from '../../common/errorUtils'
import { ProfileGeneralDetails } from './profileGeneralDetails'
import { UserContext } from '../../common/userContext'
import { User, UserInput } from '../../graphql/API'
import { createUser, updateUser } from '../../graphql/mutations'
import { isEqual } from 'lodash'
import * as common from './profileCommon'
import { ProfileInitial } from './profileInitial'
import { CommonStyles } from '../../assets/theme'
import { PageHeader } from '../common/pageHeader'
import useMediaQuery from '@mui/material/useMediaQuery'

function goToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

export type ProfileProps = {
    dialog: boolean
    mobileMode: boolean
}

export function Profile({ dialog, mobileMode }: ProfileProps) {
    const navigate = useNavigate()
    const context = useContext(UserContext)

    const orig = common.getUserInput(context.user.sub, context.user.email, context.ledgeUser)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false)
    const [pageInfo, setPageInfo] = useState({ message: '', color: '' })
    const [origInput, setOrigInput] = useState(orig)
    const [userInput, setUserInput] = useState(orig)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [emailVerificationRequestSent, setEmailVerificationRequestSent] = useState(false)
    const [showUnsavedChangesPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(unsavedChanges)

    useEffect(() => {
        let isCancelled = false
        const initialise = async () => {
            // const result = await clearCandidateContext(context)
            // if (!result.Result) {
            //     return
            // }
            const orig = common.getUserInput(context.user.sub, context.user.email, context.ledgeUser)
            setOrigInput(orig)
            setUserInput(orig)
        }

        initialise()
        return () => {
            isCancelled = true
        }
    }, [])

    const setValue = (name: string, value: any) => {
        setUserInput((current) => {
            const updated = common.getUpdatedInput(current, name, value)
            // updateUnsavedChanges(updated)
            return updated
        })
    }

    const updateProfile = async () => {
        if (!userInput.firstName || !userInput.lastName || !userInput.phone) return
        setBackdropOpen(true)
        setPageInfo({ message: '', color: '' })
        const isNew = !context.ledgeUser?.id

        const [operation, operationName] = isNew ? [createUser, 'createUser'] : [updateUser, 'updateUser']

        var testUserInput = userInput
        testUserInput.jobInterestCategory = userInput.jobInterestCategory!

        if (testUserInput.jobInterestCategory.length > 0 || testUserInput.jobInterestCategoryOther) {
            testUserInput.sourceJobForMe = true
            const d = new Date()
            testUserInput.sourceJobChangeDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
        } else testUserInput.sourceJobForMe = false

        setUserInput(testUserInput)

        // console.log(userInput)
        const updatedUser = await callApi<User>(context.user, operationName, {
            query: operation,
            variables: {
                item: {
                    ...userInput,
                },
            },
        })

        setBackdropOpen(false)

        if (!updatedUser.Result) {
            const message = getErrorMessage('updating your profile', updatedUser.Error)
            setPageInfo({ message: message, color: 'error' })
            return
        }

        setOrigInput({
            ...userInput,
        })
        setUnsavedChanges(false)
        context.setLedgeUser(updatedUser.Result)

        if (isNew) {
            navigate('/applications')
            return
        }

        setPageInfo({
            message: 'Your profile details have been updated!',
            color: 'secondary.light',
        })

        goToTop()
    }

    function updateUnsavedChanges(updated: UserInput) {
        if (dialog) return
        const hasChanges = isEqual(origInput, updated) === false
        setUnsavedChanges(hasChanges)
        var message = hasChanges ? 'There are unsaved changes' : ''
        setPageInfo({ message: message, color: 'secondary.main' })
    }

    const confirmNavigationWithSave = async (saveFirst: boolean) => {
        if (saveFirst) await updateProfile() // This will navigate after the save
        confirmNavigation()
    }

    const resendEmailVerification = async () => {
        try {
            setBackdropOpen(true)
            const result = await context.user.resendEmailVerification()
            setEmailVerificationRequestSent(result)
            goToTop()
        } finally {
            setBackdropOpen(false)
        }
    }

    if (dialog)
        return (
            <ProfileInitial
                userInput={userInput}
                mobileMode={mobileMode}
                gridItemWidth={6}
                setValue={setValue}
                updateProfile={updateProfile}
            />
        )
    return (
        <Box sx={{ mt: 0, ml: 0 }}>
            {/* <ConfirmDialogUnsavedChanges
                showDialog={showUnsavedChangesPrompt}
                confirmNavigation={confirmNavigationWithSave}
                cancelNavigation={cancelNavigation}
            /> */}
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <PageHeader title={`${userInput.firstName} ${userInput.lastName}`} />
                    <Typography variant="body1" color={'secondary'}>
                        Enter some information about yourself here!
                    </Typography>
                </Box>
                <Box sx={{ paddingRight: 2, textAlign: 'end' }}>
                    <Button variant="contained" onClick={updateProfile}>
                        Save
                    </Button>
                </Box>
            </Box>

            <PageInfo {...pageInfo} sx={{ mt: 1 }} />

            {context.user.emailVerified === false && emailVerificationRequestSent === false && (
                <Box sx={[CommonStyles.dashedBorder, { padding: 2, mt: 2 }]}>
                    <Typography variant="h3" color={'secondary.light'} component="div">
                        Email Verification
                    </Typography>
                    <p>
                        Your email address has not yet been verified. Some features will remain disabled until you have
                        verified your account.
                    </p>
                    <Box>
                        <Button variant="contained" color="error" onClick={resendEmailVerification}>
                            Resend email verification request
                        </Button>
                    </Box>
                </Box>
            )}

            {emailVerificationRequestSent && (
                <>
                    <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                        Email Verification Request Sent
                    </Typography>
                    <p>
                        An email has been sent to {context.user.email} with instructions on how to verify your account.
                        Protected Globoz features will be unlocked after you have completed your email verification
                        the next time you sign in.
                    </p>

                    <Box sx={{ fontStyle: 'italic', fontSize: 'small' }}>
                        *Note please check you trash folder if the email does not appear in your inbox shortly
                    </Box>
                </>
            )}

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ProfileGeneralDetails userInput={userInput} setValue={setValue} gridItemWidth={6} />
                </Grid>
            </Grid>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export function ProfileDialog() {
    const mobileMode = useMediaQuery('(max-width:700px)')
    return (
        <Dialog open={true} fullScreen>
            <DialogContent>
                <Box>
                    <Profile dialog={true} mobileMode={mobileMode} />
                </Box>
            </DialogContent>
        </Dialog>
    )
}
