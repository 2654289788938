import { useState, useContext, useEffect } from 'react'
import { Box, Typography, Tab, Tabs, Button } from '@mui/material'
import { UserContext } from '../../common/userContext'
import { CommonStyles } from '../../assets/theme'
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import SchoolIcon from '@mui/icons-material/School'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import LocalAirportIcon from '@mui/icons-material/LocalAirport'
import LanguageIcon from '@mui/icons-material/Language'
import WorkIcon from '@mui/icons-material/Work'
import AddIcon from '@mui/icons-material/Add'
import { ResponseTab } from './tabs/responseTab'
import { Loading } from '../common/loading'
import { NewDocumentModal } from '../modals/NewDocumentModal'
import { EditResult, EditProps, EditPropsClosed } from '../../common/types'

const tabIndexes = {
    Identity: 0,
    Character: 1,
    Financial: 2,
    Education: 3,
    Family: 4,
    Health: 5,
    Travel: 6,
    Visa: 7,
    Employment: 8,
}

export function Responses() {
    const contextData = useContext(UserContext)

    const [currentTab, setCurrentTab] = useState<Number>(0)
    const [initialised, setInitialised] = useState(false)

    const [editDialogProps, setEditDialogProps] = useState<EditProps>(EditPropsClosed)

    // Tab stuff =================================================================
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props

        if(!children) {
            console.log(`undefine tab children for tab index ${index}`)
        }

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && children && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        )
    }

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue)
    }

    const tabProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }
    // =======================================================================================
    const openUploadDocument = () => {
        let editProps: EditProps = {
            open: true,
            id: 'add',
            data: undefined,
            callback: handleAddResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        }
        setEditDialogProps(editProps)
    }

    const handleAddResult = (editResult: EditResult, document: any) => {
        setEditDialogProps(EditPropsClosed)
        switch (editResult) {
            case EditResult.Added:
                if (document) {
                    const addedDocumentTabIndex = tabIndexes[document.documentCategory]
                    if(addedDocumentTabIndex)
                        setCurrentTab(addedDocumentTabIndex)
                    contextData.setDocuments([...contextData.documents, document])
                }
                break
            default:
                break
        }
    }
    // ==============================================================================

    useEffect(() => {
        const initialise = async () => {
            try {
                // await clearCandidateContext(contextData)
                // const result = await callApi<UserAuth0>(contextData.user, 'getUserAuth0ById', {
                //     query: getUserAuth0ById,
                //     variables: { pk: contextData.user.sub },
                // })
                // if (result.Error) {
                //     console.log(result.Error)
                // }

                setInitialised(true)
            } catch (error) {
                console.log(error)
            }
        }

        initialise()
    }, [])

    if (initialised)
        return (
            <Box
                sx={{
                    mt: 1,
                    ml: 0,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                    <Typography variant="h1" sx={{ mb: 2 }}>
                        My Responses
                    </Typography>
                    <NewDocumentModal {...editDialogProps} />
                    <Button
                        sx={{ alignSelf: 'center', height: '40px' }}
                        startIcon={<AddIcon />}
                        variant="contained"
                        size="small"
                        onClick={openUploadDocument}
                    >
                        UPLOAD DOCUMENT
                    </Button>
                </Box>

                <Box sx={{ mb: 4 }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" color={'#205493'}>
                            Save your responses to Visa application questions and upload relevant supporting documents
                            here. Questions are sorted by their category for easy management.
                        </Typography>
                        <Typography variant="body1" color={'#205493'}>
                            Export your responses and documents via the “My applications” tab
                        </Typography>
                    </Box>
                </Box>

                <Box sx={CommonStyles.tabBar}>
                    <Tabs
                        id="sectionTabs"
                        value={currentTab}
                        onChange={handleChangeTab}
                        variant="fullWidth"
                        sx={{ padding: '0 40px' }}
                    >
                        <Tab label="Identity" icon={<ContactEmergencyIcon />} {...tabProps(tabIndexes.Identity)} />
                        <Tab label="Character" icon={<PersonOutlineIcon />} {...tabProps(tabIndexes.Character)} />
                        <Tab label="Financial" icon={<AccountBalanceIcon />} {...tabProps(tabIndexes.Financial)} />
                        <Tab label="Education" icon={<SchoolIcon />} {...tabProps(tabIndexes.Education)} />
                        <Tab label="Family" icon={<FamilyRestroomIcon />} {...tabProps(tabIndexes.Family)} />
                        <Tab label="Health" icon={<MonitorHeartIcon />} {...tabProps(tabIndexes.Health)} />
                        <Tab label="Travel" icon={<LocalAirportIcon />} {...tabProps(tabIndexes.Travel)} />
                        <Tab label="Visa" icon={<LanguageIcon />} {...tabProps(tabIndexes.Visa)} />
                        <Tab label="Employment" icon={<WorkIcon />} {...tabProps(tabIndexes.Employment)} />
                    </Tabs>
                </Box>

                <Box>
                    <TabPanel value={currentTab} index={tabIndexes.Identity}>
                        <ResponseTab category="Identity" />
                    </TabPanel>

                    <TabPanel value={currentTab} index={tabIndexes.Character}>
                        <ResponseTab category="Character" />
                    </TabPanel>

                    <TabPanel value={currentTab} index={tabIndexes.Financial}>
                        <ResponseTab category="Financial" />
                    </TabPanel>

                    <TabPanel value={currentTab} index={tabIndexes.Education}>
                        <ResponseTab category="Education" />
                    </TabPanel>

                    <TabPanel value={currentTab} index={tabIndexes.Family}>
                        <ResponseTab category="Family" />
                    </TabPanel>

                    <TabPanel value={currentTab} index={tabIndexes.Health}>
                        <ResponseTab category="Health" />
                    </TabPanel>

                    <TabPanel value={currentTab} index={tabIndexes.Travel}>
                        <ResponseTab category="Travel" />
                    </TabPanel>

                    <TabPanel value={currentTab} index={tabIndexes.Visa}>
                        <ResponseTab category="Visa" />
                    </TabPanel>

                    <TabPanel value={currentTab} index={tabIndexes.Employment}>
                        <ResponseTab category="Employment" />
                    </TabPanel>

                    {!currentTab && currentTab !== 0 && (
                        <Box sx={{ width: '100%', textAlign: 'center', marginTop: 8 }}>
                            <Typography variant="body1">
                                Please select a tab from the list above so you can begin inputting your data!
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        )
    return <Loading />
}
