import { Box, Grid, TextField, Typography } from '@mui/material'

import { UserInput } from '../../graphql/API'
import * as common from './profileCommon'
import Select, { SelectChangeEvent } from '@mui/material/Select/Select'
import MenuItem from '@mui/material/MenuItem/MenuItem'

export type ApplicationSectionProps = {
    userInput: UserInput
}

export function ProfileGeneralDetails({ userInput, setValue, gridItemWidth }: common.ProfileSectionProps) {
    const handleHowDidYouFindOutAboutUs = (event: SelectChangeEvent<typeof userInput.jobHowDidYouFindOutAboutUs>) => {
        const {
            target: { value },
        } = event
        setValue(
            event.target.name,
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    return (
        <Box
            sx={{
                boxSizing: 'border-box',
                borderRadius: '5px',
                mb: 2,
            }}
        >
            <Grid container spacing={2} mb={4} mt={2}>
                <Grid item xs={gridItemWidth * 2}>
                    <Typography variant="h4" fontWeight={400} mb={0} color={'primary.light'}>
                        General Details:
                    </Typography>
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                        First name:
                    </Typography>
                    <TextField
                        autoFocus
                        fullWidth
                        size="small"
                        name="firstName"
                        value={userInput.firstName}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        error={userInput.firstName ? false : true}
                        helperText={userInput.firstName ? '' : 'Please enter your first name!'}
                        sx={{ backgroundColor: 'white' }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                        Last name:
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        name="lastName"
                        value={userInput.lastName}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        error={userInput.lastName ? false : true}
                        helperText={userInput.lastName ? '' : 'Please enter your last name!'}
                        sx={{ backgroundColor: 'white' }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                        Preferred name:
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        name="preferredName"
                        value={userInput.preferredName}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        sx={{ backgroundColor: 'white' }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                        Gender:
                    </Typography>
                    <Select
                        id="sex"
                        name="sex"
                        value={userInput.sex}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        size="small"
                        sx={{ backgroundColor: 'white' }}
                        fullWidth
                    >
                        <MenuItem key={''} value="">
                            -- please select a value
                        </MenuItem>
                        {common.profileGenderList.map((pgl) => (
                            <MenuItem key={pgl.key} value={pgl.value}>
                                {pgl.value}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                        Contact number:
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        name="phone"
                        value={userInput.phone}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        helperText={userInput.phone ? '' : 'Please enter a valid phone number!'}
                        error={userInput.phone ? false : true}
                        sx={{ backgroundColor: 'white' }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                        Where are you currently situated?:
                    </Typography>
                    <TextField
                        fullWidth
                        size="small"
                        name="jobCurrentLocation"
                        value={userInput.jobCurrentLocation}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        sx={{ backgroundColor: 'white' }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                        Date of birth:
                    </Typography>
                    <TextField
                        sx={{ backgroundColor: 'white' }}
                        fullWidth
                        size="small"
                        name="dateOfBirth"
                        type="date"
                        value={userInput.dateOfBirth}
                        helperText={userInput.dateOfBirth ? '' : 'Please enter a your date of birth!'}
                        error={userInput.dateOfBirth ? false : true}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={gridItemWidth * 2}>
                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                        How did you find out about us?
                    </Typography>
                    <Select
                        id="jobHowDidYouFindOutAboutUs"
                        name="jobHowDidYouFindOutAboutUs"
                        value={userInput.jobHowDidYouFindOutAboutUs}
                        multiple
                        onChange={handleHowDidYouFindOutAboutUs}
                        sx={{ backgroundColor: 'white' }}
                        size={'small'}
                        fullWidth
                    >
                        {common.jobHowDidYouFindOutAboutUsList.map((jhdyfoaul) => (
                            <MenuItem key={jhdyfoaul.key} value={jhdyfoaul.value}>
                                {jhdyfoaul.text}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </Box>
    )
}
