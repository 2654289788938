import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { theme } from './assets/theme'
import { CssBaseline } from '@mui/material'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { getAwsExports } from './common/awsUtils'
import App from './App'

const aws_exports = getAwsExports()

LicenseInfo.setLicenseKey(
    '82462bca654f171d38382ff21442dd93T1JERVI6NDA0NDMsRVhQSVJZPTE2Nzk4MTM0MDYwMDAsS0VZVkVSU0lPTj0x'
)

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={aws_exports.openid_domain}
            clientId={aws_exports.openid_client_id}
            redirectUri={window.location.origin}
            useRefreshTokens={true}
            cacheLocation={'memory'}
            scope={'openid profile email phone'}
        >
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </MuiThemeProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
)
