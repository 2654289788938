import { useState } from 'react'

import { Box, Button, Typography, TextField, FormControl } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import DialogTitle from '@mui/material/DialogTitle'
import RedDivider from '../common/redDivider'

import { callApi } from '../../common/apiUtils'
import { Visa } from '../../graphql/API'
import { createVisa } from '../../graphql/mutations'
import { ContextDataInterface } from '../../common/userContext'
import { CommonStyles } from '../../assets/theme'

const { v4: uuid } = require('uuid')

type NewVisaTypeModalProps = {
    open: boolean
    user: any
    contextData: ContextDataInterface
    onClose: (e: any) => void
}

const NewVisaTypeModal = (props: NewVisaTypeModalProps) => {
    const { onClose, open } = props
    const [visaName, setVisaName] = useState<string>()
    const [documentTypes, setDocumentTypes] = useState<Array<string>>([])

    const [errorState, setErrorState] = useState<string>('')

    const checkComplete = () => {
        if (visaName && visaName !== '') return false
        return true
    }

    const clearAll = () => {
        setErrorState('')
        setVisaName('')
        setDocumentTypes([])
    }

    const handleClose = () => {
        onClose({ completed: false })
        clearAll()
    }

    const handleSubmit = async () => {
        const itemId = uuid()

        const item = { id: itemId, name: visaName, documentTypes: documentTypes, topics: [] }

        const createVisaResult = await callApi<Array<Visa>>(props.user, 'createVisa', {
            query: createVisa,
            variables: { item: item },
        })

        if (createVisaResult) {
            onClose({ completed: true })
            clearAll()
            window.location.href = `./questiondashboard/visa/${itemId}`
        }

        onClose({ completed: false })
        clearAll()
    }

    return (
        <Dialog onClose={handleClose} open={open} sx={{ borderRadius: 1 }}>
            <Box sx={{ width: '475px', padding: 1 }}>
                <DialogTitle variant="h2" sx={{ marginBottom: 0 }}>
                    Create a new Visa Type
                    <RedDivider />
                </DialogTitle>

                <DialogContent>
                    <Typography variant="body2">
                        This will create a new visa type that you can add document requirements and question
                        requirements to. Please set the name of this visa type!
                    </Typography>
                    <Typography variant="body2">You will be able to add requirements on the next page!</Typography>

                    <FormControl sx={{ marginBottom: 4, marginTop: 2 }} fullWidth>
                        <Typography variant="h4">Visa Name:</Typography>
                        <TextField
                            id="visaName"
                            value={visaName!}
                            onChange={(e) => setVisaName(e.target.value)}
                            size="small"
                            sx={{ width: '100%' }}
                        />
                    </FormControl>

                    <Box>
                        {errorState === 'name' && (
                            <Box sx={{ fontSize: '10px', marginBottom: '10px' }}>
                                <Typography variant="body2" color="error">
                                    Please enter in a name!
                                </Typography>
                            </Box>
                        )}

                        <Box sx={CommonStyles.buttonFlex}>
                            <Button
                                variant="outlined"
                                sx={{
                                    alignSelf: 'end',
                                    marginRight: '10px',
                                }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    alignSelf: 'end',
                                }}
                                disabled={checkComplete()}
                                onClick={handleSubmit}
                            >
                                Complete
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default NewVisaTypeModal
