import { useContext, useEffect, useState } from 'react'
import { Backdrop, Box, CircularProgress, Typography, Button, Tooltip, Link } from '@mui/material'
import { useGridApiRef, DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { ContextDataInterface, UserContext } from '../../common/userContext'
import { callApi } from '../../common/apiUtils'
import { getDocumentsForUser, getUsers } from '../../graphql/queries'
import { Link as RouterLink } from 'react-router-dom'
import { Loading } from '../common/loading'
import { Document, User } from '../../graphql/API'
import { updateUserDocumentStatus } from '../../graphql/mutations'
import { getErrorMessage } from '../../common/errorUtils'
import { exportSingleUserMainDocs, exportSingleUserData, exportUserData } from '../../common/utils'
import DeleteUserModal from '../modals/DeleteUserModal'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import FileIcon from '../../assets/icons/FileIcon'
import CsvIcon from '../../assets/icons/CsvIcon'
import RefreshIcon from '@mui/icons-material/Refresh'
import { PageInfo } from '../common/pageInfo'

export default function UserList() {
    const contextData = useContext(UserContext)
    const apiRef = useGridApiRef()
    const [loaded, setLoaded] = useState(false)
    const [pageError, setPageError] = useState('')
    const [users, setUsers] = useState<User[]>([])
    const [userToDelete, setUserToDelete] = useState<User>()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    // ===========================================================

    const doUserDataExport = async () => {
        const users = await callApi<Array<User>>(contextData.user, 'getUsers', {
            query: getUsers,
        })
        let data = users.Result!

        exportUserData(data)
    }

    const handleDeleteModalClose = (completed: boolean) => {
        if (completed) {
            const newUsers = users?.filter((uu) => uu.id !== userToDelete?.id)
            setUsers(newUsers)
        }
        setUserToDelete(undefined)
        setDeleteModalOpen(false)
    }

    const doDeleteUser = async (user: User) => {
        setUserToDelete(user)
        setDeleteModalOpen(true)
    }

    const refreshUserStatus = async (user: User) => {
        try {
            setProcessing(true)

            const result = await callApi<User>(contextData.user, 'updateUserDocumentStatus', {
                query: updateUserDocumentStatus,
                variables: { pk: user.id },
            })

            if (result.Error) {
                setPageError(getErrorMessage('Refresh Status', result.Error))
                return
            }

            setUsers((us) => {
                const users = us ?? []
                user.status = result.Result?.status
                return [...users]
            })
        } finally {
            setProcessing(false)
        }
    }

    const userColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
            flex: 2,
        },
        {
            field: 'firstName',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'lastName',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Contact email',
            flex: 2,
        },
        {
            field: 'lookingForWork',
            headerName: 'Looking for work',
            flex: 2,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box>
                    {((params.row.jobInterestCategory && params.row.jobInterestCategory.length > 0) ||
                        (params.row.jobInterestCategory &&
                            params.row.jobInterestCategory.length > 0 &&
                            params.row.jobInterestCategoryOther)) && (
                        <Typography variant="body2" marginBottom={0}>
                            Yes
                        </Typography>
                    )}
                    {!params.row.jobInterestCategory && !params.row.jobInterestCategoryOther && (
                        <Typography variant="body2" marginBottom={0}>
                            No
                        </Typography>
                    )}
                </Box>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box>
                    <Typography variant="body2" marginBottom={0}>
                        {params.row.status ? params.row.status : 'n/a'}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'agency',
            headerName: 'Agency',
            flex: 2,
            // renderCell: (params: GridRenderCellParams<string>) => (
            //     <Box>
            //         <Typography variant="body2" marginBottom={0}>
            //             {params.row.agency ? params.row.agency : 'n/a'}
            //         </Typography>
            //     </Box>
            // ),
        },
        {
            field: 'open',
            headerName: 'Edit',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Edit User Details">
                        <Link component={RouterLink} to={`/userList/user/${params.row.id}`}>
                            <EditIcon />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Refresh Status">
                        <Box sx={{ cursor: 'pointer' }} onClick={() => refreshUserStatus(params.row)}>
                            <RefreshIcon />
                        </Box>
                    </Tooltip>
                </Box>
            ),
        },
        {
            field: 'export',
            headerName: 'Export',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ cursor: 'pointer' }} marginRight={1.5} onClick={() => exportSingleUserData(params.row)}>
                        <CsvIcon />
                    </Box>
                    <Box sx={{ cursor: 'pointer' }} onClick={() => exportSingleUserMainDocs(params.row, contextData)}>
                        <FileIcon />
                    </Box>
                </Box>
            ),
        },
        {
            field: 'delete',
            headerName: 'Delete',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box sx={{ cursor: 'pointer' }} onClick={() => doDeleteUser(params.row)}>
                    <DeleteIcon />
                </Box>
            ),
        },
    ]

    useEffect(() => {
        const initialise = async () => {
            try {
                // initially make page unloaded, get the visas then set loaded
                setLoaded(false)
                if (!contextData.user.isAdmin()) return
                const uu = await callApi<Array<User>>(contextData.user, 'getUsers', {
                    query: getUsers,
                })
                if (uu.Error) {
                    setPageError(getErrorMessage('Initialise', uu.Error))
                    return
                }
                setUsers(uu.Result ?? [])
            } finally {
                setLoaded(true)
            }
        }

        initialise()
    }, [])

    if (!contextData.user.isAdmin()) return <div />

    if (loaded && users)
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    minHeight: '300px',
                    minWidth: '300px',
                    marginBottom: 8,
                }}
            >
                <DeleteUserModal open={deleteModalOpen} onClose={handleDeleteModalClose} user={userToDelete} />
                <PageInfo color="error" message={pageError} />
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 }}>
                    <Typography variant="h1" marginBottom={2}>
                        Users
                    </Typography>
                    <Box display={'flex'}>
                        <Button variant="contained" onClick={doUserDataExport} size="small" sx={{ marginRight: 2 }}>
                            Download user data
                        </Button>
                        <Box
                            sx={{
                                border: '1px solid #402E8D',
                                padding: 1,
                                borderRadius: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography marginBottom={0} color={'secondary.light'} variant="body2" marginRight={2}>
                                Users Signed Up:
                            </Typography>
                            <Typography color={'secondary.light'} variant="body1">
                                {users.length}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <DataGridPro
                    autoPageSize
                    pagination
                    rows={users}
                    columns={userColumns}
                    apiRef={apiRef}
                    editMode="row"
                    // rowsPerPageOptions={[5, 10, 15]}
                    sx={{ maxHeight: 600 }}
                />
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    return <Loading />
}
