import { useContext, useEffect, useState } from 'react'
import { Box, Button, Grid, Typography, TextField, Tooltip } from '@mui/material'
import { DataGridPro, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro'

import { UserContext } from '../../common/userContext'
import { callApi } from '../../common/apiUtils'

import { getDocumentsForUser, getUserById } from '../../graphql/queries'
import { useParams } from 'react-router-dom'
import { Loading } from '../common/loading'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { User, Document } from '../../graphql/API'

import { downloadDocument } from '../../common/documentUtils'

import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned'
import ArticleIcon from '@mui/icons-material/Article'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { convertDOB, convertJoined, exportSingleUserData, exportSingleUserMainDocs } from '../../common/utils'

export default function AdminUserPage() {
    const contextData = useContext(UserContext)
    const apiRef = useGridApiRef()
    const [loaded, setLoaded] = useState<boolean>(false)
    const { id } = useParams()
    const [user, setUser] = useState<User>()
    const [userDocuments, setUserDocuments] = useState<Array<Document>>()
    const [processing, setProcessing] = useState<boolean>(false)

    // ===========================================================
    const gridItemWidth = 6

    const doDocumentAction = async (
        id: string,
        documentAction: (ContextDataInterface, Document) => Promise<Error | undefined>
    ) => {
        const row = apiRef.current.getRow(id) as Document
        if (!row) {
            console.log(`Edit row for ${id} was null!`)
            return
        }

        try {
            setProcessing(true)
            const error = await documentAction(contextData, row)
            if (error) {
                console.log(error)
                return
            }
        } catch (error) {
            alert(`Unable to view document! ${error}`)
        } finally {
            setProcessing(false)
        }
    }

    const documentColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
            flex: 2,
        },
        {
            field: 'documentName',
            headerName: 'Document name',
            flex: 2,
        },
        {
            field: 'documentCategory',
            headerName: 'Document category',
            flex: 2,
        },
        {
            field: 'documentType',
            headerName: 'Document type',
            flex: 2,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box sx={{ cursor: 'pointer' }} onClick={() => doDocumentAction(params.row.id, downloadDocument)}>
                    <FileDownloadIcon />
                </Box>
            ),
        },
    ]

    const checkDocumentPresent = (d: string) => {
        if (userDocuments) {
            const doc = userDocuments.find((dd) => dd.documentType === d)
            if (doc)
                return (
                    <Tooltip title={`Current document selected - ${d}`} arrow>
                        <CheckCircleIcon color="success" />
                    </Tooltip>
                )
        }
        return (
            <Tooltip title={`No document uploaded - please upload on the responses page!`} arrow>
                <ErrorIcon color="error" />
            </Tooltip>
        )
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                if (!id) return
                const getUser = await callApi<User>(contextData.user, 'getUserById', {
                    query: getUserById,
                    variables: { pk: id },
                })

                if (getUser.Result) {
                    setUser(getUser.Result!)
                    const getTheDocuments = await callApi<Array<Document>>(contextData.user, 'getDocumentsForUser', {
                        query: getDocumentsForUser,
                        variables: { pk: id },
                    })
                    if (getTheDocuments.Result) {
                        const docs = getTheDocuments.Result
                        setUserDocuments(docs)
                    }
                }
            } finally {
                setLoaded(true)
            }
        }

        initialise()
    }, [])
    
    if (loaded && user)
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    minHeight: '300px',
                    minWidth: '300px',
                }}
            >
                <Grid container justifyContent={'space-between'} marginBottom={4}>
                    <Grid item>
                        <Typography variant="h2">
                            {user.firstName} {user.lastName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<ArticleIcon />}
                            onClick={() => exportSingleUserMainDocs(user, contextData)}
                            sx={{ marginRight: 2 }}
                        >
                            Download all key docs
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<AssignmentReturnedIcon />}
                            onClick={() => exportSingleUserData(user)}
                        >
                            Export all user data
                        </Button>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={3} sx={{ borderRight: '1px solid #494949', height: '100%' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="body1" fontWeight={800} color={'secondary'} marginBottom={4}>
                                    User contact details
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="body2" marginBottom={0}>
                                    Given name
                                </Typography>
                                <Typography variant="body1" marginBottom={1} sx={{ overflowWrap: 'break-word' }}>
                                    {user.firstName}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" marginBottom={0}>
                                    Phone number
                                </Typography>
                                <Typography variant="body1" marginBottom={1} sx={{ overflowWrap: 'break-word' }}>
                                    {user.phone}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" marginBottom={0}>
                                    Surname
                                </Typography>
                                <Typography variant="body1" marginBottom={1} sx={{ overflowWrap: 'break-word' }}>
                                    {user.lastName}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} marginBottom={4}>
                                <Typography variant="body2" marginBottom={0}>
                                    DOB
                                </Typography>
                                <Typography variant="body1" marginBottom={1}>
                                    {user.dateOfBirth ? convertDOB(user.dateOfBirth) : 'n/a'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" marginBottom={0}>
                                    Email
                                </Typography>
                                <Typography variant="body1" marginBottom={1} sx={{ overflowWrap: 'break-word' }}>
                                    {user.email}
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={6}>
                                <Typography variant="body2" marginBottom={0}>
                                    Joined
                                </Typography>
                                <Typography variant="body1" marginBottom={1}>
                                    {convertJoined(user.created)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" marginBottom={0}>
                                    Looking for work?
                                </Typography>
                                <Typography variant="body1">
                                    {(user.jobInterestCategory && user.jobInterestCategory.length > 0) ||
                                    (user.jobInterestCategory &&
                                        user.jobInterestCategory.length > 0 &&
                                        user.jobInterestCategoryOther)
                                        ? 'YES'
                                        : 'NO'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" marginBottom={0}>
                                    Agency
                                </Typography>
                                <Typography variant="body1">N/A</Typography>
                            </Grid>

                            {/* <Grid item xs={12} marginTop={4}>
                                <Typography variant="body1" fontWeight={800} color={'secondary'}>
                                    Status
                                </Typography>
                                <Typography variant={'body1'}>STATUS!</Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={9} paddingLeft={4}>
                        <Box marginBottom={4}>
                            <Typography variant="body1" fontWeight={800} color={'secondary'} marginBottom={1}>
                                Key documents
                            </Typography>

                            <Box width={'100%'} borderBottom={'1px solid #494949'} marginBottom={4} />

                            <Box
                                sx={{
                                    border: '1px solid #A0A0A0',
                                    borderRadius: 2,
                                    backgroundColor: '#FFF',
                                    maxWidth: 500,
                                }}
                            >
                                <Grid
                                    container
                                    sx={{
                                        borderBottom: '1px solid #CFCFCF',
                                        padding: 1.5,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Grid item>
                                        <Typography variant="body1" marginBottom={0}>
                                            Curriculum Vitae
                                        </Typography>
                                    </Grid>
                                    <Grid item height={24}>
                                        {checkDocumentPresent('Curriculum Vitae')}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    sx={{
                                        borderBottom: '1px solid #CFCFCF',
                                        padding: 1.5,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Grid item>
                                        <Typography variant="body1" marginBottom={0}>
                                            Cover Letter
                                        </Typography>
                                    </Grid>
                                    <Grid item height={24}>
                                        {checkDocumentPresent('Cover Letter')}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    sx={{
                                        borderBottom: '1px solid #CFCFCF',
                                        padding: 1.5,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Grid item>
                                        <Typography variant="body1" marginBottom={0}>
                                            Valid Passport
                                        </Typography>
                                    </Grid>
                                    <Grid item height={24}>
                                        {checkDocumentPresent('Valid Passport')}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    sx={{
                                        borderBottom: '1px solid #CFCFCF',
                                        padding: 1.5,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Grid item>
                                        <Typography variant="body1" marginBottom={0}>
                                            Current Visa
                                        </Typography>
                                    </Grid>
                                    <Grid item height={24}>
                                        {checkDocumentPresent('Current Visa')}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box>
                            <Typography variant="body1" fontWeight={800} color={'secondary'} marginBottom={1}>
                                Job preferences
                            </Typography>

                            <Box width={'100%'} borderBottom={'1px solid #494949'} marginBottom={4} />

                            <Grid container spacing={2} mb={4}>
                                <Grid item xs={gridItemWidth}>
                                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                        What roles/job titles are you looking for? Select all that apply:
                                    </Typography>
                                    <TextField
                                        id="jobInterestCategory"
                                        name="jobInterestCategory"
                                        value={user.jobInterestCategory}
                                        sx={{ backgroundColor: 'white' }}
                                        size={'small'}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                {user.jobInterestCategory?.includes('other') && (
                                    <Grid item xs={gridItemWidth}>
                                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                            Other roles/job titles sought:
                                        </Typography>

                                        <TextField
                                            id="jobInterestCategoryOther"
                                            name="jobInterestCategoryOther"
                                            value={user.jobInterestCategoryOther}
                                            autoFocus
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                            disabled
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            <Grid container spacing={2} mb={4}>
                                <Grid item xs={gridItemWidth * 2} marginBottom={-2}>
                                    <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                                        Employment:
                                    </Typography>
                                </Grid>
                                <Grid item xs={gridItemWidth}>
                                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                        What was your previous experience?
                                    </Typography>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        size="small"
                                        name="jobPreviousExperience"
                                        value={user.jobPreviousExperience}
                                        sx={{ backgroundColor: 'white' }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={gridItemWidth}>
                                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                        English fluency:
                                    </Typography>

                                    <TextField
                                        id="jobEnglishFluency"
                                        name="jobEnglishFluency"
                                        value={user.jobEnglishFluency}
                                        size={'small'}
                                        fullWidth
                                        sx={{ backgroundColor: 'white' }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={gridItemWidth}>
                                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                        What is/was your area of study?
                                    </Typography>
                                    <TextField
                                        id="jobAreaOfStudy"
                                        name="jobAreaOfStudy"
                                        value={user.jobAreaOfStudy}
                                        sx={{ backgroundColor: 'white' }}
                                        size={'small'}
                                        fullWidth
                                        disabled
                                    />
                                    {user.jobAreaOfStudyOther?.includes('other') && (
                                        <TextField
                                            id="jobAreaOfStudyOther"
                                            name="jobAreaOfStudyOther"
                                            value={user.jobAreaOfStudyOther}
                                            sx={{ backgroundColor: 'white', marginTop: 2 }}
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mb={4}>
                                <Grid item xs={gridItemWidth * 2} marginBottom={-2}>
                                    <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                                        Transport:
                                    </Typography>
                                </Grid>
                                <Grid item xs={gridItemWidth}>
                                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                        Can you drive to work?
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        id="jobDrive"
                                        name="jobDrive"
                                        value={user.jobDrive}
                                        disabled
                                    />
                                </Grid>
                                {user.jobDrive === 'yes' && (
                                    <Grid item xs={gridItemWidth}>
                                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                            How far are you able to drive for work?
                                        </Typography>
                                        <TextField
                                            id="jobFar"
                                            name="jobFar"
                                            value={user.jobFar}
                                            fullWidth
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                            disabled
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            <Grid container spacing={2} mb={4}>
                                <Grid item xs={gridItemWidth * 2} marginBottom={-2}>
                                    <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                                        Availability:
                                    </Typography>
                                </Grid>
                                <Grid item xs={gridItemWidth}>
                                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                        What is your current visa type?
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        id="jobVisaStatus"
                                        name="jobVisaStatus"
                                        value={user.jobVisaStatus}
                                        disabled
                                    />
                                    {user.jobVisaStatus === 'other' && (
                                        <TextField
                                            id="jobVisaStatusOther"
                                            name="jobVisaStatusOther"
                                            value={user.jobVisaStatusOther}
                                            sx={{ backgroundColor: 'white', marginTop: 2 }}
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={gridItemWidth}>
                                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                        How many days are you willing to work per week?
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        id="jobDays"
                                        name="jobDays"
                                        value={user.jobDays}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={gridItemWidth}>
                                    <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                        What hours are you willing to work?
                                    </Typography>
                                    <TextField
                                        id="jobTime"
                                        name="jobTime"
                                        value={user.jobTime}
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box marginBottom={8}>
                            <Typography variant="body1" fontWeight={800} color={'secondary'} marginBottom={1}>
                                All documents
                            </Typography>

                            <Box width={'100%'} borderBottom={'1px solid #494949'} marginBottom={4} />

                            {userDocuments && (
                                <DataGridPro
                                    autoHeight
                                    pagination
                                    rows={userDocuments}
                                    columns={documentColumns}
                                    editMode="row"
                                    apiRef={apiRef}
                                />
                            )}
                            {!userDocuments && (
                                <Box>
                                    <Typography variant="body1">No user documents found!</Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ height: '100%' }}></Box>
                    <Box></Box>
                </Box>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    return <Loading />
}
