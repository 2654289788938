import { useState, useContext } from 'react'
import { Box, Button, Typography, TextField, Select, SelectChangeEvent, MenuItem, FormControl } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RedDivider from '../common/redDivider'
import { UserContext } from '../../common/userContext'
import { callApi } from '../../common/apiUtils'
import { createQuestionBlock } from '../../graphql/mutations'

import { QuestionBlock } from '../../graphql/API'
import { CommonStyles } from '../../assets/theme'

const { v4: uuid } = require('uuid')
type NewQuestionBlockModalProps = {
    open: boolean
    onClose: (e: any) => void
}

const NewQuestionBlockModal = (props: NewQuestionBlockModalProps) => {
    const { onClose, open } = props

    const [title, setTitle] = useState<string>('')
    const [subtext, setSubtext] = useState<string>('')
    const [questions, setQuestions] = useState<Array<string>>([])

    const [processing, setProcessing] = useState<boolean>()

    const contextData = useContext(UserContext)

    const clearAll = () => {
        setTitle('')
        setSubtext('')
        setQuestions([])
    }

    const handleClose = () => {
        onClose({ completed: false })
        clearAll()
    }

    const handleSubmit = async () => {
        setProcessing(true)
        const blockId = uuid()

        const blockItem = {
            id: blockId,
            title: title,
            subtext: subtext,
            questions: questions,
            order: 999
        }

        const qb = await callApi<QuestionBlock>(contextData.user, 'createQuestionBlock', {
            query: createQuestionBlock,
            variables: { item: blockItem },
        })

        contextData.setQuestionBlocks([...contextData.questionBlocks, qb.Result!])

        clearAll()
        setProcessing(false)
        onClose({ completed: true })
    }

    const handleSelectQuestions = (event: SelectChangeEvent<typeof questions>) => {
        const {
            target: { value },
        } = event
        setQuestions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    return (
        <Dialog onClose={handleClose} open={open} sx={{ borderRadius: 1 }}>
            <Box sx={{ width: '475px', padding: 1 }}>
                <DialogTitle variant="h2" sx={{ marginBottom: 0 }}>
                    Create a new question block
                    <RedDivider />
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ marginBottom: 3, borderRadius: 2 }}>
                        <FormControl sx={{ marginBottom: 2 }} fullWidth>
                            <Box>
                                <Typography variant="body2" color="secondary">
                                    Enter title:
                                </Typography>
                                <TextField
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    size="small"
                                    fullWidth
                                />
                            </Box>
                        </FormControl>

                        <FormControl sx={{ marginBottom: 2 }} fullWidth>
                            <Typography variant="body2" color="secondary">
                                Enter sub-text:
                            </Typography>
                            <TextField
                                id="subtext"
                                value={subtext}
                                onChange={(e) => setSubtext(e.target.value)}
                                size="small"
                                fullWidth
                                multiline
                                minRows={4}
                            />
                        </FormControl>

                        <FormControl sx={{ marginBottom: 2 }} fullWidth>
                            <Typography variant="body2" color="secondary">
                                Add questions:
                            </Typography>
                            <Select
                                id="questions"
                                multiple
                                value={questions}
                                onChange={handleSelectQuestions}
                                size={'small'}
                                fullWidth
                            >
                                {contextData.questions.map((q) => (
                                    <MenuItem key={q.id} value={q.id} sx={{ maxWidth: 400 }}>
                                        {q.questionText}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box>
                        <Box sx={CommonStyles.buttonFlex}>
                            <Button
                                variant="outlined"
                                sx={{
                                    alignSelf: 'end',
                                    marginRight: '10px',
                                }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={processing}
                                loadingIndicator="CREATING"
                                sx={{
                                    alignSelf: 'end',
                                }}
                                onClick={handleSubmit}
                            >
                                Create
                            </LoadingButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default NewQuestionBlockModal
