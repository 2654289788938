import { API } from 'aws-amplify'
import { UserWrapper } from './userContext'
import { ErrorSessionLapsed, getErrorMessage, getErrorMessageFromApiResult, isUnauthorisedError } from './errorUtils'
import {
    getUserById,
    getDocumentsForUser,
    getVisas,
    getDocumentTypes,
    getQuestions,
    getQuestionBlocks,
    getQuestionTopics,
    getUserAnswers,
    getVisaApplicationsByUser,
} from '../graphql/queries'
import {
    User,
    Document,
    Visa,
    DocumentType,
    Question,
    VisaApplication,
    Answer,
    QuestionBlock,
    QuestionTopic,
} from '../graphql/API'
import { GraphQLOptions, GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'

export const getApplicationTemplates = /* GraphQL */ `
    query GetApplicationTemplates {
        getApplicationTemplates {
            id
            name
            userId
            updated
        }
    }
`
    
export const getApplicationsForUser = /* GraphQL */ `
    query GetApplicationsForUser($pk: ID!) {
        getApplicationsForUser(pk: $pk) {
            id
            templateId
            templateVersion
            name
            userId
            updated
        }
    }
`

export type ApiResult<TType> = {
    Result: TType | undefined
    Error: Error | undefined
}

async function doCallApi<TType>(
    user: UserWrapper,
    queryName: string,
    query: GraphQLOptions,
    retryOnUnauthorised: boolean
): Promise<ApiResult<TType>> {
    try {
        const okToProceed = await user.checkSessionActive()
        if (!okToProceed) {
            user.signOut()
            return {
                Result: undefined,
                Error: ErrorSessionLapsed,
            }
        }

        const queryWithPermissions = {
            ...query,
            authMode: GRAPHQL_AUTH_MODE.OPENID_CONNECT,
            authToken: user.idToken.__raw,
        }

        // console.log(queryWithPermissions)

        const response = (await API.graphql(queryWithPermissions)) as GraphQLResult<any>
        if (response.data) {
            // console.log('API called successfully. Returned data: %o', response.data)
            const typedResult = response.data[queryName] as TType // TODO REVISIT
            if (typedResult) return { Result: typedResult, Error: undefined }
            else
                return {
                    Result: undefined,
                    Error: new Error('The api result was not of the expected type'),
                }
        }
        console.log('Error calling API. Returned data: %o', response.errors)
        const errorMessage = getErrorMessageFromApiResult(response)
        return { Result: undefined, Error: new Error(errorMessage) }
    } catch (error) {
        const errorMessage = getErrorMessage('calling the api', error)
        if (isUnauthorisedError(errorMessage)) {
            if (retryOnUnauthorised) {
                const renewed = await user.renewSession()
                if (renewed) return doCallApi(user, queryName, query, false)
            }
            user.signOut()
        }
        console.log(errorMessage)
        return { Result: undefined, Error: new Error(errorMessage) }
    }
}

export async function callApi<TType>(
    user: UserWrapper,
    queryName: string,
    query: GraphQLOptions
): Promise<ApiResult<TType>> {
    return doCallApi(user, queryName, query, true)
}

//=============================================================
// retrieve functions are for setting up the context data

export async function retrieveLedgeUser(user: UserWrapper, userId: string): Promise<ApiResult<User>> {
    return callApi<User>(user, 'getUserById', {
        query: getUserById,
        variables: { pk: userId },
    })
}

export async function retrieveDocuments(user: UserWrapper, userId: string): Promise<ApiResult<Array<Document>>> {
    return callApi<Array<Document>>(user, 'getDocumentsForUser', {
        query: getDocumentsForUser,
        variables: { pk: userId },
    })
}

export async function retrieveDocumentTypes(user: UserWrapper): Promise<ApiResult<Array<DocumentType>>> {
    return callApi<Array<DocumentType>>(user, 'getDocumentTypes', {
        query: getDocumentTypes,
    })
}

export async function retrieveQuestions(user: UserWrapper): Promise<ApiResult<Array<Question>>> {
    return callApi<Array<Question>>(user, 'getQuestions', {
        query: getQuestions,
    })
}

export async function retrieveQuestionTopics(user: UserWrapper): Promise<ApiResult<Array<QuestionTopic>>> {
    return callApi<Array<QuestionTopic>>(user, 'getQuestionTopics', {
        query: getQuestionTopics,
    })
}

export async function retrieveQuestionBlocks(user: UserWrapper): Promise<ApiResult<Array<QuestionBlock>>> {
    return callApi<Array<QuestionBlock>>(user, 'getQuestionBlocks', {
        query: getQuestionBlocks,
    })
}

export async function retrieveUserAnswers(user: UserWrapper, userId: string): Promise<ApiResult<Array<Answer>>> {
    return callApi<Array<Answer>>(user, 'getUserAnswers', {
        query: getUserAnswers,
        variables: { pk: userId },
    })
}

export async function retrieveVisaTypes(user: UserWrapper): Promise<ApiResult<Array<Visa>>> {
    return callApi<Array<Visa>>(user, 'getVisas', {
        query: getVisas,
    })
}

export async function retrieveVisaApplications(
    user: UserWrapper,
    userId: string
): Promise<ApiResult<Array<VisaApplication>>> {
    return callApi<Array<VisaApplication>>(user, 'getVisaApplicationsByUser', {
        query: getVisaApplicationsByUser,
        variables: { pk: userId },
    })
}
