import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { PageInfo } from './components/common/pageInfo'
import { MainWindow } from './components/mainWindow'
import { Loading } from './components/common/loading'
import { BrowserRouter } from 'react-router-dom'
import { useState, useEffect } from 'react'
import {
    retrieveLedgeUser,
    retrieveDocuments,
    retrieveDocumentTypes,
    retrieveQuestions,
    retrieveVisaTypes,
    retrieveQuestionTopics,
    retrieveQuestionBlocks,
    retrieveUserAnswers,
    retrieveVisaApplications,
} from './common/apiUtils'
import { UserContext, ContextDataInterface, UserWrapper } from './common/userContext'
import {
    Answer,
    QuestionBlock,
    QuestionTopic,
    User,
    Document,
    DocumentType,
    Question,
    Visa,
    VisaApplication,
} from './graphql/API'

function App() {
    const { user, error, getIdTokenClaims, getAccessTokenSilently, logout } = useAuth0()
    const [contextData, setContextData] = useState<ContextDataInterface>()
    const [authenticationError, setAuthenticationError] = useState(error?.message)

    useEffect(() => {
        const initialiseContext = async () => {
            if (contextData) {
                await contextData.user.checkSessionActive()
                return
            }

            const setLedgeUser = (user?: User | undefined) => {
                setContextData((current) => {
                    if (!current) return undefined
                    return { ...current, ledgeUser: user }
                })
            }

            const setDocuments = (documents: Document[]) => {
                setContextData((current) => {
                    if (!current) return
                    return {
                        ...current,
                        documents: documents,
                    }
                })
            }

            const setDocumentTypes = (documentTypes: DocumentType[]) => {
                setContextData((current) => {
                    if (!current) return
                    return {
                        ...current,
                        documentTypes: documentTypes,
                    }
                })
            }

            const setQuestions = (questions: Question[]) => {
                setContextData((current) => {
                    if (!current) return
                    return {
                        ...current,
                        questions: questions,
                    }
                })
            }

            const setQuestionTopics = (questionTopics: QuestionTopic[]) => {
                setContextData((current) => {
                    if (!current) return
                    return {
                        ...current,
                        questionTopics: questionTopics,
                    }
                })
            }

            const setQuestionBlocks = (questionBlocks: QuestionBlock[]) => {
                setContextData((current) => {
                    if (!current) return
                    return {
                        ...current,
                        questionBlocks: questionBlocks,
                    }
                })
            }

            const setAnswers = (answers: Answer[]) => {
                setContextData((current) => {
                    if (!current) return
                    return {
                        ...current,
                        answers: answers,
                    }
                })
            }

            const setVisaTypes = (visaTypes: Visa[]) => {
                setContextData((current) => {
                    if (!current) return
                    return {
                        ...current,
                        visaTypes: visaTypes,
                    }
                })
            }

            const setVisaApplications = (visaApplications: VisaApplication[]) => {
                setContextData((current) => {
                    if (!current) return
                    return {
                        ...current,
                        visaApplications: visaApplications,
                    }
                })
            }

            if (!user) {
                // Redirect to error??
                setAuthenticationError('Could not retrieve idToken!')
                return
            }

            const userWrapper = new UserWrapper(user, getAccessTokenSilently, getIdTokenClaims, logout)

            // https://auth0.com/docs/authenticate/login/configure-silent-authentication
            const error = await userWrapper.initialise()

            if (error) {
                setAuthenticationError(error.message)
                return
            }

            const result = Promise.all([
                retrieveLedgeUser(userWrapper, userWrapper.sub),
                retrieveDocuments(userWrapper, userWrapper.sub),
                retrieveDocumentTypes(userWrapper),
                retrieveQuestions(userWrapper),
                retrieveQuestionTopics(userWrapper),
                retrieveQuestionBlocks(userWrapper),
                retrieveUserAnswers(userWrapper, userWrapper.sub),
                retrieveVisaTypes(userWrapper),
                retrieveVisaApplications(userWrapper, userWrapper.sub),
            ])

            const [
                userResult,
                documentsResult,
                documentTypesResult,
                questionsResult,
                questionTopicsResult,
                questionBlocksResult,
                answersResult,
                visaTypesResult,
                visaApplicationsResult,
            ] = await result

            const newContextData: ContextDataInterface = {
                user: userWrapper,
                ledgeUser: userResult.Result,
                documents: documentsResult.Result ?? [],
                documentTypes: documentTypesResult.Result ?? [],
                questions: questionsResult.Result ?? [],
                questionTopics: questionTopicsResult.Result ?? [],
                questionBlocks: questionBlocksResult.Result ?? [],
                answers: answersResult.Result ?? [],
                visaTypes: visaTypesResult.Result ?? [],
                visaApplications: visaApplicationsResult.Result ?? [],
                setLedgeUser: setLedgeUser,
                setDocuments: setDocuments,
                setDocumentTypes: setDocumentTypes,
                setQuestions: setQuestions,
                setQuestionTopics: setQuestionTopics,
                setQuestionBlocks: setQuestionBlocks,
                setAnswers: setAnswers,
                setVisaTypes: setVisaTypes,
                setVisaApplications: setVisaApplications,
            }
            setContextData(newContextData)
        }

        if (!contextData && !authenticationError) {
            initialiseContext()
        }
    }, [])

    if (authenticationError) return <PageInfo color="error" message={authenticationError} />

    if (contextData)
        return (
            <UserContext.Provider value={contextData}>
                <BrowserRouter>
                    <MainWindow />
                </BrowserRouter>
            </UserContext.Provider>
        )

    return <Loading />
}

export default withAuthenticationRequired(App, {
    onRedirecting: () => <Loading />,
})
