import { User, UserInput } from '../../graphql/API'

export type ProfileSectionProps = {
    userInput: UserInput
    gridItemWidth: number
    setValue: (name: string, value: any) => void
}

export type InitialProfileSectionProps = {
    userInput: UserInput
    gridItemWidth: number
    mobileMode: boolean
    setValue: (name: string, value: any) => void
    updateProfile: () => void
}

export function stringToHash(string): number {
    var hash = 0

    if (string.length == 0) return hash

    for (var i = 0; i < string.length; i++) {
        var char = string.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash
    }

    return Math.abs(hash)
}

export function getUserInput(id: string, email: string, user: User | undefined): UserInput {
    if (!user || id !== user.id) {
        const newUser: UserInput = {
            id: id,
            email: email,
            phone: '',
            jobCurrentLocation: '',
            firstName: '',
            lastName: '',
            preferredName: '',
            sex: '',
            jobInterestCategory: [],
            jobInterestCategoryOther: '',
            jobPreviousExperience: '',
            jobEnglishFluency: '',
            jobAreaOfStudy: [],
            jobAreaOfStudyOther: '',
            jobDrive: '',
            jobFar: '',
            jobVisaStatus: '',
            jobVisaStatusOther: '',
            jobDays: '',
            jobTime: '',
            jobHowDidYouFindOutAboutUs: [],
            dateOfBirth: undefined,
        }
        return newUser
    }
    const existingUser: UserInput = {
        id: id,
        email: email,
        phone: user.phone,
        jobCurrentLocation: user.jobCurrentLocation,
        firstName: user.firstName,
        lastName: user.lastName,
        preferredName: user.preferredName,
        dateOfBirth: user.dateOfBirth,
        sex: user.sex,
        jobInterestCategory: user.jobInterestCategory!,
        jobInterestCategoryOther: user.jobInterestCategoryOther!,
        jobPreviousExperience: user.jobPreviousExperience!,
        jobEnglishFluency: user.jobEnglishFluency,
        jobAreaOfStudy: user.jobAreaOfStudy,
        jobAreaOfStudyOther: user.jobAreaOfStudyOther,
        jobDrive: user.jobDrive,
        jobFar: user.jobFar,
        jobVisaStatus: user.jobVisaStatus,
        jobVisaStatusOther: user.jobVisaStatusOther,
        jobDays: user.jobDays,
        jobTime: user.jobTime,
        jobHowDidYouFindOutAboutUs: user.jobHowDidYouFindOutAboutUs!,
    }
    return existingUser
}

export function getUpdatedInput(input: UserInput, name: string, value: any) {
    const updated: UserInput = {
        ...input,
        [name]: value,
    }
    return updated
}

export function checkComplete(data: Array<string>) {
    var num = 0
    data.forEach((d) => {
        if (d && d !== '') num++
    })
    return num + '/' + data.length
}

export const jobInterestCategories = [
    {
        key: 'jobCleaning',
        value: 'cleaning',
        text: 'Cleaning',
    },
    {
        key: 'jobHospitality',
        value: 'hospitality',
        text: 'Hospitality',
    },
    {
        key: 'jobFrontOfHouse',
        value: 'front of house',
        text: 'Front of house',
    },
    {
        key: 'jobKitchenHand',
        value: 'kitchen hand',
        text: 'Kitchen hand',
    },
    {
        key: 'jobBackOfHouse',
        value: 'back of house',
        text: 'Back of house',
    },
    {
        key: 'jobRemovalist',
        value: 'removalist',
        text: 'Removalist',
    },
    {
        key: 'jobPickingAndPacking',
        value: 'picking and packing',
        text: 'Picking and packing (warehouse)',
    },
    {
        key: 'jobTruckDriving',
        value: 'truck driving',
        text: 'Truck driving',
    },
    {
        key: 'jobFarmhand',
        value: 'farmhand',
        text: 'Farmhand',
    },
    {
        key: 'jobUnskilledLabour',
        value: 'unskilled labour',
        text: 'Unskilled labour',
    },
    {
        key: 'jobFlyInFlyOut',
        value: 'fly in/fly out',
        text: 'Fly in/fly out',
    },
    {
        key: 'jobManufacturingMachineOperator',
        value: 'manufacturing machine operator',
        text: 'Manufacturing machine operator',
    },
    {
        key: 'jobProfessional',
        value: 'professional',
        text: 'Professional',
    },
    {
        key: 'jobOther',
        value: 'other',
        text: 'Other',
    },
]

export const jobEnglishFluencyLevels = [
    {
        key: 'englishBeginner',
        value: 'beginner',
        text: 'Beginner',
    },
    {
        key: 'englishIntermediate',
        value: 'intermediate',
        text: 'Intermediate/Conversational',
    },
    {
        key: 'englishNative/Bilingual',
        value: 'native/bilingual',
        text: 'Native',
    },
]

export const jobAreasOfStudy = [
    {
        key: 'studyNone',
        value: 'no formal education',
        text: 'No formal education',
    },
    {
        key: 'studySciences',
        value: 'sciences',
        text: 'Sciences',
    },
    {
        key: 'studyEngineering',
        value: 'engineering',
        text: 'Engineering',
    },
    {
        key: 'studyArtsAndHumanities',
        value: 'arts and humanities',
        text: 'Arts and Humanities',
    },
    {
        key: 'studyLaw',
        value: 'law',
        text: 'Law',
    },
    {
        key: 'studyHealthAndMedicine',
        value: 'health and medicine',
        text: 'Health and Medicine',
    },
    {
        key: 'studyItAndTech',
        value: 'it and tech',
        text: 'IT and Tech',
    },
    {
        key: 'studyOther',
        value: 'other',
        text: 'Other',
    },
]

export const jobDrivingDistances = [
    {
        key: 'close',
        value: 'close',
        text: 'Short Distances (immediate/neighbouring suburbs)',
    },
    {
        key: 'medium',
        value: 'medium',
        text: 'Medium Distance (~2-3 suburbs away)',
    },
    {
        key: 'long',
        value: 'long',
        text: 'Long Distance (4+ suburbs away)',
    },
    {
        key: 'intercity',
        value: 'intercity',
        text: 'Intercity (Outside of the city, neighbouring towns)',
    },
]

export const jobVisaStatusList = [
    {
        key: 'visaNoVisa',
        value: 'no visa',
        text: 'No visa',
    },
    {
        key: 'visaStudent',
        value: 'student visa',
        text: 'Student Visa',
    },
    {
        key: 'visaWorking',
        value: 'working visa',
        text: 'Working Visa',
    },
    {
        key: 'visaBridging',
        value: 'bridging partner visa',
        text: 'Bridging Partner Visa',
    },
    {
        key: 'visaTourist',
        value: 'tourist visa',
        text: 'Tourist Visa',
    },
    {
        key: 'visaOther',
        value: 'other',
        text: 'Other',
    },
]

export const jobWorkDaysList = [
    {
        key: 'days0',
        value: '0',
        text: '0',
    },
    {
        key: 'days1',
        value: '1',
        text: '1',
    },
    {
        key: 'days2',
        value: '2',
        text: '2',
    },
    {
        key: 'days3',
        value: '3',
        text: '3',
    },
    {
        key: 'days4',
        value: '4',
        text: '4',
    },
    {
        key: 'days5',
        value: '5',
        text: '5',
    },
    {
        key: 'days6',
        value: '6',
        text: '6',
    },
    {
        key: 'days7',
        value: '7',
        text: '7',
    },
]

export const jobWorkTimesList = [
    {
        key: 'Morning',
        value: 'morning',
        text: 'Morning',
    },
    {
        key: 'Afternoon',
        value: 'afternoon',
        text: 'Afternoon',
    },
    {
        key: 'Evening',
        value: 'evening',
        text: 'Evening',
    },
    {
        key: 'Full day',
        value: 'full day',
        text: 'Full day',
    },
]

export const profileGenderList = [
    {
        key: 'Male',
        value: 'Male',
        text: 'Male',
    },
    {
        key: 'Female',
        value: 'Female',
        text: 'Female',
    },
    {
        key: 'Other',
        value: 'Other',
        text: 'Other',
    },
]

export const jobHowDidYouFindOutAboutUsList = [
    {
        key: 'Facebook',
        value: 'Facebook',
        text: 'Facebook',
    },
    {
        key: 'Instagram',
        value: 'Instagram',
        text: 'Instagram',
    },
    {
        key: 'WhatsApp',
        value: 'WhatsApp',
        text: 'WhatsApp',
    },
    {
        key: 'Word of mouth',
        value: 'Word of mouth',
        text: 'Word of mouth',
    },
    {
        key: 'Search engine (Google search)',
        value: 'Search engine (Google search)',
        text: 'Search engine (Google search)',
    },
    {
        key: 'Other',
        value: 'Other',
        text: 'Other',
    },
]
