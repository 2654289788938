import { useState, useContext, useEffect } from 'react'
import { Box, Grid, TextField, Typography, CircularProgress, Backdrop } from '@mui/material'

import { UserContext } from '../../common/userContext'

import { User, UserInput } from '../../graphql/API'
import Select, { SelectChangeEvent } from '@mui/material/Select/Select'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import { PageHeader } from '../common/pageHeader'
import {
    jobAreasOfStudy,
    jobDrivingDistances,
    jobEnglishFluencyLevels,
    jobInterestCategories,
    jobVisaStatusList,
    jobWorkDaysList,
    jobWorkTimesList,
} from './profileCommon'
import { Loading } from '../common/loading'
import { callApi } from '../../common/apiUtils'
import { updateUser } from '../../graphql/mutations'
import { PageInfo } from '../common/pageInfo'
import { LoadingButton } from '@mui/lab'

export type ApplicationSectionProps = {
    userInput: UserInput
}

export function ProfileJobsDetails() {
    const contextData = useContext(UserContext)

    const [pageInfo, setPageInfo] = useState({ message: '', color: '' })

    const [jobInterestCategory, setJobInterestCategory] = useState<Array<String>>([])
    const [jobInterestCategoryOther, setJobInterestCategoryOther] = useState<String>()
    const [jobPreviousExperience, setJobPreviousExperience] = useState<String>('')
    const [jobEnglishFluency, setJobEnglishFluency] = useState<String>('')
    const [jobAreaOfStudy, setJobAreaOfStudy] = useState<Array<String>>([])
    const [jobAreaOfStudyOther, setJobAreaOfStudyOther] = useState<String>('')
    const [jobDrive, setJobDrive] = useState<String>('')
    const [jobFar, setJobFar] = useState<String>('')
    const [jobVisaStatus, setJobVisaStatus] = useState<String>('')
    const [jobVisaStatusOther, setJobVisaStatusOther] = useState<String>('')
    const [jobDays, setJobDays] = useState<String>('')
    const [jobTime, setJobTime] = useState<String>('')
    const [origSourceJob, setOrigSourceJob] = useState<Boolean>()
    const [origSourceJobDate, setOrigSourceJobDate] = useState<String>('')

    const [loaded, setLoaded] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)

    const gridItemWidth = 6

    const handleSelectJobs = (event: SelectChangeEvent<typeof jobInterestCategory>) => {
        const {
            target: { value },
        } = event
        setJobInterestCategory(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const handleSelectAreaOfStudy = (event: SelectChangeEvent<typeof jobAreaOfStudy>) => {
        const {
            target: { value },
        } = event
        setJobAreaOfStudy(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const handleSubmit = async () => {
        setProcessing(true)
        let categories = Array<String>()
        if (jobInterestCategory)
            jobInterestCategory.forEach((jic) => {
                categories.push(jic!)
            })

        let studies = Array<String>()
        if (jobAreaOfStudy)
            jobAreaOfStudy.forEach((jaos) => {
                studies.push(jaos!)
            })
        let item = {
            id: contextData.ledgeUser!.id,
            jobInterestCategory: categories,
            jobInterestCategoryOther: jobInterestCategoryOther,
            jobPreviousExperience: jobPreviousExperience,
            jobEnglishFluency: jobEnglishFluency,
            jobAreaOfStudy: studies,
            jobAreaOfStudyOther: jobAreaOfStudyOther,
            jobDrive: jobDrive,
            jobFar: jobFar,
            jobVisaStatus: jobVisaStatus,
            jobVisaStatusOther: jobVisaStatusOther,
            jobDays: jobDays,
            jobTime: jobTime,
            sourceJobForMe: origSourceJob,
            sourceJobChangeDate: origSourceJobDate
        }

        if (jobInterestCategory.length > 0 || jobInterestCategoryOther) {
            item.sourceJobForMe = true
        } else item.sourceJobForMe = false

        if (origSourceJob === false && item.sourceJobForMe === true) {
            const d = new Date()
            setOrigSourceJob(true)
            item.sourceJobChangeDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
        }

        // console.log(item)

        const modifyUser = await callApi<User>(contextData.user, 'updateUser', {
            query: updateUser,
            variables: { item: item },
        })

        if (modifyUser.Result) {
            setPageInfo({ message: 'Your job details have been updated!', color: 'primary.light' })
            contextData.setLedgeUser(modifyUser.Result)
        }
        setProcessing(false)
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                // initially make page unloaded, get the visas then set loaded
                setLoaded(false)

                const currentUser = { ...contextData.ledgeUser }

                if (currentUser) {
                    let cats = Array<String>()
                    if (currentUser.jobInterestCategory)
                        currentUser.jobInterestCategory.forEach((jic) => {
                            cats.push(jic!)
                        })
                    setJobInterestCategory(cats)

                    setJobInterestCategoryOther(currentUser.jobInterestCategoryOther!)
                    setJobPreviousExperience(currentUser.jobPreviousExperience!)
                    setJobEnglishFluency(currentUser.jobEnglishFluency!)

                    let studies = Array<String>()
                    if (currentUser.jobAreaOfStudy)
                        currentUser.jobAreaOfStudy.forEach((jaos) => {
                            studies.push(jaos!)
                        })
                    setJobAreaOfStudy(studies)
                    setJobAreaOfStudyOther(currentUser.jobAreaOfStudyOther!)
                    setJobDrive(currentUser.jobDrive!)
                    setJobFar(currentUser.jobFar!)
                    setJobVisaStatus(currentUser.jobVisaStatus!)
                    setJobVisaStatusOther(currentUser.jobVisaStatusOther!)
                    setJobDays(currentUser.jobDays!)
                    setJobTime(currentUser.jobTime!)
                    setOrigSourceJob(currentUser.sourceJobForMe ? currentUser.sourceJobForMe : false)
                    setOrigSourceJobDate(currentUser.sourceJobChangeDate ? currentUser.sourceJobChangeDate : '')
                }
            } finally {
                setLoaded(true)
            }
        }

        initialise()
    }, [])

    if (loaded)
        return (
            <Box
                sx={{
                    boxSizing: 'border-box',
                    borderRadius: '5px',
                    mb: 2,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <PageHeader title="My Jobs Details" />
                        <Typography variant="body1" color={'secondary'}>
                            Enter some information about your previous employment history here!
                        </Typography>
                    </Box>
                    <Box sx={{ paddingRight: 2, textAlign: 'end' }}>
                        <LoadingButton
                            variant="contained"
                            loading={processing}
                            loadingIndicator="SAVING"
                            onClick={handleSubmit}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Box>

                <PageInfo {...pageInfo} sx={{ mt: 1 }} />

                <Grid container spacing={2} mb={4} mt={2}>
                    <Grid item xs={gridItemWidth}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            What roles/job titles are you looking for? Select all that apply:
                        </Typography>
                        <Select
                            id="jobInterestCategory"
                            name="jobInterestCategory"
                            value={jobInterestCategory}
                            multiple
                            onChange={handleSelectJobs}
                            sx={{ backgroundColor: 'white' }}
                            size={'small'}
                            fullWidth
                        >
                            {jobInterestCategories.map((jic) => (
                                <MenuItem key={jic.key} value={jic.value}>
                                    {jic.text}
                                </MenuItem>
                            ))}
                        </Select>
                        {jobInterestCategory?.includes('other') && (
                            <TextField
                                id="jobInterestCategoryOther"
                                name="jobInterestCategoryOther"
                                value={jobInterestCategoryOther}
                                onChange={(e) => setJobInterestCategoryOther(e.target.value)}
                                placeholder="Please enter role here"
                                autoFocus
                                fullWidth
                                size="small"
                                sx={{ backgroundColor: 'white', marginTop: 2 }}
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={4}>
                    <Grid item xs={gridItemWidth * 2} marginBottom={-2}>
                        <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                            Employment:
                        </Typography>
                    </Grid>
                    <Grid item xs={gridItemWidth}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            What was your previous experience?
                        </Typography>
                        <TextField
                            autoFocus
                            fullWidth
                            size="small"
                            name="jobPreviousExperience"
                            value={jobPreviousExperience}
                            onChange={(e) => setJobPreviousExperience(e.target.value)}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={gridItemWidth}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            English fluency:
                        </Typography>

                        <Select
                            id="jobEnglishFluency"
                            name="jobEnglishFluency"
                            value={jobEnglishFluency}
                            onChange={(e) => setJobEnglishFluency(e.target.value)}
                            size={'small'}
                            fullWidth
                            sx={{ backgroundColor: 'white' }}
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            {jobEnglishFluencyLevels.map((jefl) => (
                                <MenuItem key={jefl.key} value={jefl.value}>
                                    {jefl.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={gridItemWidth}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            What is/was your area of study?
                        </Typography>
                        <Select
                            id="jobAreaOfStudy"
                            name="jobAreaOfStudy"
                            value={jobAreaOfStudy}
                            multiple
                            onChange={handleSelectAreaOfStudy}
                            sx={{ backgroundColor: 'white' }}
                            size={'small'}
                            fullWidth
                        >
                            {jobAreasOfStudy.map((jaos) => (
                                <MenuItem key={jaos.key} value={jaos.value}>
                                    {jaos.text}
                                </MenuItem>
                            ))}
                        </Select>
                        {jobAreaOfStudy?.includes('other') && (
                            <TextField
                                id="jobAreaOfStudyOther"
                                name="jobAreaOfStudyOther"
                                value={jobAreaOfStudyOther}
                                onChange={(e) => setJobAreaOfStudyOther(e.target.value)}
                                sx={{ backgroundColor: 'white', marginTop: 2 }}
                                fullWidth
                                size="small"
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={4}>
                    <Grid item xs={gridItemWidth * 2} marginBottom={-2}>
                        <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                            Transport:
                        </Typography>
                    </Grid>
                    <Grid item xs={gridItemWidth}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            Can you drive to work?
                        </Typography>
                        <Select
                            fullWidth
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            id="jobDrive"
                            name="jobDrive"
                            value={jobDrive}
                            onChange={(e) => setJobDrive(e.target.value)}
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            <MenuItem key={'Yes'} value={'yes'}>
                                Yes
                            </MenuItem>
                            <MenuItem key={'No'} value={'no'}>
                                No
                            </MenuItem>
                        </Select>
                    </Grid>
                    {jobDrive === 'yes' && (
                        <Grid item xs={gridItemWidth}>
                            <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                                How far are you able to drive for work?
                            </Typography>
                            <Select
                                id="jobFar"
                                name="jobFar"
                                value={jobFar}
                                onChange={(e) => setJobFar(e.target.value)}
                                fullWidth
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                            >
                                <MenuItem key={''} value={''}>
                                    -- please select a value --
                                </MenuItem>
                                {jobDrivingDistances.map((jdd) => (
                                    <MenuItem key={jdd.key} value={jdd.value}>
                                        {jdd.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                </Grid>

                <Grid container spacing={2} mb={4}>
                    <Grid item xs={gridItemWidth * 2} marginBottom={-2}>
                        <Typography variant="h4" fontWeight={400} color={'primary.light'}>
                            Availability:
                        </Typography>
                    </Grid>
                    <Grid item xs={gridItemWidth}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            What is your current visa type?
                        </Typography>
                        <Select
                            fullWidth
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            id="jobVisaStatus"
                            name="jobVisaStatus"
                            value={jobVisaStatus}
                            onChange={(e) => setJobVisaStatus(e.target.value)}
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            {jobVisaStatusList.map((jvs) => (
                                <MenuItem key={jvs.key} value={jvs.value}>
                                    {jvs.text}
                                </MenuItem>
                            ))}
                        </Select>
                        {jobVisaStatus === 'other' && (
                            <TextField
                                id="jobVisaStatusOther"
                                name="jobVisaStatusOther"
                                value={jobVisaStatusOther}
                                onChange={(e) => setJobVisaStatusOther(e.target.value)}
                                sx={{ backgroundColor: 'white', marginTop: 2 }}
                                fullWidth
                                size="small"
                            />
                        )}
                    </Grid>
                    <Grid item xs={gridItemWidth}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            How many days are you willing to work per week?
                        </Typography>
                        <Select
                            fullWidth
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            id="jobDays"
                            name="jobDays"
                            value={jobDays}
                            onChange={(e) => setJobDays(e.target.value)}
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            {jobWorkDaysList.map((jwdl) => (
                                <MenuItem key={jwdl.key} value={jwdl.value}>
                                    {jwdl.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={gridItemWidth}>
                        <Typography variant="body2" mb={0.5} color={'secondary.light'}>
                            What hours are you willing to work?
                        </Typography>
                        <Select
                            id="jobTime"
                            name="jobTime"
                            value={jobTime}
                            onChange={(e) => setJobTime(e.target.value)}
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        >
                            <MenuItem key={''} value={''}>
                                -- please select a value --
                            </MenuItem>
                            {jobWorkTimesList.map((jwtl) => (
                                <MenuItem key={jwtl.key} value={jwtl.value}>
                                    {jwtl.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    return <Loading></Loading>
}
