import { useState, useContext, useEffect } from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import { UserContext } from '../../../common/userContext'
import { Document, DocumentType, Visa, VisaApplication } from '../../../graphql/API'
import { theme, CommonStyles } from '../../../assets/theme'
import Chevron from '../../../assets/chevron.png'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

type ResponseProps = {
    currentVisa: Visa
    category: string
    currentApplication: VisaApplication
    currentApplicationDocuments: Array<Document>
    updateCurrentApplication: Function
}

export function ApplicationDataAccordion(props: ResponseProps) {
    const contextData = useContext(UserContext)

    const [show, setShow] = useState<boolean>(true)

    const [documentTypes, setDocumentTypes] = useState<Array<DocumentType>>()

    const toggleAccordion = () => {
        setShow(!show)
    }

    const checkDocumentPresent = (d: DocumentType) => {
        const doc = contextData.documents.find(
            (dd) =>
                dd.documentCategory === d.documentCategory &&
                dd.documentType === d.documentType &&
                dd.userId === contextData.user.sub
        )
        if (doc)
            return (
                <Box style={CommonStyles.centerFlex}>
                    <Tooltip title={`Current document selected - ${doc.documentName}`} arrow>
                        <CheckCircleIcon color="success" />
                    </Tooltip>
                </Box>
            )
        return (
            <Box style={CommonStyles.centerFlex}>
                <Tooltip title={`No document uploaded - please upload on the responses page!`} arrow>
                    <ErrorIcon color="error" />
                </Tooltip>
            </Box>
        )
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                if (contextData.documentTypes) {
                    var dtA = Array<DocumentType>()

                    props.currentVisa.documentTypes?.forEach((dr) => {
                        const validDocTypes = contextData.documentTypes.filter(
                            (d) => d.documentCategory === props.category
                        )
                        const toPush = validDocTypes.find((d) => d.id === dr)
                        if (toPush) dtA.push(toPush)
                    })
                    setDocumentTypes(dtA)
                }
            } catch (error) {
                console.log(error)
            }
        }

        initialise()
    }, [])

    // Tab stuff =================================================================

    // =======================================================================================
    if (
        contextData &&
        props.currentVisa &&
        documentTypes &&
        contextData.documents &&
        props.currentApplication &&
        documentTypes.length >= 1
    )
        return (
            <Box sx={CommonStyles.responseAccordionStyle}>
                <Box sx={CommonStyles.responseAccordionHeader} onClick={toggleAccordion}>
                    <Typography variant="h3" color={theme.palette.secondary.light} marginBottom={0}>
                        {props.category}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            component="img"
                            sx={{
                                width: '16px',
                                height: '9px',
                                mr: 0.5,
                                ml: 2.5,
                                rotate: show ? '0deg' : '180deg',
                                transition: '0.3s',
                            }}
                            src={Chevron}
                            alt="logo"
                        />
                    </Box>
                </Box>

                {show && (
                    <Box>
                        {documentTypes!.map((d) => {
                            return (
                                <Box sx={CommonStyles.responseAccordionCell}>
                                    <Typography variant="body1" color="primary">
                                        {d.documentType}
                                    </Typography>
                                    {checkDocumentPresent(d)}
                                </Box>
                            )
                        })}
                    </Box>
                )}
            </Box>
        )
    return <></>
}
