import { Document, User } from '../graphql/API'
import { getDocumentsForUser } from '../graphql/queries'
import { callApi } from './apiUtils'
import { downloadAndZip } from './documentUtils'
import { ContextDataInterface } from './userContext'
// import * as XLSX from "xlsx"
var XLSX = require('xlsx')

export const convertDOB = (dob: string) => {
    return dob.slice(8, 10) + '/' + dob.slice(5, 7) + '/' + dob.slice(0, 4)
}

export const convertJoined = (joined: number) => {
    const dd = new Date(joined * 1000)
    return (
        dd.getDay().toString().padStart(2, '0') +
        '/' +
        (dd.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        dd.getFullYear()
    )
}

export const concatMulti = (a: (string | null)[] | null | undefined) => {
    if (a) {
        if (a.length < 1 || !a) return ''
        let toReturn = a.join('/')
        return toReturn
    }
}

export const exportSingleUserData = async (user: User) => {
    if (user) {
        let userData = Array<Array<string>>()

        userData.push([
            'First Name',
            'Last Name',
            'Email',
            'Mobile',
            'Lead Source',
            'Area of study',
            'Preferred Name',
            'What was your previous role title?',
            'Can you drive to work?',
            'What is your current visa status',
            'How far you able to drive for work',
            'English Fluency',
            'Position looking for',
            'Position hiring for',
            'Are you looking for a job or to hire',
            'Date of birth',
            'How many days are you willing to work per week',
            'What roles/job titles are you looking for',
            'How did you find out about us? Select all that app',
            'What hours are you willing to work?',
            'Source job for me',
            'Source job since...',
        ])

        userData.push([
            user.firstName,
            user.lastName,
            user.email,
            user.phone,
            'Globoz Portal',
            concatMulti(user.jobAreaOfStudy!)! ? concatMulti(user.jobAreaOfStudy!)! : user.jobAreaOfStudyOther!,
            user.preferredName!,
            user.jobPreviousExperience!,
            user.jobDrive!,
            user.jobVisaStatusOther! ? user.jobVisaStatusOther! : user.jobVisaStatus!,
            user.jobFar!,
            user.jobEnglishFluency!,
            '',
            '',
            '',
            convertDOB(user.dateOfBirth!),
            user.jobDays!,
            concatMulti(user.jobInterestCategory)!
                ? concatMulti(user.jobInterestCategory)!
                : user.jobInterestCategoryOther!,
            concatMulti(user.jobHowDidYouFindOutAboutUs)! ? concatMulti(user.jobHowDidYouFindOutAboutUs)! : '',
            user.jobTime!,
            user.sourceJobForMe === true ? 'yes' : 'no',
            user.sourceJobChangeDate ? user.sourceJobChangeDate : '',
        ])
        const dateobj = new Date()
        const worksheet = XLSX.utils.aoa_to_sheet(userData)
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [
                [
                    'First Name',
                    'Last Name',
                    'Email',
                    'Mobile',
                    'Lead Source',
                    'Area of study',
                    'Preferred Name',
                    'What was your previous role title?',
                    'Can you drive to work?',
                    'What is your current visa status',
                    'What is your current location?',
                    'How far you able to drive for work',
                    'English Fluency',
                    'Position looking for',
                    'Position hiring for',
                    'Are you looking for a job or to hire',
                    'Date of birth',
                    'How many days are you willing to work per week',
                    'What roles/job titles are you looking for',
                    'How did you find out about us? Select all that app',
                    'What hours are you willing to work?',
                    'Source job for me',
                    'Source job since...',
                ],
            ],
            { origin: 'A1' }
        )
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'User Data')
        XLSX.writeFile(
            workbook,
            `Globoz-UserData-${dateobj.getDate()}-${dateobj.getMonth()}-${dateobj.getFullYear()}.csv`,
            { compression: true }
        )
    }
}

export const exportSingleUserMainDocs = async (user: User, contextData: ContextDataInterface) => {
    let docsToExport = Array<Document>()

    const getTheDocuments = await callApi<Array<Document>>(contextData.user, 'getDocumentsForUser', {
        query: getDocumentsForUser,
        variables: { pk: user.id },
    })

    if (getTheDocuments.Result) {
        const docs = getTheDocuments.Result
        for (const doc of docs) {
            if (
                doc.documentType === 'Valid Passport' ||
                doc.documentType === 'Cover Letter' ||
                doc.documentType === 'Curriculum Vitae' ||
                doc.documentType === 'Current Visa'||
                doc.documentType === 'Australian Police Check'
            ) {
                docsToExport.push(doc)
            }
        }
    }

    if (docsToExport!.length > 0) {
        await downloadAndZip(contextData, docsToExport!, user.email)
    } else {
        alert('No documents of type (Valid Passport, Curriculum Vitae, Cover Letter, Current Visa) found!')
    }
}

export const exportUserData = async (data: User[]) => {
    let userData = Array<Array<string>>()

    userData.push([
        'First Name',
        'Last Name',
        'Email',
        'Mobile',
        'Lead Source',
        'Area of study',
        'Preferred Name',
        'What was your previous role title?',
        'Can you drive to work?',
        'What is your current visa status',
        'What is your current location?',
        'How far you able to drive for work',
        'English Fluency',
        'Position looking for',
        'Position hiring for',
        'Are you looking for a job or to hire',
        'Date of birth',
        'How many days are you willing to work per week',
        'What roles/job titles are you looking for',
        'How did you find out about us? Select all that app',
        'What hours are you willing to work?',
        'Source job for me',
        'Source job since...',
        'Documents Status',
    ])
    data.forEach((u) => {
        userData.push([
            u.firstName,
            u.lastName,
            u.email,
            u.phone,
            'Globoz Portal',
            concatMulti(u.jobAreaOfStudy!)! ? concatMulti(u.jobAreaOfStudy!)! : u.jobAreaOfStudyOther!,
            u.preferredName!,
            u.jobPreviousExperience!,
            u.jobDrive!,
            u.jobVisaStatusOther! ? u.jobVisaStatusOther! : u.jobVisaStatus!,
            u.jobCurrentLocation!,
            u.jobFar!,
            u.jobEnglishFluency!,
            '',
            '',
            '',
            convertDOB(u.dateOfBirth!),
            u.jobDays!,
            concatMulti(u.jobInterestCategory)! ? concatMulti(u.jobInterestCategory)! : u.jobInterestCategoryOther!,
            concatMulti(u.jobHowDidYouFindOutAboutUs)! ? concatMulti(u.jobHowDidYouFindOutAboutUs)! : '',
            u.jobTime!,
            u.sourceJobForMe === true ? 'yes' : 'no',
            u.sourceJobChangeDate ? u.sourceJobChangeDate : '',
            u.status ?? '',
        ])
    })
    const dateobj = new Date()
    const worksheet = XLSX.utils.aoa_to_sheet(userData)
    XLSX.utils.sheet_add_aoa(
        worksheet,
        [
            [
                'First Name',
                'Last Name',
                'Email',
                'Mobile',
                'Lead Source',
                'Area of study',
                'Preferred Name',
                'What was your previous role title?',
                'Can you drive to work?',
                'What is your current visa status',
                'What is your current location?',
                'How far you able to drive for work',
                'English Fluency',
                'Position looking for',
                'Position hiring for',
                'Are you looking for a job or to hire',
                'Date of birth',
                'How many days are you willing to work per week',
                'What roles/job titles are you looking for',
                'How did you find out about us? Select all that app',
                'What hours are you willing to work?',
                'Source job for me',
                'Source job since...',
            ],
        ],
        { origin: 'A1' }
    )
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'User Data')
    XLSX.writeFile(
        workbook,
        `Globoz-UserData-${dateobj.getDate()}-${dateobj.getMonth()}-${dateobj.getFullYear()}.csv`,
        { compression: true }
    )
}
