import { useState, useContext, useEffect } from 'react'
import { Box, Typography, TextField, Select, MenuItem, FormControl, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { UserContext } from '../../common/userContext'

import { callApi } from '../../common/apiUtils'
import { modifyQuestionBlock, modifyQuestionTopic } from '../../graphql/mutations'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { questionCategories } from './questionCommon'
import { LoadingButton } from '@mui/lab'
import { Loading } from '../common/loading'
import { QuestionBlock, QuestionTopic } from '../../graphql/API'
import { SelectChangeEvent } from '@mui/material/Select'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { CommonStyles } from '../../assets/theme'
import RedDivider from '../common/redDivider'

export default function QuestionTopicPage() {
    const contextData = useContext(UserContext)

    const [loaded, setLoaded] = useState<boolean>(false)
    const [current, setCurrent] = useState<QuestionTopic>()
    const [category, setCategory] = useState<String>('')
    const [title, setTitle] = useState<String>('')
    const [subtext, setSubtext] = useState<String>('')
    const [topicOrder, setTopicOrder] = useState<number>(99)
    const [questionBlocks, setQuestionBlocks] = useState<Array<String | null>>([])
    const [questionBlocksList, setQuestionBlocksList] = useState<Array<QuestionBlock>>([])
    const [tags, setTags] = useState<Array<String | null>>([])

    const [newName, setNewName] = useState<string>('')
    const [editingNewName, setEditingNewName] = useState<boolean>(false)

    const [processing, setProcessing] = useState<boolean>(false)

    const { id } = useParams()

    const doChange = async (val: number, bb: QuestionBlock) => {
        let blockData = { ...bb, order: val }
        const modBlock = await callApi<QuestionBlock>(contextData.user, 'modifyQuestionBlock', {
            query: modifyQuestionBlock,
            variables: { item: blockData },
        })

        let newList = [...questionBlocksList]
        newList[newList.findIndex((bb) => bb.id === blockData.id)!] = modBlock.Result!

        setQuestionBlocksList(newList)
    }

    const handleSubmit = async () => {
        setProcessing(true)

        const item = {
            id: current?.id,
            category: category,
            title: title,
            subtext: subtext,
            order: topicOrder,
            questionBlocks: questionBlocks,
            tags: tags,
        }

        const modQuestionTopic = await callApi<QuestionTopic>(contextData.user, 'modifyQuestionTopic', {
            query: modifyQuestionTopic,
            variables: { item: item },
        })

        if (modQuestionTopic.Result) {
            const r = modQuestionTopic.Result
            setCurrent(r)
            setTitle(r.title)
            setSubtext(r.subtext!)
            setTopicOrder(r.order!)
            setQuestionBlocks(r.questionBlocks!)
            if (r.questionBlocks) {
                const bl = Array<QuestionBlock>()
                r.questionBlocks.forEach((qbid) => {
                    const findBlock = contextData.questionBlocks.find((bb) => bb.id === qbid)
                    if (findBlock) bl.push(findBlock)
                })
                setQuestionBlocksList(bl)
            }
            setTags(r.tags!)
            const f = contextData.questionTopics.filter((cdqt) => cdqt.id !== r.id)
            contextData.setQuestionTopics([...f, r])
        }
        setProcessing(false)
    }

    const saveName = async () => {
        setProcessing(true)
        const q = await callApi<QuestionTopic>(contextData.user, 'modifyQuestionTopic', {
            query: modifyQuestionTopic,
            variables: { item: { ...current, title: newName } },
        })
        if (q.Result) {
            setTitle(newName)
            setCurrent(q.Result)
        }

        contextData.setQuestionTopics([...contextData.questionTopics!, q.Result!])
        setEditingNewName(false)
        setProcessing(false)
    }

    const handleSelectQuestionBlocks = (event: SelectChangeEvent<typeof questionBlocks>) => {
        const {
            target: { value },
        } = event
        setQuestionBlocks(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const handleSelectTags = (event: SelectChangeEvent<typeof tags>) => {
        const {
            target: { value },
        } = event
        setTags(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                if (id) {
                    // initially make page unloaded, get the visas then set loaded
                    setLoaded(false)

                    const current = contextData.questionTopics.filter((qt) => qt.id === id)[0]!
                    if (current) {
                        setCurrent(current)
                        setCategory(current.category)
                        setTitle(current.title)
                        setSubtext(current.subtext!)
                        setTopicOrder(current.order! ? current.order : 99)
                        setQuestionBlocks(current.questionBlocks!)
                        setTags(current.tags ? current.tags : [])

                        if (current.questionBlocks) {
                            const bl = Array<QuestionBlock>()
                            current.questionBlocks.forEach((qbid) => {
                                const findBlock = contextData.questionBlocks.find((bb) => bb.id === qbid)
                                if (findBlock) bl.push(findBlock)
                            })
                            setQuestionBlocksList(bl)
                        }
                    }
                }
            } finally {
                setLoaded(true)
            }
        }

        initialise()
    }, [])
    if (loaded && current && questionBlocksList)
        return (
            <Box maxWidth={'70vw'}>
                <Box marginBottom={2}>
                    {editingNewName && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                onChange={(e) => setNewName(e.target.value)}
                                size="small"
                                variant="standard"
                                sx={{ width: '400px' }}
                            />

                            <Box onClick={() => setEditingNewName(false)} sx={{ cursor: 'pointer', marginLeft: 2 }}>
                                <CloseIcon />
                            </Box>

                            <Box onClick={saveName} sx={{ cursor: 'pointer', marginLeft: 1 }}>
                                <DoneIcon />
                            </Box>
                        </Box>
                    )}
                    {!editingNewName && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h1" marginBottom={0}>
                                Editing {current.title}
                            </Typography>
                            <Box
                                sx={{ cursor: 'pointer', marginLeft: 2 }}
                                onClick={() => setEditingNewName(!editingNewName)}
                            >
                                <EditIcon />
                            </Box>
                        </Box>
                    )}
                </Box>

                <FormControl sx={{ marginBottom: 2 }} fullWidth>
                    <Typography variant="h4">Select topic category</Typography>
                    <Select
                        id="setCategory"
                        name="setCategory"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        size="small"
                        sx={{ background: '#fff' }}
                    >
                        <MenuItem key={''} value={''}>
                            - please select a type -
                        </MenuItem>
                        {questionCategories.map((qc) => (
                            <MenuItem key={qc} value={qc.toLowerCase()}>
                                {qc}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl>
                    <Typography variant="h4">Set topic appearance order</Typography>
                    <TextField
                        id="order"
                        name="order"
                        type="number"
                        value={topicOrder}
                        onChange={(e) => setTopicOrder(parseInt(e.target.value))}
                        size="small"
                        fullWidth
                        sx={{ marginBottom: 1 }}
                    />
                </FormControl>

                <FormControl sx={{ marginBottom: 2 }} fullWidth>
                    <Typography variant="h4">Tags assigned:</Typography>
                    <Select
                        id="tags"
                        name="tags"
                        multiple
                        value={tags}
                        onChange={handleSelectTags}
                        size="small"
                        sx={{ background: '#fff' }}
                    >
                        {contextData.visaTypes.map((vt) => (
                            <MenuItem key={vt.name} value={vt.name}>
                                {vt.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <RedDivider />
                <FormControl sx={{ marginBottom: 2, marginTop: 2 }} fullWidth>
                    <Typography variant="h4">Block Subtext:</Typography>
                    <TextField
                        id="subtext"
                        name="subtext"
                        value={subtext}
                        onChange={(e) => setSubtext(e.target.value)}
                        type="number"
                        size="small"
                        multiline
                        minRows={4}
                    />
                </FormControl>
                <RedDivider />
                <FormControl sx={{ marginBottom: 4, marginTop: 2 }} fullWidth>
                    <Typography variant="h4">Question Blocks assigned:</Typography>
                    <Select
                        id="questionblocks"
                        name="questionblocks"
                        multiple
                        value={questionBlocks}
                        onChange={handleSelectQuestionBlocks}
                        size="small"
                        sx={{ background: '#fff' }}
                    >
                        {contextData.questionBlocks.map((d) => (
                            <MenuItem key={d.id} value={d.id}>
                                {d.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {questionBlocksList.length > 0 && (
                    <FormControl sx={{ marginBottom: 3 }} fullWidth>
                        <Typography variant="h4">Block Order</Typography>
                        {questionBlocksList.map((bb, i) => (
                            <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                <Typography sx={{ maxWidth: '70%' }}>{bb.title}</Typography>
                                <TextField
                                    id={bb.id}
                                    name={bb.id}
                                    type="number"
                                    onChange={(e) => doChange(parseInt(e.target.value), bb)}
                                    defaultValue={bb.order}
                                    size="small"
                                    sx={{ height: '100%' }}
                                />
                            </Box>
                        ))}
                    </FormControl>
                )}

                <Box>
                    <Box sx={CommonStyles.buttonFlex}>
                        <Button
                            variant="outlined"
                            sx={{
                                alignSelf: 'end',
                                marginRight: '10px',
                            }}
                            component={Link}
                            to="/questiondashboard"
                        >
                            Close
                        </Button>
                        <LoadingButton
                            variant="contained"
                            loading={processing}
                            loadingIndicator="SAVING!"
                            sx={{
                                alignSelf: 'end',
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Box>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    return <Loading />
}
