import { useState, useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { UserContext } from '../../common/userContext'
import { callApi } from '../../common/apiUtils'
import { useParams } from 'react-router-dom'
import { CommonStyles, theme } from '../../assets/theme'
import { ApplicationDataAccordion } from './accordions/applicationDataAccordion'
import { Loading } from '../common/loading'
import TextField from '@mui/material/TextField/TextField'
import { modifyVisaApplication } from '../../graphql/mutations'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import Chevron from '../../assets/chevron.png'


import CloseIcon from '@mui/icons-material/Close'
import { Answer, Question, Document, Visa, VisaApplication, QuestionTopic, QuestionBlock } from '../../graphql/API'
import { getUserAnswers } from '../../graphql/queries'
import { LoadingButton } from '@mui/lab'
import { exportDocsAndQuestions } from '../../common/documentUtils'

// import * as XLSX from "xlsx"

export default function ApplicationsPage() {
    var XLSX = require("xlsx");
    const contextData = useContext(UserContext)


    const [loaded, setLoaded] = useState<boolean>(false)
    const [currentApplication, setCurrentApplication] = useState<VisaApplication>()
    const [currentApplicationDocuments, setCurrentApplicationDocuments] = useState<Array<Document>>()
    const [currentVisa, setCurrentVisa] = useState<Visa>()
    const [newName, setNewName] = useState<string>('')
    const [editingNewName, setEditingNewName] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(true)
    const { id } = useParams()

    const [idQuestions, setIdQuestions] = useState<Array<Question>>()
    const [answers, setAnswers] = useState<Array<Answer>>()

    const [processing, setProcessing] = useState<boolean>(false)

    const toggleAccordion = () => {
        setShow(!show)
    }

    const saveName = async () => {
        setProcessing(true)
        var modifiedCurrent = currentApplication
        delete modifiedCurrent?.created
        delete modifiedCurrent?.updated
        const q = await callApi<VisaApplication>(contextData.user, 'modifyVisaApplication', {
            query: modifyVisaApplication,
            variables: { item: { ...modifiedCurrent, name: newName } },
        })
        if (q.Result) {
            setCurrentApplication(q.Result)
        }
        setEditingNewName(false)
        setProcessing(false)
    }

    const updateDocs = (cva: VisaApplication) => {
        const searchDocs = Array<Document>()
        let newVA = [...contextData.visaApplications]
        newVA.filter((va) => va.id !== cva.id)
        newVA.push(cva)
        contextData.setVisaApplications([...newVA])
        setCurrentApplication(cva)

        for (const d of cva.documents!) {
            const get = contextData.documents.find((dd) => dd.id === d)

            if (get) {
                searchDocs.push(get)
            }
        }
        setCurrentApplicationDocuments(searchDocs)
    }

    const handleExport = async () => {
        setProcessing(true)
        const answersToExport = Array<Array<string>>()
        const answersList = await callApi<Array<Answer>>(contextData.user, 'getUserAnswers', {
            query: getUserAnswers,
            variables: { pk: contextData.ledgeUser?.id },
        })
        answersToExport.push(['Question', 'Answer'])
        idQuestions?.map((qq) => {
            const ans = answersList.Result!.find((aa) => aa.questionId === qq.id)

            if (ans) {
                answersToExport.push([qq.questionText!, ans.answer!])
            }
        })

        const worksheet = XLSX.utils.aoa_to_sheet(answersToExport)
        XLSX.utils.sheet_add_aoa(worksheet, [['Question', 'Answer']], { origin: 'A1' })
        worksheet['!cols'] = [{ wch: 80 }, { wch: 200 }]
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Questions + Answers')
        XLSX.writeFile(workbook, `Globoz-QuestionData-${currentApplication?.name}.xlsx`, { compression: true })

        if (currentApplicationDocuments!.length > 0) {
            await exportDocsAndQuestions(contextData, currentApplicationDocuments!)
        }

        setProcessing(false)
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                if (id) {
                    // initially make page unloaded, get the visas then set loaded
                    setLoaded(false)

                    const cva = contextData.visaApplications.find((ff) => ff.id === id)

                    if (cva) {
                        setCurrentApplication(cva)
                    }

                    const theVisa = contextData.visaTypes.find((vt) => vt.id === cva?.visaType)

                    if (theVisa) {
                        setCurrentVisa(theVisa)
                        const idQuestions = contextData.questions

                        if (idQuestions) {
                            const cvq = theVisa.topics
                            const getTopics = Array<QuestionTopic>()
                            const findBlocks = Array<String>()
                            const getBlocks = Array<QuestionBlock>()
                            const findQuestions = Array<String>()
                            const getQuestions = Array<Question>()
                            const getDocs = Array<Document>()
                            cvq?.forEach((tt) => {
                                const found = contextData.questionTopics.find((toFind) => toFind.id === tt)
                                if (found) getTopics.push(found)
                            })

                            // Get the blocks
                            getTopics.forEach((tt) => {
                                tt.questionBlocks!.forEach((bb) => {
                                    findBlocks.push(bb!)
                                })
                            })
                            findBlocks.forEach((bb) => {
                                const found = contextData.questionBlocks.find((toFind) => toFind.id === bb)
                                if (found) getBlocks.push(found)
                            })
                            // =============================================
                            //getQuestions
                            getBlocks.forEach((bb) => {
                                bb.questions?.forEach((bq) => {
                                    findQuestions.push(bq!)
                                })
                            })

                            findQuestions.forEach((qid) => {
                                const found = contextData.questions.find((foundQuestion) => foundQuestion.id === qid)
                                if (found) getQuestions.push(found)
                            })

                            //--=-=-=-=-=-=-=-=-=-=--==-=--=-=-=-=-=-===-=-=-=--==-=-=-==

                            setIdQuestions(getQuestions)

                            // =-=-=-=-=-==-=-=-=-=-=--==-=-=-=-=-=-=-=-=-=-=-=-=-=-=--=-

                            theVisa.documentTypes?.forEach((dt) => {
                                const docType = contextData.documentTypes.find((dd) => dd.id === dt)
                                const newDoc = contextData.documents.find(
                                    (doc) => doc.documentType === docType?.documentType
                                )
                                if (newDoc) getDocs.push(newDoc)
                            })
                            setCurrentApplicationDocuments(getDocs)
                        }
                        if (contextData.ledgeUser) {
                            const currentAnswers = await callApi<Array<Answer>>(contextData.user, 'getUserAnswers', {
                                query: getUserAnswers,
                                variables: { pk: contextData.ledgeUser.id },
                            })

                            if (currentAnswers.Result) {
                                contextData.setAnswers(currentAnswers.Result)
                                setAnswers(currentAnswers.Result)
                            }
                        }
                    }
                    setNewName(cva?.name!)
                }
            } finally {
                setLoaded(true)
            }
        }

        initialise()
    }, [])
    if (loaded && currentApplication && currentVisa && currentApplicationDocuments && answers && idQuestions)
        return (
            <Box paddingLeft={4} paddingTop={3}>
                <Box marginBottom={2}>
                    {editingNewName && (
                        <Box sx={{ display: 'flex' }}>
                            <TextField
                                onChange={(e) => setNewName(e.target.value)}
                                size="small"
                                variant="standard"
                                sx={{ width: '400px' }}
                            />
                            <Box onClick={() => setEditingNewName(false)} sx={{ cursor: 'pointer', marginLeft: 2 }}>
                                <CloseIcon />
                            </Box>

                            <Box onClick={saveName} sx={{ cursor: 'pointer', marginLeft: 1 }}>
                                <DoneIcon />
                            </Box>
                        </Box>
                    )}
                    {!editingNewName && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h1" marginBottom={0}>
                                {currentApplication.name}
                            </Typography>
                            <Box
                                sx={{ marginLeft: 2, cursor: 'pointer' }}
                                onClick={() => setEditingNewName(!editingNewName)}
                            >
                                <EditIcon />
                            </Box>
                        </Box>
                    )}
                </Box>

                <Box minWidth={700} maxWidth={1000} margin={'auto'}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body1" marginBottom={1} color={theme.palette.secondary.light}>
                            Application Details
                        </Typography>
                        <Box>
                            <LoadingButton
                                variant="contained"
                                loading={processing}
                                loadingIndicator="SAVING"
                                sx={{
                                    alignSelf: 'end',
                                }}
                                onClick={handleExport}
                            >
                                Export Application
                            </LoadingButton>
                        </Box>
                    </Box>

                    <Box display={'flex'} marginBottom={2}>
                        <Box paddingRight={4}>
                            <Box>
                                {currentApplication.dueDate && (
                                    <Typography variant="body2" color={'secondary'}>
                                        Due date:{' '}
                                    </Typography>
                                )}
                            </Box>
                            <Box>
                                <Typography variant="body2" color={'secondary'}>
                                    Type:
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            {currentApplication.dueDate && (
                                <Typography variant="body2">{currentApplication.dueDate}</Typography>
                            )}
                            <Typography variant="body2">{currentVisa.name}</Typography>
                        </Box>
                    </Box>

                    <Box>
                        <ApplicationDataAccordion
                            currentVisa={currentVisa}
                            currentApplication={currentApplication}
                            category={'Identity'}
                            currentApplicationDocuments={currentApplicationDocuments}
                            updateCurrentApplication={updateDocs}
                        />
                        <ApplicationDataAccordion
                            currentVisa={currentVisa}
                            currentApplication={currentApplication}
                            category={'Character'}
                            currentApplicationDocuments={currentApplicationDocuments}
                            updateCurrentApplication={updateDocs}
                        />
                        <ApplicationDataAccordion
                            currentVisa={currentVisa}
                            currentApplication={currentApplication}
                            category={'Financial'}
                            currentApplicationDocuments={currentApplicationDocuments}
                            updateCurrentApplication={() => updateDocs}
                        />
                        <ApplicationDataAccordion
                            currentVisa={currentVisa}
                            currentApplication={currentApplication}
                            category={'Education'}
                            currentApplicationDocuments={currentApplicationDocuments}
                            updateCurrentApplication={() => updateDocs}
                        />
                        <ApplicationDataAccordion
                            currentVisa={currentVisa}
                            currentApplication={currentApplication}
                            category={'Family'}
                            currentApplicationDocuments={currentApplicationDocuments}
                            updateCurrentApplication={() => updateDocs}
                        />
                        <ApplicationDataAccordion
                            currentVisa={currentVisa}
                            currentApplication={currentApplication}
                            category={'Health'}
                            currentApplicationDocuments={currentApplicationDocuments}
                            updateCurrentApplication={() => updateDocs}
                        />
                        <ApplicationDataAccordion
                            currentVisa={currentVisa}
                            currentApplication={currentApplication}
                            category={'Travel'}
                            currentApplicationDocuments={currentApplicationDocuments}
                            updateCurrentApplication={() => updateDocs}
                        />
                        <ApplicationDataAccordion
                            currentVisa={currentVisa}
                            currentApplication={currentApplication}
                            category={'Visa'}
                            currentApplicationDocuments={currentApplicationDocuments}
                            updateCurrentApplication={() => updateDocs}
                        />
                        <ApplicationDataAccordion
                            currentVisa={currentVisa}
                            currentApplication={currentApplication}
                            category={'Employment'}
                            currentApplicationDocuments={currentApplicationDocuments}
                            updateCurrentApplication={() => updateDocs}
                        />

                        <Box sx={CommonStyles.responseAccordionStyle}>
                            <Box sx={CommonStyles.responseAccordionHeader} onClick={toggleAccordion}>
                                <Typography variant="h3" color={theme.palette.secondary.light} marginBottom={0}>
                                    Questions
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: '16px',
                                            height: '9px',
                                            mr: 0.5,
                                            ml: 2.5,
                                            rotate: show ? '0deg' : '180deg',
                                            transition: '0.3s',
                                        }}
                                        src={Chevron}
                                        alt="logo"
                                    />
                                </Box>
                            </Box>

                            {show && (
                                <Box>
                                    {idQuestions.map((qq) => (
                                        <Box sx={CommonStyles.responseAccordionCell}>
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                }}
                                            >
                                                <Typography variant="body1" sx={{ paddingRight: 2 }}>
                                                    {qq.questionText}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    return <Loading />
}
