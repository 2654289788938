/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsers = /* GraphQL */ `
  query GetUsers {
    getUsers {
      id
      firstName
      lastName
      preferredName
      title
      dateOfBirth
      address
      email
      sex
      maritalStatus
      phone
      created
      updated
      visaApplications
      jobInterestCategory
      jobInterestCategoryOther
      jobPreviousExperience
      jobEnglishFluency
      jobAreaOfStudy
      jobAreaOfStudyOther
      jobDrive
      jobFar
      jobVisaStatus
      jobVisaStatusOther
      jobDays
      jobTime
      jobHowDidYouFindOutAboutUs
      jobCurrentLocation
      sourceJobChangeDate
      sourceJobForMe
      status
      agency
      __typename
    }
  }
`;
export const getUsersLedgeRec = /* GraphQL */ `
  query GetUsersLedgeRec {
    getUsersLedgeRec {
      id
      firstName
      lastName
      preferredName
      title
      dateOfBirth
      address
      email
      sex
      maritalStatus
      phone
      created
      updated
      visaApplications
      jobInterestCategory
      jobInterestCategoryOther
      jobPreviousExperience
      jobEnglishFluency
      jobAreaOfStudy
      jobAreaOfStudyOther
      jobDrive
      jobFar
      jobVisaStatus
      jobVisaStatusOther
      jobDays
      jobTime
      jobHowDidYouFindOutAboutUs
      jobCurrentLocation
      sourceJobChangeDate
      sourceJobForMe
      status
      agency
      __typename
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById($pk: ID!) {
    getUserById(pk: $pk) {
      id
      firstName
      lastName
      preferredName
      title
      dateOfBirth
      address
      email
      sex
      maritalStatus
      phone
      created
      updated
      visaApplications
      jobInterestCategory
      jobInterestCategoryOther
      jobPreviousExperience
      jobEnglishFluency
      jobAreaOfStudy
      jobAreaOfStudyOther
      jobDrive
      jobFar
      jobVisaStatus
      jobVisaStatusOther
      jobDays
      jobTime
      jobHowDidYouFindOutAboutUs
      jobCurrentLocation
      sourceJobChangeDate
      sourceJobForMe
      status
      agency
      __typename
    }
  }
`;
export const getUserAuth0ById = /* GraphQL */ `
  query GetUserAuth0ById($pk: ID!) {
    getUserAuth0ById(pk: $pk) {
      id
      useMfa
      __typename
    }
  }
`;
export const getDocuments = /* GraphQL */ `
  query GetDocuments {
    getDocuments {
      id
      userId
      documentName
      documentCategory
      documentType
      created
      updated
      expires
      uploaded
      originalFileName
      contentType
      __typename
    }
  }
`;
export const getDocumentById = /* GraphQL */ `
  query GetDocumentById($pk: ID!) {
    getDocumentById(pk: $pk) {
      id
      userId
      documentName
      documentCategory
      documentType
      created
      updated
      expires
      uploaded
      originalFileName
      contentType
      __typename
    }
  }
`;
export const getDocumentsForUser = /* GraphQL */ `
  query GetDocumentsForUser($pk: ID!) {
    getDocumentsForUser(pk: $pk) {
      id
      userId
      documentName
      documentCategory
      documentType
      created
      updated
      expires
      uploaded
      originalFileName
      contentType
      __typename
    }
  }
`;
export const getDocumentTypes = /* GraphQL */ `
  query GetDocumentTypes {
    getDocumentTypes {
      id
      documentCategory
      documentType
      __typename
    }
  }
`;
export const getDocumentTypeById = /* GraphQL */ `
  query GetDocumentTypeById($pk: ID!) {
    getDocumentTypeById(pk: $pk) {
      id
      documentCategory
      documentType
      __typename
    }
  }
`;
export const getVisaById = /* GraphQL */ `
  query GetVisaById($pk: ID!) {
    getVisaById(pk: $pk) {
      id
      name
      topics
      documentTypes
      published
      __typename
    }
  }
`;
export const getVisas = /* GraphQL */ `
  query GetVisas {
    getVisas {
      id
      name
      topics
      documentTypes
      published
      __typename
    }
  }
`;
export const getQuestionById = /* GraphQL */ `
  query GetQuestionById($pk: String!) {
    getQuestionById(pk: $pk) {
      id
      questionType
      questionText
      notes
      order
      choices
      questionHelperTexts
      __typename
    }
  }
`;
export const getQuestions = /* GraphQL */ `
  query GetQuestions {
    getQuestions {
      id
      questionType
      questionText
      notes
      order
      choices
      questionHelperTexts
      __typename
    }
  }
`;
export const getQuestionTopicById = /* GraphQL */ `
  query GetQuestionTopicById($pk: String!) {
    getQuestionTopicById(pk: $pk) {
      id
      category
      title
      subtext
      questionBlocks
      order
      tags
      __typename
    }
  }
`;
export const getQuestionTopics = /* GraphQL */ `
  query GetQuestionTopics {
    getQuestionTopics {
      id
      category
      title
      subtext
      questionBlocks
      order
      tags
      __typename
    }
  }
`;
export const getQuestionBlockById = /* GraphQL */ `
  query GetQuestionBlockById($pk: String!) {
    getQuestionBlockById(pk: $pk) {
      id
      title
      subtext
      questions
      order
      conditionalShowQuestionId
      conditionalShowAnswer
      __typename
    }
  }
`;
export const getQuestionBlocks = /* GraphQL */ `
  query GetQuestionBlocks {
    getQuestionBlocks {
      id
      title
      subtext
      questions
      order
      conditionalShowQuestionId
      conditionalShowAnswer
      __typename
    }
  }
`;
export const getVisaApplicationById = /* GraphQL */ `
  query GetVisaApplicationById($pk: ID!) {
    getVisaApplicationById(pk: $pk) {
      id
      userId
      name
      visaType
      created
      updated
      documents
      dueDate
      __typename
    }
  }
`;
export const getVisaApplicationsByUser = /* GraphQL */ `
  query GetVisaApplicationsByUser($pk: String!) {
    getVisaApplicationsByUser(pk: $pk) {
      id
      userId
      name
      visaType
      created
      updated
      documents
      dueDate
      __typename
    }
  }
`;
export const getVisaApplications = /* GraphQL */ `
  query GetVisaApplications {
    getVisaApplications {
      id
      userId
      name
      visaType
      created
      updated
      documents
      dueDate
      __typename
    }
  }
`;
export const getAnswerById = /* GraphQL */ `
  query GetAnswerById($pk: String!) {
    getAnswerById(pk: $pk) {
      id
      userId
      questionId
      answer
      __typename
    }
  }
`;
export const getAnswerByData = /* GraphQL */ `
  query GetAnswerByData($item: AnswerInput!) {
    getAnswerByData(item: $item) {
      id
      userId
      questionId
      answer
      __typename
    }
  }
`;
export const getUserAnswers = /* GraphQL */ `
  query GetUserAnswers($pk: String!) {
    getUserAnswers(pk: $pk) {
      id
      userId
      questionId
      answer
      __typename
    }
  }
`;
export const getAnswers = /* GraphQL */ `
  query GetAnswers {
    getAnswers {
      id
      userId
      questionId
      answer
      __typename
    }
  }
`;
