export const ErrorSessionLapsed = new Error('The user session has lapsed - the user is being signed out')

function mapError(error: string): string {
    // Variable 'phone' has an invalid value. Unable to parse `+61 401 84` as a valid phone number.
    const phoneError = error.match(
        /^Variable 'phone' has an invalid value. Unable to parse `(\+[0-9 ]+)` as a valid phone number.*$/
    )
    if (phoneError && phoneError.length > 1)
        return `Please enter a valid phone number. ‘${phoneError[1]}’ is not a valid phone number.`
    return error
}

export function getErrorMessage(context: string, error: any): string {
    if (error?.errors && error?.errors.length) return getErrorMessageFromApiResult(error)
    if (typeof error === 'string') return mapError(error)
    if (error instanceof Error) return (error as Error).message ?? 'an error occurred'
    if (typeof error === 'object') return (error as object).toString()
    return `An error has occurred ${context}!`
}

export function getErrorMessageFromApiResult(apiResult: any): string {
    const errors = apiResult?.errors as any[]
    if (!errors || errors.length === 0 || !errors[0].message)
        return 'The api call failed but no error information was returned'
    return errors[0].message as string
}

export function isUnauthorisedError(error: string): boolean {
    return error.includes('401')
}
