import { useState, useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

import { UserContext } from '../../common/userContext'
import { theme } from '../../assets/theme'

import { AnswerInput, Question, QuestionBlock } from '../../graphql/API'
import { QuestionField } from './question'

export function QuestionBlockSection({ answerInput, updateAnswers, questionBlock }) {
    const contextData = useContext(UserContext)

    const [qb, setQb] = useState<QuestionBlock>()
    const [questions, setQuestions] = useState<Array<Question>>([])

    const checkDisplayConditional = (qb: QuestionBlock, ai: Array<AnswerInput>) => {
        if (qb.conditionalShowQuestionId && qb.conditionalShowAnswer) {
            const ans = ai.find((aa) => aa.questionId === qb.conditionalShowQuestionId)
            if (ans?.answer === qb.conditionalShowAnswer) return true
            return false
        }

        return true
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                setQb(questionBlock)

                var qs = Array<Question>()
                questionBlock?.questions?.forEach((qq) => {
                    qs.push(contextData.questions.find((toFind) => toFind.id === qq)!)
                })

                setQuestions(qs)
            } catch (error) {
                console.log(error)
            }
        }

        initialise()
    }, [])

    if (qb && questions) {
        if (checkDisplayConditional(qb, answerInput)) {
            return (
                <Box>
                    <Typography variant="h4" color={theme.palette.primary.light}>
                        {qb.title}
                    </Typography>
                    <Box marginTop={0.5} marginBottom={2}>
                        <Typography variant="body2" color="divider">
                            {qb.subtext}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {questions
                            ?.sort((a, b) => a.order! - b.order!)
                            .map((qq) => (
                                <QuestionField answerInput={answerInput} updateAnswers={updateAnswers} question={qq!} />
                            ))}
                    </Box>
                </Box>
            )
        } else return <></>
    }

    return <></>
}
