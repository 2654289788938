import { useState, useContext, useEffect } from 'react'
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormHelperText,
    CircularProgress,
    TextField,
    DialogActions,
    MenuItem,
    Select,
    Typography,
} from '@mui/material'
import { callApi } from '../../common/apiUtils'
import { EditResult, EditProps } from '../../common/types'
import { uploadDocument, removeExtension, userDocumentExists } from '../../common/documentUtils'

import { UserContext } from '../../common/userContext'
import { PageHeader } from '../common/pageHeader'
import { PageInfo } from '../common/pageInfo'
import { DragAndDrop } from '../common/dragAndDrop'
import { Document, DocumentInput } from '../../graphql/API'
import { createDocument } from '../../graphql/mutations'
import * as utils from '../../common/typeUtils'
import { theme } from '../../assets/theme'
import RedDivider from '../common/redDivider'
import { documentCategories } from '../documents/documentCommon'

const { v4: uuid } = require('uuid')

const getNewDocument = (userId: string) => {
    const initialDocument: DocumentInput = {
        id: uuid(),
        userId: userId,
        documentName: '',
        documentCategory: '',
        documentType: '',
        uploaded: 0,
        originalFileName: '',
        expires: undefined,
    }

    return initialDocument
}

export function NewDocumentModal(props: EditProps) {
    const id = props.id
    const isNew = id === 'add'
    const contextData = useContext(UserContext)

    const [documentInput, setDocumentInput] = useState<DocumentInput>({} as DocumentInput)
    const [fileUpload, setFileUpload] = useState<File | undefined>(undefined)
    const [hasFile, setHasFile] = useState(false)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(true)
    const [expires, setExpires] = useState<boolean>(false)
    const [pageError, setPageError] = useState<string | undefined>()

    const [errorData, setErrorData] = useState<any>({
        documentName: '',
        documentType: '',
        originalFileName: '',
        expires: '',
    })

    useEffect(() => {
        const initialise = async () => {
            try {
                setBackdropOpen(true)
                if (isNew) {
                    setDocumentInput(getNewDocument(contextData.user.sub))
                    return
                }
                const document: Document = props.data
                if (!document) {
                    console.log('Document not found: ' + id)
                    return
                }

                const existingDocumentInput: DocumentInput = {
                    id: document.id,
                    userId: document.userId,
                    documentName: document.documentName,
                    documentCategory: document.documentCategory,
                    documentType: document.documentType,
                    uploaded: document.uploaded!,
                    originalFileName: document.originalFileName!,
                    expires: document.expires!,
                }
                setDocumentInput(existingDocumentInput)
                setExpires(document.expires !== null && document.expires !== '')
                var errorUpdate = errorData
                // if (utils.hasExpired(document.expires))
                //     errorUpdate = {
                //         ...errorUpdate,
                //         expires: 'the date specified is in the past',
                //     }
                const [result, exists] = await userDocumentExists(contextData, document)
                setHasFile(exists)
                setErrorData(errorUpdate)
                setBackdropOpen(false)
            } finally {
                setBackdropOpen(false)
            }
        }
        if (props.open) initialise()
    }, [props.open])

    const setDocumentChanged = (selectedFile: File) => {
        const fileName = selectedFile.name
        const contentType = selectedFile.type
        setFileUpload(selectedFile)
        setDocumentInput({
            ...documentInput,
            documentName: documentInput.documentName ? documentInput.documentName : removeExtension(fileName),
            uploaded: 0,
            originalFileName: fileName,
            contentType: contentType,
        })
    }

    const setValue = (name: string, value: string | undefined | null) => {
        setDocumentInput({
            ...documentInput,
            [name]: value,
        })
    }

    const documentNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentInput({
            ...documentInput,
            documentName: event.target.value,
        })
    }

    const expiryChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpires(event.target.checked)
        if (!event.target.checked) {
            setValue('expires', undefined)
        } else setValue('expires', utils.getNextYearISODate())
        errorChanged('expires', '')
    }

    const errorChanged = (name: string, message: string) => {
        const currentValue = errorData[name]
        if (currentValue === message) return
        setErrorData({
            ...errorData,
            [name]: message,
        })
    }

    const handleCancel = () => {
        props.callback(EditResult.Cancelled, undefined)
    }

    const handleSave = async () => {
        handleSubmit(false)
    }

    const handleDrop = (files: File[]) => {
        // console.log(files)
        if (files.length === 0) return
        setDocumentChanged(files[0])
    }

    const handleSubmit = async (allowDuplicate: boolean) => {
        let issueCount = 0

        setPageError(undefined)
        if (!documentInput.documentName || documentInput.documentName === '') {
            errorChanged('documentName', 'required field')
            issueCount = issueCount + 1
        }

        if (!documentInput.documentCategory || documentInput.documentCategory === '') {
            errorChanged('documentCategory', 'required field')
            issueCount = issueCount + 1
        }

        if (!documentInput.documentType || documentInput.documentType === '') {
            errorChanged('documentType', 'required field')
            issueCount = issueCount + 1
        }

        if (hasFile || fileUpload !== undefined) {
            errorChanged('originalFileName', '')
        } else {
            errorChanged('originalFileName', 'please attach a file first')
            issueCount = issueCount + 1
        }

        if (issueCount !== 0) return

        try {
            setBackdropOpen(true)

            if (!documentInput.id) {
                setPageError('Invalid document details! Please contact support')
                return
            }

            if (fileUpload) {
                const uploadError = await uploadDocument(contextData, documentInput.id, fileUpload)
                if (uploadError) {
                    setPageError(uploadError.message)
                    return
                }
            }
            const documentInputUpdate = documentInput.expires ? documentInput : { ...documentInput, expires: null }
            const updatedDocument = await callApi<Document>(contextData.user, 'createDocument', {
                query: createDocument,
                variables: { item: documentInputUpdate },
            })

            if (updatedDocument.Error) {
                setPageError(updatedDocument.Error.message)
                return
            }
            const document = updatedDocument.Result
            props.callback(EditResult.Added, document)
        } finally {
            setValue('expires', undefined)
            setFileUpload(undefined)
            setDocumentInput({
                ...documentInput,
                documentName: '',
                documentType: '',
                documentCategory: '',
                expires: '',
            })
            setErrorData({
                documentName: '',
                documentType: '',
                originalFileName: '',
                expires: '',
            })
            setBackdropOpen(false)
        }
    }
    return (
        <Dialog open={props.open}>
            <DialogContent>
                <Box sx={{ minWidth: '400px', maxWidth: '400px' }}>
                    <PageHeader title={isNew ? 'Upload Document' : 'Replace Document'} />
                    <RedDivider />
                    <PageInfo color="error" message={pageError} />

                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body1" color={theme.palette.secondary.main} marginBottom={1}>
                            Document category
                        </Typography>
                        <Select
                            name="documentCategory"
                            size="small"
                            value={documentInput.documentCategory}
                            // onChange={documentCategoryChanged}
                            onChange={(e) => setDocumentInput({ ...documentInput, documentCategory: e.target.value })}
                        >
                            {documentCategories.map((dc) => (
                                <MenuItem value={dc.name} key={dc.name}>
                                    {dc.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={errorData.documentCategory}>{errorData.documentCategory}</FormHelperText>
                    </Box>

                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body1" color={theme.palette.secondary.main} marginBottom={1}>
                            Document type
                        </Typography>
                        <Select
                            name="documentType"
                            size="small"
                            value={documentInput.documentType}
                            onChange={(e) => setDocumentInput({ ...documentInput, documentType: e.target.value })}
                        >
                            {contextData.documentTypes
                                .filter((ddcc) => ddcc.documentCategory === documentInput.documentCategory)
                                .map((dc) => (
                                    <MenuItem value={dc.documentType} key={dc.documentType}>
                                        {dc.documentType}
                                    </MenuItem>
                                ))}
                        </Select>
                        <FormHelperText error={errorData.documentType ? true : false}>
                            {errorData.documentType}
                        </FormHelperText>
                    </Box>

                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            {hasFile === false && fileUpload === undefined && (
                                <Typography variant="body1" color={theme.palette.secondary.main}>
                                    Attach Document
                                </Typography>
                            )}
                            {documentInput.originalFileName && (hasFile || fileUpload !== undefined) && (
                                <Typography variant="body1" color={theme.palette.secondary.main} marginLeft={1}>
                                    {fileUpload === undefined
                                        ? `${documentInput.originalFileName} is attached`
                                        : `${documentInput.originalFileName} will be uploaded`}
                                </Typography>
                            )}
                        </Box>
                        <Box
                            component="span"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{
                                mt: 1,
                                width: 1,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <DragAndDrop
                                handleDrop={handleDrop}
                                replace={hasFile}
                                multiple={false}
                                currentFileName={documentInput.documentName}
                            />
                        </Box>
                        <FormHelperText error={errorData.originalFileName ? true : false}>
                            {errorData.originalFileName}
                        </FormHelperText>
                    </Box>

                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body1" color={theme.palette.secondary.main} marginBottom={1}>
                            Document name
                        </Typography>
                        <TextField
                            autoFocus
                            name="documentName"
                            size="small"
                            value={documentInput.documentName}
                            onChange={documentNameChanged}
                        />
                        <FormHelperText error={errorData.documentName ? true : false}>
                            {errorData.documentName}
                        </FormHelperText>
                    </Box>

                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography variant="body1" color={theme.palette.secondary.main} marginBottom={2}>
                                Document Expiry
                            </Typography>
                            <Checkbox
                                size="small"
                                checked={expires}
                                onChange={expiryChanged}
                                sx={{ height: '3px', ml: 0, mt: '3px' }}
                            />
                        </Box>

                        {expires && (
                            <TextField
                                id="dob"
                                value={documentInput.expires}
                                onChange={(e) => setDocumentInput({ ...documentInput, expires: e.target.value })}
                                type="date"
                                size="small"
                                sx={{ width: '100%' }}
                                disabled={!expires}
                            />
                        )}

                        <FormHelperText error={errorData.expires ? true : false}>{errorData.expires}</FormHelperText>
                    </Box>

                    <DialogActions sx={{ mt: 1 }}>
                        <Button variant="outlined" onClick={handleCancel}>
                            Cancel
                        </Button>

                        <Button variant="contained" onClick={handleSave}>
                            Upload
                        </Button>
                    </DialogActions>
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={backdropOpen}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
