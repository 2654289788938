import { useState, useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

import { UserContext } from '../../common/userContext'
import { CommonStyles } from '../../assets/theme'
import Chevron from '../../assets/chevron.png'
import { Answer, QuestionBlock } from '../../graphql/API'
import { QuestionBlockSection } from './questionBlock'
import { callApi } from '../../common/apiUtils'
import { getUserAnswers } from '../../graphql/queries'

export function QuestionTopicSection({ answerInput, updateAnswers, questionTopic }) {
    const contextData = useContext(UserContext)

    const [questionBlocks, setQuestionBlocks] = useState<Array<QuestionBlock>>()
    const [questionAnswers, setQuestionAnswers] = useState<Array<Answer>>()
    const [show, setShow] = useState<boolean>(false)

    const toggleAccordion = () => {
        setShow(!show)
    }

    const getSelectedBorder = () => {
        if (show) return '1px solid #F15722'
        return '1px solid #00000000'
    }

    useEffect(() => {
        const initialise = async () => {
            try {
                var blocksToAdd = Array<QuestionBlock>()
                for (const qbs of questionTopic.questionBlocks ?? []) {
                    const qbl = contextData.questionBlocks.find((q) => q.id === qbs)
                    if(!qbl) {
                        console.log(`undefined question block ${qbs}!`)
                        continue
                    }
                    blocksToAdd.push(qbl)
                }

                if (blocksToAdd.length > 0) {
                    var answersToAdd = Array<Answer>()

                    const answersList = await callApi<Array<Answer>>(contextData.user, 'getUserAnswers', {
                        query: getUserAnswers,
                        variables: { pk: contextData.ledgeUser?.id },
                    })

                    for (const b of blocksToAdd) {
                        for (const answers of b.questions ?? []) {
                            const ans = answersList.Result!.find((a) => a.id === answers)
                            answersToAdd.push(ans!)
                        }
                    }
                    setQuestionBlocks(blocksToAdd)
                    setQuestionAnswers(answersToAdd)
                } else {
                    setQuestionBlocks([])
                    setQuestionAnswers([])
                }
            } catch (error) {
                console.log(error)
            }
        }
        initialise()
    }, [])

    if (questionTopic && questionBlocks && questionAnswers)
        return (
            <Box>
                <Box marginTop={3}>
                    <Box sx={[CommonStyles.tabStyle]} border={getSelectedBorder}>
                        <Box sx={CommonStyles.accordionHeader} onClick={toggleAccordion}>
                            <Typography margin={0} variant="h2">
                                {questionTopic.title}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: '20px',
                                        height: '12px',
                                        ml: 2.5,
                                        rotate: show ? '0deg' : '180deg',
                                        transition: '0.3s',
                                    }}
                                    src={Chevron}
                                    alt="logo"
                                />
                            </Box>
                        </Box>
                        {show && (
                            <Box
                                marginTop={0.5}
                                position={show ? 'relative' : 'absolute'}
                                left={show ? 'inherit' : '-9999px'}
                            >
                                <Typography variant="body2" color="divider">
                                    {questionTopic.subtext}
                                </Typography>
                                <Box marginTop={3}>
                                    {questionBlocks
                                        ?.sort((a, b) => (a.order ? a.order : 1) - (b.order ? b.order : 2))
                                        .map((qb, i) => (
                                            <QuestionBlockSection
                                                key={i}
                                                answerInput={answerInput}
                                                questionBlock={qb!}
                                                updateAnswers={updateAnswers}
                                            />
                                        ))}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        )

    return <></>
}
