import { Box, Typography, TextField, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material'

import { Question, AnswerInput } from '../../graphql/API'
import { CommonStyles } from '../../assets/theme'

type QProps = {
    question: Question
    answerInput: Array<AnswerInput>
    updateAnswers: Function
}

export function QuestionField(QProps: QProps) {
    const getFieldStyle = () => {
        if (QProps.question.questionText!.length > 32 || QProps.question.questionType === 'large text')
            return CommonStyles.responseFieldContainerLarge
        return CommonStyles.responseFieldContainerSmall
    }

    const handleSelectMultiple = (event: SelectChangeEvent<typeof String>) => {
        const {
            target: { value },
        } = event
        const f = typeof value === 'string' ? value.split(',') : value
        QProps.updateAnswers(
            QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id,
            event.target.name,
            f
        )
    }

    if (QProps.question) {
        if (QProps.question.questionType === 'text') {
            return (
                <Box sx={getFieldStyle()}>
                    <Typography variant="body2" color="secondary">
                        {QProps.question.questionText}
                    </Typography>

                    <TextField
                        id={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id}
                        name={QProps.question.id}
                        value={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)!.answer}
                        onChange={(e) =>
                            QProps.updateAnswers(
                                QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id,
                                e.target.name,
                                e.target.value
                            )
                        }
                        size="small"
                        fullWidth
                    />
                </Box>
            )
        } else if (QProps.question.questionType === 'choice') {
            return (
                <Box sx={getFieldStyle()}>
                    <Typography variant="body2" color="secondary">
                        {QProps.question.questionText}
                    </Typography>
                    <FormControl component="fieldset" fullWidth>
                        <Select
                            id={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id}
                            name={QProps.question.id}
                            value={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)!.answer}
                            onChange={(e) =>
                                QProps.updateAnswers(
                                    QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id,
                                    e.target.name,
                                    e.target.value
                                )
                            }
                            size="small"
                        >
                            <MenuItem value={''} key={''}>
                                -- please select a choice --
                            </MenuItem>
                            {QProps.question.choices!.map((d, i) => (
                                <MenuItem value={d!} key={i}>
                                    {d!}
                                </MenuItem>
                            ))}
                        </Select>
                        {QProps.answerInput.find((aa) => aa.questionId === QProps.question.id) &&
                            QProps.question.questionHelperTexts && (
                                <Typography variant="body2" color={'primary.light'} mt={1}>
                                    {
                                        QProps.question.questionHelperTexts![
                                            QProps.question.choices?.findIndex(
                                                (c) =>
                                                    c ===
                                                    QProps.answerInput.find(
                                                        (aa) => aa.questionId === QProps.question.id
                                                    )!.answer
                                            )!
                                        ]
                                    }
                                </Typography>
                            )}
                    </FormControl>
                </Box>
            )
        } else if (QProps.question.questionType === 'boolean') {
            return (
                <Box sx={getFieldStyle()}>
                    <Typography variant="body2" color="secondary">
                        {QProps.question.questionText}
                    </Typography>

                    <FormControl component="fieldset" fullWidth>
                        <Select
                            id={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id}
                            name={QProps.question.id}
                            value={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)!.answer}
                            onChange={(e) =>
                                QProps.updateAnswers(
                                    QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id,
                                    e.target.name,
                                    e.target.value
                                )
                            }
                            size="small"
                        >
                            <MenuItem value={''} key={''}>
                                -- please select an option --
                            </MenuItem>
                            <MenuItem value={'yes'} key={QProps.question.id + 'yes'}>
                                yes
                            </MenuItem>
                            <MenuItem value={'no'} key={QProps.question.id + 'no'}>
                                no
                            </MenuItem>
                        </Select>
                        {QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)!.answer === 'yes' &&
                            QProps.question.questionHelperTexts &&
                            QProps.question.questionHelperTexts![0] && (
                                <Typography variant="body2" color={'primary.light'} mt={1}>
                                    {QProps.question.questionHelperTexts![0]}
                                </Typography>
                            )}

                        {QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)!.answer === 'no' &&
                            QProps.question.questionHelperTexts &&
                            QProps.question.questionHelperTexts![1] && (
                                <Typography variant="body2" color={'primary.light'} mt={1}>
                                    {QProps.question.questionHelperTexts![1]}
                                </Typography>
                            )}
                    </FormControl>
                </Box>
            )
        } else if (QProps.question.questionType === 'date') {
            return (
                <Box sx={getFieldStyle()}>
                    <Typography variant="body2" color="secondary">
                        {QProps.question.questionText}
                    </Typography>
                    <TextField
                        id={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id}
                        name={QProps.question.id}
                        value={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)!.answer}
                        onChange={(e) =>
                            QProps.updateAnswers(
                                QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id,
                                e.target.name,
                                e.target.value
                            )
                        }
                        type="date"
                        size="small"
                        fullWidth
                    />
                </Box>
            )
            // } else if (QProps.question.questionType === 'multiselect') {
            //     return (
            //         <Box sx={getFieldStyle()}>
            //             <Typography variant="body2" color="secondary">{QProps.question.questionText}</Typography>
            //             <Select
            //                 id={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id}
            //                 name={QProps.question.id}
            //                 multiple
            //                 value={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)!.answer}
            //                 onChange={(e) =>
            //                     QProps.updateAnswers(
            //                         QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id,
            //                         e.target.name,
            //                         e.target.value
            //                     )
            //                 }
            //                 type="date"
            //                 size="small"
            //                 fullWidth
            //             />

            //             <Select
            //                 id={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id}
            //                 name={QProps.question.id}
            //                 multiple
            //                 value={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)!.answer}
            //                 onChange={(e) => handleSelectMultiple}
            //                 size={'small'}
            //                 fullWidth
            //             >
            //                 {QProps.question.choices!.map((d) => (
            //                         <MenuItem value={d!} key={d!}>
            //                             {d!}
            //                         </MenuItem>
            //                     ))}
            //             </Select>
            //         </Box>
            //     )
        } else if (QProps.question.questionType === 'large text') {
            return (
                <Box sx={getFieldStyle()}>
                    <Typography variant="body2" color="secondary">
                        {QProps.question.questionText}
                    </Typography>

                    <TextField
                        id={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id}
                        name={QProps.question.id}
                        value={QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)!.answer}
                        onChange={(e) =>
                            QProps.updateAnswers(
                                QProps.answerInput.find((aa) => aa.questionId === QProps.question.id)?.id,
                                e.target.name,
                                e.target.value
                            )
                        }
                        multiline
                        minRows={3}
                        maxRows={10}
                        size="small"
                        fullWidth
                    />
                </Box>
            )
        }
    }
    return <></>
}
