export const questionTypes = [
    {
        key: 'Text',
        value: 'text',
        text: 'Text',
    },
    {
        key: 'Choice',
        value: 'choice',
        text: 'Choice',
    },
    {
        key: 'Boolean',
        value: 'boolean',
        text: 'Yes/No',
    },
    {
        key: 'Date',
        value: 'date',
        text: 'Date',
    },
    {
        key: 'largeText',
        value: 'large text',
        text: 'Large Text',
    },
]
export const questionCategories = [
    'identity',
    'character',
    'financial',
    'education',
    'family',
    'health',
    'travel',
    'visa',
    'employment',
]

export const getSelectedBorder = (show: boolean) => {
    if (show) return '1px solid #F15722'
    return '1px solid #00000000'
}
