import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

export const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: '#494949',
                light: '#F15722',
            },
            secondary: {
                main: '#205493',
                light: '#402E8D',
            },
            background: {
                default: '#F9F9F9',
                paper: '#FFFFFF',
            },
            divider: '#A0A0A0',
            success: {
                main: '#4C8C87',
            },
            warning: {
                main: '#FEBD11',
            },
            error: {
                main: '#E25858',
            },
            common: {},
        },
        typography: {
            fontFamily: 'Roboto',
            h1: {
                fontSize: 24,
                fontWeight: 600,
                color: '#402E8D',
                marginBottom: 16,
            },
            h2: {
                fontSize: 19.5,
                fontWeight: 600,
                color: '#402E8D',
                marginBottom: 16,
            },
            h3: {
                fontSize: 20,
                fontWeight: '600',
                marginBottom: 16,
            },
            h4: {
                fontSize: 16,
                fontWeight: 400,
                color: '#402E8D',
                marginBottom: 8,
            },
            h5: {
                fontSize: 20,
                fontWeight: 400,
                color: '#402E8D',
            },
            body1: {
                fontSize: 16,
                fontWeight: 400,
                color: '#494949',
            },
            body2: {
                fontSize: 12,
                fontWeight: '500',
                color: '#494949',
                marginBottom: 8,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                        boxShadow: 'none',
                    },
                    contained: {
                        '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#F1572288',
                        },
                        backgroundColor: '#F15722',
                    },
                    outlined: {
                        '&:hover': {
                            boxShadow: 'none',
                            borderColor: '#F57944',
                            color: '#F57944',
                        },
                        borderColor: '#F15722',
                        color: '#F15722',
                    },
                },
            },
            // MuiDivider: {
            //     styleOverrides: {
            //         root: {
            //             background: "#A0A0A0",
            //         },
            //     },
            // },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        background: '#FFFFFF',
                        // "&:hover": {
                        //     background: "#EEEEEE",
                        // },
                    },
                },
            },
            // MuiListItemButton: {
            //     styleOverrides: {
            //         root: {
            //             background: "#FFFFFF",
            //             "&:hover": {
            //                 background: "#EEEEEE",
            //             },
            //         },
            //     },
            // },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                        boxShadow: 'none',
                    },
                    // variants: {
                    //   // outlined: {
                    //   //   style :{
                    //   //     borderRadius: 0,
                    //   //   }
                    //   // },
                    // },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                        boxShadow: 'none',
                        elevation: 0,
                        backgroundColor: '#FFFFFF',
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {},
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {},
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {},
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                    },
                    columnHeaders: {
                        backgroundColor: '#E3E0EE',
                        color: '#402E8D',
                    },
                    columnHeaderTitleContainer: {
                        padding: 0,
                    },
                    main: {
                        backgroundColor: '#FFFFFF',
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        variant: 'outlined',
                        size: 'small',
                        background: '#fff',
                    },
                },
            },

            // MuiCircularProgress: {
            //     styleOverrides: {
            //         root: {
            //             color: "green",
            //         },
            //     },
            // },
            // MuiBreadcrumbs: {
            // 	styleOverrides: {
            // 		root: {
            // 			color: '#000000',
            // 		},
            // 	},
            // },
            // MuiChip: {
            // 	styleOverrides: {
            // 		root: {
            // 			borderRadius: 0,
            // 			boxShadow: 'none',
            // 		},
            // 	},
            // },
        },
    })
)

export const CommonStyles = {
    tabStyle: {
        padding: 3,
        minWidth: 802,
        maxWidth: 802,
        backgroundColor: '#ffffff',
        margin: 'auto',
        // border: '1px solid #ffffffff',
        borderRadius: 2,
        marginBottom: 2,
    },
    spacingStyle: {
        minWidth: 802,
        maxWidth: 802,
        margin: 'auto',
    },
    accordionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    tabHalf: { width: '50%', marginBottom: 2 },
    tabHalfLeft: { paddingRight: 1 },
    tabHalfRight: { paddingLeft: 1 },
    tabBar: { background: '#ffffff', borderRadius: 5 },
    responseAccordionStyle: { background: '#FFFFFF', borderRadius: 1, border: '1px solid #A0A0A0', marginBottom: 4 },
    responseAccordionHeader: { padding: 2, display: 'flex', justifyContent: 'space-between' },
    responseAccordionCell: {
        padding: 2,
        borderTop: '1px solid #A0A0A0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    responseFieldContainerSmall: { width: '50%', paddingRight: 2, marginBottom: 2 },
    responseFieldContainerLarge: { width: '100%', paddingRight: 2, marginBottom: 2 },
    centerFlex: { display: 'flex', alignItems: 'center' },
    dashedBorder: { border: 'dashed grey 2px', borderRadius: 2 },
    sideBarBoxStyle: {
        color: `#fff`,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 3,
        paddingRight: 3,
        borderBottom: '1px solid #644DC8',
    },
    buttonFlex: { display: 'flex', justifyContent: 'flex-end' },
}
