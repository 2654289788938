import { DocumentType } from '../../graphql/API'

export const documentCategories = [
    {
        name: 'Identity',
        subcategories: [],
    },
    {
        name: 'Character',
        subcategories: [],
    },
    {
        name: 'Financial',
        subcategories: [],
    },
    {
        name: 'Education',
        subcategories: [],
    },
    {
        name: 'Family',
        subcategories: [],
    },
    {
        name: 'Health',
        subcategories: [],
    },
    {
        name: 'Visa',
        subcategories: [],
    },
    {
        name: 'Employment',
        subcategories: [],
    },
    {
        name: 'Travel',
        subcategories: [{ name: 'Details of countries previously visited', instructions: '' }],
    },
]

export const getDocumentCategories = () => {
    var dc = Array<DocumentType>()
    var i = 0
    documentCategories.forEach((d) => {
        d.subcategories.forEach((dd) => {
            dc.push({
                __typename: 'DocumentType',
                id: i.toString(),
                documentCategory: d.name,
                documentType: dd.name,
            })
            i++
        })
    })
    console.log(dc)
    return dc
}
