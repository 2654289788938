import { useState, useContext, useEffect } from 'react'
import { Box, Typography, TextField, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { getErrorMessage } from '../../common/errorUtils'
import { UserContext } from '../../common/userContext'
import { DocumentType, QuestionTopic, Visa, VisaInput } from '../../graphql/API'
import { callApi } from '../../common/apiUtils'
import { getDocumentTypeById } from '../../graphql/queries'
import { modifyVisa } from '../../graphql/mutations'
import { useGridApiRef, DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useParams } from 'react-router-dom'
import RedDivider from '../common/redDivider'
import { Loading } from '../common/loading'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { PageInfo } from '../common/pageInfo'
import { CommonStyles } from '../../assets/theme'

export default function VisaPage() {
    const contextData = useContext(UserContext)
    const apiRef = useGridApiRef()
    const { id } = useParams()

    const [loaded, setLoaded] = useState<boolean>(false)
    const [pageError, setPageError] = useState('')
    const [visaTopics, setVisaTopics] = useState<Array<QuestionTopic>>()
    const [visaDocumentTypes, setVisaDocumentTypes] = useState<Array<DocumentType>>()
    const [newName, setNewName] = useState<string>('')
    const [currentVisa, setCurrentVisa] = useState<Visa>()
    const [editingNewName, setEditingNewName] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)

    const addTopic = async (params: any) => {
        try {
            setProcessing(true)

            if (!currentVisa) return // Logic error

            if (currentVisa.topics?.find((q) => q === params.id)) {
                alert('this topic is already on the visa!')
                setProcessing(false)
                return
            }

            var tAdd = currentVisa.topics
            tAdd?.push(params.id)

            const input: VisaInput = {
                id: currentVisa.id,
                topics: tAdd,
            }

            const result = await callApi<Visa>(contextData.user, 'modifyVisa', {
                query: modifyVisa,
                variables: { item: input },
            })

            if (result.Error) {
                setPageError(getErrorMessage('Add Topic', result.Error))
                return
            }

            const newTopics = Array<QuestionTopic>()
            result.Result!.topics?.forEach((tt) => {
                newTopics.push(contextData.questionTopics.find((qt) => qt.id === tt)!)
            })
            setVisaTopics(newTopics)

            setCurrentVisa(result.Result)
            let newVT = [...contextData.visaTypes].filter((vt) => vt.id !== result.Result?.id)
            newVT.push(result.Result!)
            contextData.setVisaTypes(newVT)
        } finally {
            setProcessing(false)
        }
    }

    const removeTopic = async (params: GridRenderCellParams) => {
        try {
            if (!currentVisa) return // Logic error

            setProcessing(true)

            const input: VisaInput = {
                id: currentVisa.id,
                topics: currentVisa?.topics?.filter((q) => q !== params.id),
            }

            const q = await callApi<Visa>(contextData.user, 'modifyVisa', {
                query: modifyVisa,
                variables: { item: input },
            })

            if (q.Result) {
                setCurrentVisa(q.Result)
                setVisaTopics(visaTopics?.filter((vt) => vt.id !== params.id))
            }
        } finally {
            setProcessing(false)
        }
    }

    const togglePublish = async () => {
        try {
            if (!currentVisa) return // Logic error

            setProcessing(true)

            const input: VisaInput = {
                id: currentVisa.id,
                published: !currentVisa?.published,
            }

            const updatedVisa = await callApi<Visa>(contextData.user, 'modifyVisa', {
                query: modifyVisa,
                variables: { item: input },
            })
            if (updatedVisa.Result) {
                setCurrentVisa(updatedVisa.Result)
                let newVisas = [...contextData.visaTypes].filter((vt) => vt.id !== updatedVisa.Result?.id)
                newVisas.push(updatedVisa.Result)
                contextData.setVisaTypes(newVisas)
            }
        } finally {
            setProcessing(false)
        }
    }

    const addDocumentType = async (params: any) => {
        try {
            if (!currentVisa) return // Logic error

            setProcessing(true)
            if (currentVisa?.documentTypes?.find((dt) => dt === params.id)) {
                alert('this document type is already on the visa!')
                setProcessing(false)
                return
            }

            var dAdd = currentVisa?.documentTypes
            dAdd?.push(params.id)

            const input: VisaInput = {
                id: currentVisa.id,
                documentTypes: dAdd,
            }

            const dt = await callApi<Visa>(contextData.user, 'modifyVisa', {
                query: modifyVisa,
                variables: { item: input },
            })
            if (dt.Result) {
                console.log(dt.Result)
            }

            const da = await callApi<DocumentType>(contextData.user, 'getDocumentTypeById', {
                query: getDocumentTypeById,
                variables: { pk: params.id },
            })
            if (da.Result) {
                const dda: DocumentType = da.Result
                var dArray = Array<DocumentType>()
                visaDocumentTypes?.forEach((d) => {
                    dArray.push(d)
                })
                dArray.push(dda)
                setVisaDocumentTypes(dArray)
            }
        } finally {
            setProcessing(false)
        }

        // if (currentVisa?.documentTypes?.find((q) => q === params.id)) {
        //     alert('this question is already on the visa!')
        //     return
        // }
    }

    const removeDocumentType = async (params: GridRenderCellParams) => {
        try {
            if (!currentVisa) return // Logic error

            setProcessing(true)

            const input: VisaInput = {
                id: currentVisa.id,
                documentTypes: currentVisa?.documentTypes?.filter((q) => q !== params.id),
            }

            const q = await callApi<Visa>(contextData.user, 'modifyVisa', {
                query: modifyVisa,
                variables: {
                    item: input,
                },
            })

            if (q.Result) {
                setCurrentVisa(q.Result)
                setVisaDocumentTypes(visaDocumentTypes?.filter((q) => q.id !== params.id))
            }
        } finally {
            setProcessing(false)
        }
    }

    const saveName = async () => {
        try {
            if (!currentVisa) return // Logic error

            setProcessing(true)
            const q = await callApi<Visa>(contextData.user, 'modifyVisa', {
                query: modifyVisa,
                variables: { item: { ...currentVisa, name: newName } },
            })
            if (q.Result) {
                setCurrentVisa(q.Result)
            }
            setEditingNewName(false)
        } finally {
            setProcessing(false)
        }
    }

    const currentDocumentTypeColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'documentCategory',
            headerName: 'Category',
            flex: 1,
        },
        {
            field: 'documentType',
            headerName: 'Type',
            flex: 1,
        },
        {
            field: 'remove',
            headerName: 'Remove',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box component={Box} onClick={() => removeDocumentType(params)} sx={{ cursor: 'pointer' }}>
                    <DeleteIcon />
                </Box>
            ),
        },
    ]

    const documentTypeColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'documentCategory',
            headerName: 'Category',
            flex: 1,
        },
        {
            field: 'documentType',
            headerName: 'Type',
            flex: 1,
        },
        {
            field: 'add',
            headerName: 'Add',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box component={Box} sx={{ cursor: 'pointer' }} onClick={() => addDocumentType(params.row)}>
                    <AddIcon />
                </Box>
            ),
        },
    ]

    const allQuestionTopicColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'title',
            headerName: 'Topic Title',
            flex: 3,
        },
        {
            field: 'category',
            headerName: 'Topic Category',
            flex: 1,
        },
        {
            field: 'subtext',
            headerName: 'Has subtext?',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <Box>{params.row.subquestion ? 'Yes' : 'No'}</Box>,
        },
        {
            field: 'questionBlocks',
            headerName: 'Question blocks added',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box>{params.row.questionBlocks ? params.row.questionBlocks.length : '0'}</Box>
            ),
        },
        {
            field: 'add',
            headerName: 'Add topic',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box component={Box} sx={{ cursor: 'pointer' }} onClick={() => addTopic(params)}>
                    <AddIcon />
                </Box>
            ),
        },
    ]

    const currentQuestionTopicColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'title',
            headerName: 'Topic Title',
            flex: 3,
        },
        {
            field: 'category',
            headerName: 'Topic Category',
            flex: 1,
        },
        {
            field: 'subtext',
            headerName: 'Has subtext?',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <Box>{params.row.subquestion ? 'Yes' : 'No'}</Box>,
        },
        {
            field: 'questionBlocks',
            headerName: 'Question blocks added',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box>{params.row.questionBlocks ? params.row.questionBlocks.length : '0'}</Box>
            ),
        },
        {
            field: 'remove',
            headerName: 'Remove',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box component={Box} sx={{ cursor: 'pointer' }} onClick={() => removeTopic(params)}>
                    <DeleteIcon />
                </Box>
            ),
        },
    ]

    useEffect(() => {
        const initialise = async () => {
            try {
                if (id) {
                    // initially make page unloaded, get the visas then set loaded
                    setLoaded(false)
                    const thisVisa = contextData.visaTypes.find((vv) => vv.id === id)

                    if (thisVisa) {
                        const topicsArray = Array<QuestionTopic>()
                        for (const topic of thisVisa.topics!) {
                            const toAdd = contextData.questionTopics.find((tt) => tt.id === topic)
                            if (toAdd) topicsArray.push(toAdd)
                        }

                        const dtArray = Array<DocumentType>()
                        for (const dt of thisVisa.documentTypes!) {
                            const toAdd = contextData.documentTypes.find((ddtt) => ddtt.id === dt)
                            if (toAdd) dtArray.push(toAdd)
                        }

                        setVisaDocumentTypes(dtArray)
                        setCurrentVisa(thisVisa)
                        setVisaTopics(topicsArray)
                    }
                }
            } finally {
                setLoaded(true)
            }
        }

        initialise()
    }, [])

    if (loaded && currentVisa)
        return (
            <Box>
                <PageInfo color="error" message={pageError} />

                <Box marginBottom={2}>
                    {editingNewName && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                onChange={(e) => setNewName(e.target.value)}
                                size="small"
                                variant="standard"
                                sx={{ width: '400px' }}
                            />

                            <Box onClick={() => setEditingNewName(false)} sx={{ cursor: 'pointer', marginLeft: 2 }}>
                                <CloseIcon />
                            </Box>

                            <Box onClick={saveName} sx={{ cursor: 'pointer', marginLeft: 1 }}>
                                <DoneIcon />
                            </Box>
                        </Box>
                    )}
                    {!editingNewName && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h1" marginBottom={0}>
                                {currentVisa.name}
                            </Typography>
                            <Box
                                sx={{ cursor: 'pointer', marginLeft: 2 }}
                                onClick={() => setEditingNewName(!editingNewName)}
                            >
                                <EditIcon />
                            </Box>
                        </Box>
                    )}
                </Box>

                <Box marginBottom={4}>
                    <Typography variant="h3" color={'primary.light'}>
                        Current Requirements
                    </Typography>

                    <Box marginBottom={2} sx={[CommonStyles.dashedBorder, { padding: 2 }]}>
                        <Typography color={'secondary.light'}>
                            {currentVisa.published
                                ? 'This visa is currently published and visible to users!'
                                : 'This visa is currently unpublished and hidden to users - but visible to admins!'}
                        </Typography>
                        <Button variant="contained" onClick={togglePublish}>
                            {currentVisa.published ? 'Unpublish!' : 'Publish!'}
                        </Button>
                    </Box>

                    <Box>
                        <Typography variant="h4" color={'primary.light'}>
                            Topics currently selected
                        </Typography>
                        <Box>
                            <DataGridPro
                                autoPageSize
                                pagination
                                rows={visaTopics!}
                                columns={currentQuestionTopicColumns}
                                apiRef={apiRef}
                                editMode="row"
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'pathName', sort: 'asc' }],
                                    },
                                }}
                                sx={{ minHeight: 400 }}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <Typography variant="h4" color={'primary.light'}>
                            Documents Required
                        </Typography>
                        <Box>
                            <DataGridPro
                                autoPageSize
                                pagination
                                rows={visaDocumentTypes!}
                                columns={currentDocumentTypeColumns}
                                apiRef={apiRef}
                                // getRowId={(row) => `${row.pathName}`}
                                editMode="row"
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'pathName', sort: 'asc' }],
                                    },
                                }}
                                sx={{ minHeight: 400 }}
                            />
                        </Box>
                    </Box>
                </Box>
                <RedDivider />
                <Box marginTop={2} marginBottom={8}>
                    <Box>
                        <Typography variant="h3" color={'primary.light'}>
                            Add New Requirements
                        </Typography>
                        <Typography variant="h4" color={'primary.light'}>
                            Add Topics
                        </Typography>
                        <Box minHeight={200}>
                            <DataGridPro
                                autoPageSize
                                pagination
                                rows={contextData.questionTopics}
                                columns={allQuestionTopicColumns}
                                apiRef={apiRef}
                                // getRowId={(row) => `${row.pathName}`}
                                editMode="row"
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'pathName', sort: 'asc' }],
                                    },
                                }}
                                sx={{ minHeight: 400 }}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <Typography variant="h4" color={'primary.light'}>
                            Add Document Types
                        </Typography>
                        <Box minHeight={200}>
                            <DataGridPro
                                autoPageSize
                                pagination
                                rows={contextData.documentTypes}
                                columns={documentTypeColumns}
                                apiRef={apiRef}
                                editMode="row"
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'pathName', sort: 'asc' }],
                                    },
                                }}
                                sx={{ minHeight: 400 }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )

    return <Loading />
}
